import React, { Component } from "react";
import { Row, Col, Button, Modal, Form, DatePicker, Input, InputNumber, Select, message, Spin } from 'antd';

const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment');



/**
 *
 *
 * @class ModalFacturas
 * @extends {Component}
 */
class ModalFacturas extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            proveedor: undefined,
            disabled: false,

            clientes: {
				data: [],
				
				page: 1, 
				limit: 10,

				total: 0,
				pages: 0, 

				search: null
			},

            empresas: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

            },

            proveedores: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

            },

        }
    }

    ModalFacturas = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getClientes();
        this.getEmpresas();
        this.getProveedores();

        if (this.props.factura_id !== undefined || this.props.factura_id != null) {
            this.getFactura();
        }else if(this.props.cliente_id){
            this.ModalFacturas.current?.setFieldsValue({
                cliente_id: this.props.cliente_id
            })
        }
    }

    /**
	 *
	 *
	 * @memberof ModalFacturas
	 * 
	 * @method getClientes
	 * @description Obtiene los Clientes
	 */
	getClientes = (search) => {
        axios.get('/clientes', {
            params: { page: 1, limit: 10, search }
        }).then(({ data }) => {
            this.setState(state => {
                state.clientes.data = data.data
                state.clientes.total = data.total
                state.clientes.page = data.page

                return state;
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     *
     * @memberof ModalFacturas
     * @method getEmpresas
     * @description Obtiene la informacion de los Empresas y actualiza los valores locales de la lista.
     */
     getEmpresas = (search) => {
        axios.get('/empresas', {
            params: { limit:10, page:1, search }
        })
            .then(({ data }) => {
                console.log("data", data);
                this.setState({ empresas: data })
            })
            .catch(res => {
                message.error(res.response.data.message)
            })
            .finally(()=>this.setState({loading: false}));
    }

    /**
     *
     * @memberof ModalFacturas
     * @method getProveedores
     * @description Obtiene la informacion de los Empresas y actualiza los valores locales de la lista.
     */
     getProveedores = (search) => {
        axios.get('/proveedores', {
            params: { limit:10, page:1, search }
        })
            .then(({ data }) => {
                console.log("data", data);
                this.setState({ proveedores: data })
            })
            .catch(res => {
                message.error(res.response.data.message)
            })
            .finally(()=>this.setState({loading: false}));
    }

    /**
     * @memberof ModalFacturas
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {
        if (this.props.factura_id) {
            this.updateFactura(values)
        } else {
            this.addFactura(values)
        }
    }


    /**
     * @memberof ModalFacturas
     * @method getFactura
     * @description Se ejecuta al si el modal tiene factura_id en las propiedades y obtiene los datos para
     * llenar el formulario y editar
     * 
     */
    getFactura = () => {
        this.setState({ loading: true })

        axios.get(`/facturas/${this.props.factura_id}`, {
            params: { 
                id: this.props.factura_id,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(async response => {
            let factura = response.data.data

            console.log('factura', factura)

            this.ModalFacturas.current?.setFieldsValue({
                concepto: factura.concepto,
                cliente_id: factura.cliente_id?._id,
                fecha: moment(factura.fecha),
                fecha_vigencia: moment(factura.fecha_vigencia),
                descripcion: factura.descripcion,
                tipo: factura.tipo,
                monto: factura.monto,
                monto_dolar: factura.monto_dolar,
                proveedor_id: factura.proveedor_id?._id,
                empresa_id: factura.empresa_id?._id
            })

            this.setState({
                proveedor: factura.proveedor_id,
                razon_social: factura.razon_social_id,
                orden: factura.orden_id,
                disabled: (!!factura.syncfy_id || factura.tipo_facturacion === 1)
            })

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la factura')
        }).finally(() => {

            this.setState({ loading: false, disabled: true })
        })
    }


    /**
     * @memberof ModalFacturas
     * @method addFactura
     * @description Agrega la factura a la base de datos
     */
    addFactura = (values) => {
        console.log('values', values)

        if(this.state.loading) return
        
        this.setState({ loading: true }, () => {

            axios.post('/facturas', {
                ...values,
                monto_pesos: parseFloat(values.monto_pesos).toMoney(),
                monto_dolar: parseFloat(values.monto_dolar).toMoney()
            }).then(response => {
                message.success('Factura creada')
                this.props.onClose()
            }).catch(error => {
                console.log(error)
                message.error('Error al guardar')
            }).finally(() => this.setState({ loading: false }))
        })
        
    }

    /**
    * @memberof ModalFacturas
    * @method updateFactura
    * @description Actualiza la informacion de una factura
    */
    updateFactura = (values) => {
        this.setState({ loading: true })
        axios.put('/facturas', {
            ...values,
            monto: values.monto.toMoney(),
            monto_dolar: values.monto_dolar.toMoney(),
            id: this.props.factura_id
        }).then(response => {
            message.success('Factura Actualizada')
            this.props.onClose()
        }).catch(error => {
            console.log(error)
            message.error('Error al guardar')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalFacturas
     * @method onChangePesos
     * @description Hace la conversion de pesos a dolares para actualizar el campo de dolares 
     * 
     */
    onChangePesos = (value) => {
        this.ModalFacturas.current?.setFieldsValue({
            monto_dolar: parseFloat(value).toMoney()
        })
    }

    /**
     * @memberof ModalFacturas
     * @method onChangeDolar
     * @description Hace la conversion de dolares a pesos para actualizar el campo de pesos 
     * 
     */
    onChangeDolar = (value) => {
        this.ModalFacturas.current?.setFieldsValue({
            monto_pesos: parseFloat(value).toMoney()
        })
    }

    /**
    * @memberof ModalFacturas
    *
    * @method   selectOptions
    * @description  Metodo que reciebe un array de elementos para devolver un array de opciones para un select,
    * ademas de agrear al arreglo el elemento a editar
    *
    **/
    selectOptions = (array, addElement) => {

        let arr = [];
        if (array) {
            array.forEach(function ({ _id, nombre, apellido, razon_social, folio }, index) {
                arr.push(<Option value={_id}>{nombre}{apellido || ""}{razon_social} {folio}</Option>)
            })
        }

        if (array && addElement) {
            if (!array.some(element => element._id === addElement._id)) {
                arr.push(<Option value={addElement._id}> {addElement.nombre}{addElement.apellido || ""}{addElement.razon_social} {addElement.folio}</Option>)
            }
        }
        return arr
    }

    render() {
        const { disabled } = this.state;
        return (
            <Form
                layout="vertical"
                name="form-materiales"
                ref={this.ModalFacturas}
                onFinish={this.onFinish}
                initialValues={{
                    fecha: moment(),
                    tipo: 2,
                    ...(!disabled ? {estatus: 2} : null)
                }}

            >
                <Spin spinning={this.state.loading}>

                    <Row>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Concepto"
                                name="concepto"

                                /* rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el concepto"
                                }]} */
                            >
                                <Input disabled={disabled} placeholder="Concepto"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="Fecha"
                                name="fecha"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha"
                                }]}
                            >
                                <DatePicker className="width-100" disabled={disabled} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                            // rules={[{
                            //     required: true,
                            //     message: "Por favor, ingrese una descipción"
                            // }]}
                            >
                                <Input placeholder="Descripción" ></Input>

                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Cliente"
                                name="cliente_id"
                                /* rules={[{
                                    required: true,
                                    message: "Por favor, seleccione al cliente"
                                }]} */
                            >
                                <Select
                                    placeholder="Seleccione al cliente"
                                    showSearch
                                    onSearch={this.getClientes}
                                    filterOption={false}
                                    disabled={disabled || this.props.cliente_id}
                                >
                                    {this.selectOptions(this.state.clientes.data)}
                                </Select>
                            </Form.Item>
                        </Col>
                        
                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="Proveedor"
                                name="proveedor_id"
                                /* rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el tipo"
                                }]} */
                            >
                                <Select
                                    placeholder="Seleccione al proveedor"
                                    showSearch
                                    onSearch={this.getProveedores}
                                    filterOption={false}
                                    disabled={disabled}
                                >
                                    {this.selectOptions(this.state.proveedores.data)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col  xs={24} lg={11}>
                            <Form.Item 
                                label="Tipo"
                                name="tipo"

                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el tipo"
                                }]}
                            >
                                <Select
                                     disabled={this.state.disabled}
                                    placeholder="Seleccione tipo">
                                    {!this.props.cliente_id && <Option value={0}>Sin Cambios</Option>}
                                    <Option value={1}>Ingreso</Option>
                                    <Option value={2}>Egreso</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="Fecha de vigencia"
                                name="fecha_vigencia"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha"
                                }]}
                            >
                                <DatePicker className="width-100" disabled={disabled} />
                            </Form.Item>
                        </Col>
                        
                        <Col xs={24}>
                            <Form.Item
                                label="Empresa"
                                name="empresa_id"
                                /* rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la razón social"
                                }]} */
                            >
                                <Select
                                    placeholder="Seleccione la razon social"
                                    showSearch
                                    onSearch={this.getEmpresas}
                                    filterOption={false}
                                    disabled={disabled}
                                >
                                    
                                    {this.selectOptions(this.state.empresas.data)}
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>

                    

                    <Row>
                        <Col xs={24} lg={{ span: 11 }} >
                            <Form.Item
                                label="Monto (Pesos)"
                                name="monto"
                                // formatter="(\d)(?=(\d\d\d)+(?!\d))"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >
                                <InputNumber disabled={false} min={0.01} defaultValue={0} className="width-100" decimalSeparator="," onChange={this.onChangePesos}
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                    />
                                
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 2 }} >
                            <Form.Item
                                label="Monto (Dolar)"
                                name="monto_dolar"
                                // formatter="(\d)(?=(\d\d\d)+(?!\d))"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >   
                                <InputNumber disabled={false} min={0.01} defaultValue={0}  decimalSeparator="," className="width-100" onChange={this.onChangeDolar}
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                    />
                            </Form.Item>
                        </Col>
                    </Row>
                    
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, factura_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={`${factura_id != null ? "Editar " : "Nueva "} Factura`}
        /* footer={null} */
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-materiales', key: 'submit', htmlType: 'submit' }}
    >
        <ModalFacturas {...props} />
    </Modal>

}