import React, { Component, useContext } from 'react'
import { Row, Col, Form, Input, Button, Card, Spin, Divider, Typography, Image, Alert, Modal, Checkbox, Avatar, Space } from 'antd';
import { Navigate, Link, useNavigate, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { User, SetUser } from '../../Hooks/Logged';
import axios from 'axios'

//css
import '../../Styles/Global/auth.css';
import CustomAvatar from '../Widgets/Avatar/Avatar';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const { Title } = Typography

/**
 * 
 * @class Login
 * @extends {Component}
 * @description Componente Login para inicio de sesion
 */
class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {

		}
	}

	/**
	 * @memberof Login
	 *
	 * @method handleSubmit
	 * @description  Envia los datos del formulario al Servidor
	 *
	 * @param values (array)
	 * Contiene los campos del formulario para registrar al usuario
	 *
	 * @returns response (array)
	 **/
	handleSubmit = (values) => {
		this.setState({ loading: true })
		console.log("values", values)
		axios.post('/login', values)
			.then(({ data, headers }) => {

				const { setUser } = this.props;

				axios.defaults.headers.post["Authorization"] = headers.authorization;
				sessionStorage.setItem('token', headers.authorization);
				axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

				setUser(data.user);
				const path = this.props.query.get('path');

				if(path){
					this.props.navigate(path)
				} else{
					this.props.navigate('/admin/dashboard')
				}

			})
			.catch((error) => {
				console.log("error", error);

				Modal.error({ title: "Credenciales incorrectas" })

				this.setState({ loading: false });
			})
	};


	/**
	* @memberof Login
	*
	* @method renderRedirect
	* @description  Activa el redireccionamiento si el formulario se envio con exito
	*
	**/
	renderRedirect = () => {
		if (this.state.log)
			return <Navigate to={this.redirectLink} replace />
	};

	/**
	* @memberof Login
	*
	* @method redirectTo
	* @description  Redirecciona a cierto link.
	*
	**/
	redirectTo = (to) => {
		this.redirectLink = to;
		this.setState({ log: true });
	};

	render() {
		return (
			<Row className='layout-login'>
				{this.renderRedirect()}
				<Col span={10} className="col-welcome">
					<Row>
						<img src="/img/logo.png" alt="Logo " className='logo' />
					</Row>
					<Row>
						<div>
							<Title level={2} className='title-isyt-erp'>
								Grupo manantial <br />
							</Title>
							<p className='subtitle-isyt-erp'>Welcome to the Grupo manantial </p>
						</div>
					</Row>
				</Col>

				{this.props.user?._id ?
					<Col span={14}>
						<div className='login-form-container'>
							<div className='login-form'>
								<Title level={3} className="h3-title" style={{ marginBottom: '3em' }}>Welcome back!</Title>
								<CustomAvatar
									className='avatar-logged'
									image={axios.defaults.baseURL + "/usuarios/avatar/" /this.props?.user._id}
									name={this.props?.user?.nombre + " " + this.props?.user?.apellidos}
									size={120}
									style={{
										display: "block",
										margin: '0 auto'
									}}
								/>
								<Title align="center" style={{ marginTop: '1em' }}>{this.props?.user?.nombre} {this.props?.user?.apellidos}</Title>
								<Title level={4} align="center" style={{ marginBottom: '1em' }}>{this.props?.user?.email}</Title>
								<Button type="primary" htmlType="submit" block size="large" onClick={() => this.props.navigate("/admin/dashboard")} >Continuar</Button>
								<Button type="link" htmlType="submit" block size="large" >Cerrar Sesión</Button>
							</div>
						</div>
					</Col> : <Col span={14}>
						<div className='login-form-container'>
							<Form onFinish={this.handleSubmit} layout={"vertical"} requiredMark={false} className='login-form'>
								<Title level={3} className="h3-title">Welcome back!</Title>
								<Title level={5} className="h5-title login-subtitle">Let's build something great</Title>
								<Form.Item className='input-email' name="email" label="Email or phone number"
									rules={[
										{ required: true, message: 'Type your email or phone number' }
									]}>
									<Input placeholder="Type your email or phone number" size="large" className="input-login" />
									
								</Form.Item>
								<Form.Item
									label="Password"
									name="password"
									rules={[{ required: true, message: 'Please enter your password' }]}
									extra={<Form.Item valuePropName='checked' name="keep_session">
										<Checkbox >Mantener sesión iniciada</Checkbox>
									</Form.Item>}
								>
									<Input.Password
										size="large"
										placeholder="********"
										iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
									/>
								</Form.Item>
								<Form.Item>
									<Button type="primary" htmlType="submit" block size="large" >Sign in</Button>
								</Form.Item>
							</Form>
						</div>
					</Col>

				}

				{!this.props.user?._id && <div className='link-password-recovery'>
					Forgot your Password? <Link style={{ color: '#5452F6' }} to='/password/recovery'>Restore it here</Link>
				</div>}

			</Row>
		)
	}
}

export default function (props) {

	let user = useContext(User)
	const setUser = useContext(SetUser)

	const navigate = useNavigate()
	let location = useLocation();

	const [query, setQuery] = useSearchParams()

	return <Login {...props} user={user} setUser={setUser} navigate={navigate} params={useParams()} query={query}/>
}