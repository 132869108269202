import React, { Component } from "react";
import { Row, Col, Input, Button, Modal, Form, message, Spin, InputNumber, DatePicker,  Divider } from 'antd';
import axios from 'axios'
import PropTypes from "prop-types";
import { UploadOutlined } from '@ant-design/icons';
//componentes
import Logged from "../../../../Hooks/Logged";
import { Uploader } from "../../../Widgets/Uploaders";
import { SelectProyecto, SelectUsuario } from "../../../Widgets/Inputs/Selects"


const moment = require('moment');
let contConsulta = 0
class ModalAcreedor extends Component {

    static contextType = Logged;

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }

    };


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            accionModal: '',
            datosConsultados: false,
            return: false,
            fileList: [],
            edicion: false,
        }
    }


    //Referencia de form
    ModalAcreedorRef = React.createRef();

    componentDidMount() {
        contConsulta = 0;
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if (this.props.acreedor_id !== undefined) {
            this.getAcreedor()
        }

    }


    /**
     * @memberof ModalAreas
     * @method getAcreedor
     * @description Obtiene la información del acreedor 
     */
    getAcreedor = () => {
        this.setState({ loading: true })

        axios.get(`/acreedores/${this.props.acreedor_id}`, {
            params: { id: this.props.acreedor_id }
        }).then(({ data }) => {
            console.log(data)
            let acreedor = data;
            this.setState({
                acreedor: data,
            })

            this.ModalAcreedorRef.current.setFieldsValue({
                acreedor: data.acreedor,
                email: data.email,
                telefono: data.telefono,

                monto_prestamo: data.monto_prestamo,
                monto_pendiente: data.monto_pendiente,
                monto_retorno: data.monto_retorno,
                proyecto_id: {
                    value: data?.proyecto_id?._id,
                    label: data?.proyecto_id?.nombre
                },
                contacto_id: {
                    value: data?.contacto_id?._id,
                    label: data?.contacto_id?.nombre
                },
                documentos: data?.documentos?.map((file, index) => ({
                    uid: file.file,
                    name: file.name,
                    status: 'done',
                    fromDB: true,
                    url: axios.defaults.baseURL + "/acreedores/" + this.props.acreedor_id + "?documento=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                })),

                fecha_prestamo: moment(data.fecha_prestamo),
            })

        }).catch(error => {
            message.error('Error al traer almacen')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof ModalAreas
     * @method hideModal
     * @description Función que reinicializa los states y llama al método de cierre en componente padre
     * */
    hideModal = () => {
        //Reiniciar valores al cerrar modal
        contConsulta = 0;
        this.setState({
            spinning: false,
            accionModal: '',
            datosConsultados: false,
        })

        this.props.closeMethod();
    }

    /**
     * @function onFinish
     * @description Determina si se va a editar o agregar un acreedor
     * */
    onFinish = (values) => {
        console.log("values", values);
        if (values.monto_retorno < values.monto_prestamo) {
            message.error("El monto a retornar debe ser mayor o igual al monto de prestamo");
            return;
        }
        

        const formData = new FormData()

        formData.appendMultiple({
            acreedor: values.acreedor,
            email: values.email,
            telefono: values.telefono,
            monto_prestamo: values.monto_prestamo,
            monto_retorno: values.monto_retorno,
            fecha_prestamo: values.fecha_prestamo,
            proyecto_id: values.proyecto_id,
            contacto_id: values.contacto_id,
            documentos: undefined,
            id: this.props.acreedor_id
        });

        values.documentos?.forEach(e => {
            if (!e.fromDB) formData.append("documentos", e, e.name)
        })


        if (this.props.acreedor_id) {
            this.editAcreedor(formData)
        } else {
            this.addAcreedor(formData)
        }
    }

    /**
     * @function addAcreedor
     * @description Agrega un acreedor utilizando los datos del form
     * */   
    addAcreedor = (values) => { 
        this.setState({loading: true})
        axios.post('/acreedores', values)
        .then((res) => {
            message.success("¡Acreedor Registrado!");
            this.hideModal();
            this.props.hideModal();

        }).catch((error) => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al crear el Acreedor")
            
        }).finally(()=>this.setState({loading: false}))

        
    }

    
    /**
     * @function editAcreedor
     * @description Edita un acreedor utilizando los datos del form
     * */
    editAcreedor = (values) => {
        this.setState({loading: true})
        const { acreedor_id } = this.props;
        axios.put('/acreedores', values)
        .then((res) => {
            message.success("¡Acreedor Actualizado!");
            this.hideModal();
            this.props.hideModal();

        }).catch((error) => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al editar el Acreedor")
        }).finally(()=>this.setState({loading: false}));
        

    }


    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    ref={this.ModalAcreedorRef}
                    name="formulario-cliente"
                    onFinish={this.onFinish}
                >


                    <Row gutter={16}>
                        <Col xs={24}>
                            <Form.Item
                                label="Acreedor"
                                name="acreedor"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el acreedor"
                                }]}
                            >
                                <Input placeholder="Acreedor"></Input>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Teléfono"
                                name="telefono"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese telefono"
                                }]}
                            >
                                <Input placeholder="Teléfono"></Input>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese email"
                                }]}
                            >
                                <Input placeholder="Email"></Input>
                            </Form.Item>
                        </Col>




                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Proyecto"
                                name="proyecto_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione proyecto"
                                }]}
                            >
                                <SelectProyecto/>
                            </Form.Item>

                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Contacto"
                                name="contacto_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione contacto"
                                }]}
                            >
                                <SelectUsuario placeholder="Seleccione el contacto"/>
                            </Form.Item>

                        </Col>

                    </Row>


                    <Row gutter={16}>
                        <Col xs={24}>
                            <Divider>Información del Prestamo</Divider>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Monto de Prestamo"
                                name="monto_prestamo"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto de prestamo"
                                }]}
                            >
                                <InputNumber min={0.01} defaultValue={0} className="width-100"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                />
                            </Form.Item>

                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Monto a Retornar"
                                name="monto_retorno"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto a retornar"
                                }]}

                            >
                                <InputNumber min={0.01} defaultValue={0} className="width-100"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Fecha de Prestamo"
                                name="fecha_prestamo"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha"
                                }]}
                            >
                                <DatePicker className="width-100" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Documentos"
                                name="documentos"
                                valuePropName="fileList"
                                getValueFromEvent={e => {
                                    if (Array.isArray(e)) {
                                        return e;
                                    }
                                    return e?.fileList;
                                }}>
                                <Uploader
                                    {...this.props.acreedor_id ? {
                                        method: "PUT",
                                        name: "documentos",
                                        headers: {
                                            authorization: sessionStorage.getItem("token")
                                        },
                                        //action: axios.defaults.baseURL + '/transacciones',
                                        onRemove: e => {
                                            axios.put('/acreedores', {
                                                documento: e.uid,
                                                id: this.props.acreedor_id
                                            })
                                        }
                                    } : {}}>
                                    <Button icon={<UploadOutlined />} block size="small">Subir Documentos </Button>
                                </Uploader>
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Spin>

        )
    }
}

export default function (props) {
    const { visible, closeMethod, data, acreedor_id, modalVisible } = props;
    const [modal] = Modal.useModal();

    return (
        <Modal
            visible={modalVisible}
            onCancel={closeMethod}
            title={acreedor_id ? "Editar Acreedor" : "Nuevo Acreedor"}
            cancelText="Cancelar"
            okText="Guardar"
            okButtonProps={{ form: 'formulario-cliente', key: 'submit', htmlType: 'submit' }}
            closable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <ModalAcreedor {...props} />
        </Modal>
    );
}