import React from 'react'
import { Route, Routes } from "react-router-dom";

// Components

import Proveedores from '../components/Admin/Proveedores/Proveedores';
import ProveedorDetalle from '../components/Admin/Proveedores/ProveedorDetalle';





/**
 * 
 * @export
 * @function RouterProveedores
 * @description Router para Proveedores
 */
 function RouterProveedores(props) {
  return (
    <Routes>
      <Route path=""  element={<Proveedores {...props} />} />
      <Route path="/detalle/:proveedor_id"  element={<ProveedorDetalle {...props} />} />
    </Routes>
  )
}

export default RouterProveedores