import React, { Component, useContext } from "react";
import { Button, Col, Row, Typography, Card, List, Space, message, Popconfirm, Modal, Avatar, Statistic, Tooltip } from "antd";
import axios from 'axios'
import { MdOutlineEmail, MdOutlineMarkEmailRead } from "react-icons/md"
import { FloatingMenu, MainButton, ChildButton, } from 'react-floating-button-menu';
import { ClockCircleFilled, CloseOutlined, DeleteOutlined, DollarCircleFilled, EditOutlined, EyeOutlined, FilePdfOutlined, LinkOutlined, PlusOutlined } from "@ant-design/icons"


//compoenentes
import User from "../../../../Hooks/Logged";
import { renderMontoTransaccion } from "../../../Utils";
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import usePermissions from "../../../../Hooks/usePermissions";
import ModalTransaccion from "../../Finanzas/Transacciones/ModalTransaccion";
import ModalTransaccionDetalle from "../../Finanzas/Transacciones/ModalTransaccionDetalle";


const { Text } = Typography;
const moment = require('moment');

/**
 * @export
 * @class TransaccionesTab
 * @extends {Component}
 * @description Lista de transacciones del cliente
 */
class TransaccionesTab extends Component {

	constructor(props) {
		super(props)
		this.state = {
			transacciones: {
				data: [],
				limit: 20,
				page: 1,
				total: 0,
				pages: 0,
			},
			inversion_id: this.props.inversion_id,
			transaccion_id: undefined
		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
		this.get()
	}

	componentDidUpdate(prevProps){
		if(prevProps.inversion_id !== this.props.inversion_id) this.get()
	}

	/**
	 * @memberof TransaccionesTab
	 * @method get
	 * @description Obtiene los transacciones del cliente
	 *
	 */
	get = ({

		page = this.state.transacciones.page,
		limit = this.state.transacciones.limit,

		inversion_id = this.props.inversion_id,


	} = this.state.transacciones) => {

		this.setState({ loading: true })
		axios.get('/transacciones', {
			params: {
				inversion_id,
				page,
				limit
				// tipo_pago
			}
		})
			.then(({ data }) => {
				console.log("data", data);
				this.setState({ transacciones: { ...this.state.transacciones, ...data } });
			})
			.catch(error => {
				message.error('Error al traer los transacciones')
				console.log(error.response)
			}
			).finally(() => this.setState({ loading: false }))
	}

	/**
	 * @memberof TransaccionesTab
	 * @method desvincular
	 * @description Obtiene los transacciones del cliente
	 *
	 */
	desvincular = (transaccion_id) => {
		Modal.confirm({
			title: '¿Seguro de desvincular la transaccion?',
			okText: 'Si',
			cancelText: 'Cancelar',
			onOk: () => {
				return axios.put('/crm/desvincular', {
					transaccion_id
				}).then(response => {
					message.success('Transacción desvinculada')
					this.get()
				}).catch(error => {
					console.log("error", error);
					message.error('Error al desvincular')
				}).finally(e => this.props.updateAll())
			}
		})
	}


	render() {

		const { data } = this.state.transacciones

		return (
			<>
				<Row gutter={[8, 8]}>
					<List
						itemLayout="horizontal"
						dataSource={data}
						loading={this.state.loading}
						key={(item => item._id)}
						className="component-list width-100"
						pagination={{
							current: this.state.transacciones.page,
							pageSize: this.state.transacciones.limit,
							total: this.state.transacciones.total,
							showSizeChanger: true,
							position: 'bottom',
							className: "flex-left",
							onChange: (page, limit) => this.get({ page, limit })
						}}
						renderItem={item => (
							<List.Item className="component-list-item transacciones">
								<Card className="ant-card-list width-100">
									<Row className="width-100" gutter={[16, 16]}>
										<Col xs={24} lg={3} className="center">
											<Text className="text-date-format fecha">{moment(item.fecha).format('YYYY-MM-DD')}</Text>
										</Col>
										<Col xs={24} lg={6} className="center">
											<Text className="concepto">{item.concepto}</Text>
										</Col>
										<Col xs={24} lg={4} className="center">
											<Text className="concepto">{item.area_id?.nombre}</Text>
										</Col>
										<Col xs={24} lg={3} className="center">
											<Text className="concepto">{item.cuenta_id?.nombre ?? ((item.tipo_pago == 2) ? "Especie" : null)}</Text>
										</Col>
										<Col xs={24} lg={4} className="center">
											{renderMontoTransaccion({tipo: item.tipo ===  1 ? 2 : 1, monto: item.monto})}
										</Col>
										<Col xs={24} lg={4} align="right">
											<Space direction="horizontal" size={5}>
												{!this.props.enviarTransaccionesInversiones ? null : <Tooltip mouseEnterDelay={0} title="Enviarle el Recibo de Pago al Cliente">
													<Button
														size="small"
														className={(item?.mensaje?.enviado) ? "ant-btn-primary-lime" : "ant-btn-primary-cyan"}//"ant-btn-primary-cyan"
														type="primary"
														//disabled={!this.props.enviarTransaccionesPropiedad}
														icon={(item?.mensaje?.enviado) ? <MdOutlineMarkEmailRead style={{ color: "currentcolor", marginTop: 3, fontSize: 16 }} /> : <MdOutlineEmail style={{ color: "currentcolor", marginTop: 3, fontSize: 16 }} />}
														onClick={() => Modal.confirm({

															title: "¿Deseas envíar el comprobante de pago al cliente?",
															content: "Se enviará un correo al cliente con el comprobante adjunto",
															onOk: () => axios.get('/transaccion/recibo/' + item._id, { params: { send: true } }).then(() => {
																this.get()
																message.success("Se ha enviado correctamente")
															}).catch(() => {
																message.error("No fue posible envíar el correo")
															})
																.finally(e => {})

														})}
													/>
												</Tooltip>}
												<Tooltip mouseEnterDelay={0} title="Ver el recibo de pago de esta transacción">
													<Button
														size="small"
														className="ant-btn-primary-gold"
														type="primary"
														//disabled={!this.props.verTransaccionesPropiedad}
														icon={<FilePdfOutlined style={{ color: "currentcolor" }} />}
														onClick={() => window.open(axios.defaults.baseURL + "/transaccion/recibo/" + item._id + "?Authorization=" + sessionStorage.getItem("token"))}
													/>
												</Tooltip>
												<Tooltip mouseEnterDelay={0} title="Ver la transacción">
													<Button
														size="small"
														className="ant-btn-primary-green"
														type="primary"
														//disabled={!this.props.verTransaccionesPropiedad}
														icon={<EyeOutlined style={{ color: "currentcolor" }} />}
														onClick={() => this.setState({modalDetalleVisible: true, transaccion_id: item._id, tipo_pago: item.tipo_pago})}
													/>
												</Tooltip>
												<Tooltip mouseEnterDelay={0} title="Desvincular la transacción de la propiedad y el cliente">
													<Button
														size="small"
														className="ant-btn-primary-yellow"
														type="primary"
														//disabled={!this.props.desvincularTransaccionesPropiedad}
														icon={<LinkOutlined style={{ color: "currentcolor" }} />}
														onClick={() => this.desvincular(item._id)}
													/>
												</Tooltip>
												<Tooltip mouseEnterDelay={0} title="Editar la transacción">
													<Button
														//disabled={!this.props.editarTransaccionesPropiedad}
														size="small"
														type="primary"
														icon={<EditOutlined style={{ color: "currentcolor" }} />}
														onClick={() => this.setState({modalVisible: true, transaccion_id: item._id})}
													/>
												</Tooltip>
												<Popconfirm
													placement="topRight"
													title="¿Deseas eliminar esta transaccion?"
													onConfirm={() => axios.delete(`/transacciones`,
														{ params: { id: item._id } }).then(() => {
															this.get()
															message.success('Transaccion eliminada')
														}).catch(error => console.log(error))
														.finally(e => this.props.updateAll())
													}
													okText="Si"
													cancelText="No"
												>
													<Button
														//disabled={!this.props.eliminarTransaccionesPropiedad}
														size="small"
														type="primary"
														icon={<DeleteOutlined style={{ color: "currentcolor" }} />}
														title="Eliminar"
														danger
													/>
												</Popconfirm>
											</Space>
										</Col>
									</Row>
								</Card>
							</List.Item>
						)}
					/>
				</Row>

				{/*Modal solo para editar*/}
				<ModalTransaccion
					visible={this.state.modalVisible}
					onClose={() => {
						this.setState({modalVisible: false, transaccion_id: undefined})
						this.props.updateAll()
					}}
					transaccion_id={this.state.transaccion_id}
					inversion_id={this.state.inversion_id}
					tipo_pago={this.state.tipo_pago}
					disabled={{
						area: true,
						rubro: true,
						cliente: true,
						propiedad: true,
						factura: true,
						cuenta: ((this.state.tipo_pago === 2) ? false : true),
						proyecto: true,
						tipo: true,
						acreedor: true,
						programada: true
					}}
					required={{
						cuenta: ((this.state.tipo_pago === 2) ? true : false)
					}}

				/>
				<ModalTransaccionDetalle
					visible={this.state.modalDetalleVisible}
					onClose={() => this.setState({modalDetalleVisible: false, transaccion_id: undefined})}
					transaccion_id={this.state.transaccion_id}
				/>
			</>
		)
	}
}

export default React.forwardRef((props, ref) => {

    const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {
		verTransaccionesInversiones: ["inversiones", "transacciones", "read"],
		enviarTransaccionesInversiones: ["inversiones", "transacciones", "send"],
		desvincularTransaccionesInversiones: ["inversiones", "transacciones", "unlink"],
        editarTransaccionesInversiones: ["inversiones", "transacciones", "edit"],
        eliminarTransaccionesInversiones: ["inversiones", "transacciones", "delete"]
    });

	return <TransaccionesTab ref={ref} {...props} user={user} {...permisos}/>
})