import React, { Component } from 'react';
import { Row, Col, Card, Button, Spin, Tag, Layout, Space, List, Typography, Avatar } from 'antd'

import axios from 'axios';

import { IconPlusGroup } from '../../../Widgets/Iconos';
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";

import {BsFillFlagFill} from 'react-icons/bs'

import Logged from '../../../../Hooks/Logged'
import usePermissions from '../../../../Hooks/usePermissions'; 

const { Content } = Layout;
const { Text } = Typography;


export class Requisiciones extends Component {

    constructor(props) {
        super(props)
        this.state = {
            
            
        }
    }

    componentDidMount() {
        

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        
    }


    render() {
        return (
            <>
                <Spin spinning={false}>
                    <Content className="admin-content content-bg">
                        <List
                            loading={false}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Requisiciones " }}
                            dataSource={[{},{},{}]}
                            pagination={{
                                current: 1,
                                pageSize: 10,
                                total: 3,
                                position: 'bottom',
                                className: "flex-left",
                                
                            }}

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 " gutter={[16, 16]}>

                                            <Col span={5} className="center">
                                                <Tag>{`{{Estatus}}`}</Tag>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Text >{`{{Volumen}} {{Unidad}}`}</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Text>{`{{Almacen}}`}</Text>
                                            </Col>
                                            <Col span={2} className="center">
                                                <Avatar> </Avatar>
                                                <BsFillFlagFill className='ml-1'/>
                                            </Col>
                                            <Col span={4} className="center">
                                            <Text>{`{{Fecha}}`}</Text>
                                            </Col>
                                            <Col span={2} align="right">
                                                <Space size="small" direction="horizontal">
                                                    <Button
                                                        type="primary"
                                                        disabled={false}
                                                        icon={<IconPlusGroup />}
                                                        title="Editar"
                                                        
                                                    />
                                                    
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>

                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} />


                    
                </Spin>
            </>
        )
    }
}


export default function (props) {

    
    

    return <Requisiciones {...props} />

}