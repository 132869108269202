import React, { Component, useContext } from 'react';
import { Avatar, Spin, PageHeader, Tooltip, Layout, message, Row, Col, Card, Typography, Tabs, Menu, Space, Button, Result, Popconfirm, Tag, Empty, Badge } from 'antd'
import axios from 'axios';

import { FaRegHandshake } from 'react-icons/fa'
import { useParams, useNavigate, useLocation, matchRoutes } from "react-router-dom";


//Componentes
import Logged from '../../../Hooks/Logged';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import usePermissions from '../../../Hooks/usePermissions';
import { IconArrowBack } from '../../Widgets/Iconos';
import InformacionGeneral from './Sections/InformacionGeneral';
import Domicilio from './Sections/Domicilio';
import ContactoEmergencia from './Sections/ContactoEmergencia';
import Vacaciones from './Sections/Vacaciones';
import Documentacion from './Sections/Documentacion';

//Modales






//modal


const { Content } = Layout;
const { Text, Title, Paragraph } = Typography;
const { TabPane } = Tabs;


const moment = require('moment');
moment.locale('es');

/**
 * @export
 * @class DetalleClientes
 * @extends {Component}
 * @description Vista de Detalle Clientes
 */
class DetalleClientes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            empleado: {},

        }
    }



    componentDidMount() {
        this.get()
    }



    /**
     * @memberof DetallesClientes
     * @method get
     * @description Obtiene los detalles y propiedades del cliente
     *
     */
    get = () => {
        this.setState({ loading: true })
        axios.get('/empleados/' + this.props.params.id)
            .then(({ data }) => {
                console.log("Data Empleado", data)
                this.setState({ empleado: data })

            }).catch(error => {
                message.error('Error al obtener el empleado')
            }).finally(() => this.setState({ loading: false }))
    }


    render() {
        const { empleado, cliente } = this.state


        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        backIcon={<IconArrowBack />}
                        onBack={(e) => this.props.navigate(-1)}
                        title={
                            <Text level={4} className="text-white ant-page-header-heading-title ml-1" > Información de {empleado.nombre} {empleado.apellido_paterno} </Text>
                        }
                    />
                    <Content className="admin-content content-bg pd-1 detalle-propiedad">
                        <Row >
                            <Col span={24}>
                                <Card bordered>
                                    <Row align="middle" className='mb-1' gutter={[12, 12]}>
                                        <Col>
                                            <Title level={4} >Expediente</Title>
                                        </Col>
                                    </Row>
                                    <Row>


                                        <Col xs={24} lg={20}>
                                            <Row>
                                                <Col xs={24} lg={12} xl={6}>
                                                    <Paragraph>Nombre</Paragraph>
                                                    <Paragraph strong style={{ fontSize: 16 }}>{empleado?.nombre}</Paragraph>
                                                </Col>
                                                {
                                                    empleado?.segundo_nombre ?
                                                        <Col xs={24} lg={12} xl={6}>
                                                            <Paragraph>Segundo Nombre</Paragraph>
                                                            <Paragraph strong style={{ fontSize: 16 }}>{empleado?.segundo_nombre}</Paragraph>
                                                        </Col> : null
                                                }
                                                {
                                                    empleado?.apellido_paterno ?
                                                        <Col xs={24} lg={12} xl={6}>
                                                            <Paragraph>Apellido Paterno</Paragraph>
                                                            <Paragraph strong style={{ fontSize: 16 }}>{empleado?.apellido_paterno}</Paragraph>
                                                        </Col> : null
                                                }
                                                {
                                                    empleado?.apellido_materno ?
                                                        <Col xs={24} lg={12} xl={6}>
                                                            <Paragraph>Apellido Materno</Paragraph>
                                                            <Paragraph strong style={{ fontSize: 16 }}>{empleado?.apellido_materno}</Paragraph>
                                                        </Col> : null
                                                }
                                                {
                                                    empleado?.email ?
                                                        <Col xs={24} lg={12} xl={6}>
                                                            <Paragraph>Email</Paragraph>
                                                            <Paragraph strong style={{ fontSize: 16 }}>{empleado?.email}</Paragraph>
                                                        </Col> : null
                                                }
                                                {
                                                    empleado?.telefono ?
                                                        <Col xs={24} lg={12} xl={6}>
                                                            <Paragraph>Telefono</Paragraph>
                                                            <Paragraph strong style={{ fontSize: 16 }}>{empleado?.telefono}</Paragraph>
                                                        </Col> : null
                                                }
                                                {
                                                    empleado?.fecha_ingreso ?
                                                        <Col xs={24} lg={12} xl={6}>
                                                            <Paragraph>Fecha de Ingreso</Paragraph>
                                                            <Paragraph strong style={{ fontSize: 16 }}>{moment(empleado?.fecha_ingreso).format('YYYY-MM-DD')}</Paragraph>
                                                        </Col> : null
                                                }
                                                {
                                                    empleado?.fecha_baja ?
                                                        <Col xs={24} lg={12} xl={6}>
                                                            <Paragraph>Fecha de Baja</Paragraph>
                                                            <Paragraph strong style={{ fontSize: 16 }}>{moment(empleado?.fecha_baja).format('YYYY-MM-DD')}</Paragraph>
                                                        </Col> : null
                                                }



                                            </Row>
                                        </Col>
                                        <Col xs={24} lg={4}>
                                            <CustomAvatar
                                                size={150}
                                                placeholder={true}
                                                url={this.state.empleado.avatar ? {
                                                    url: axios.defaults.baseURL + "/empleados/" + this.props.params.id + "?avatar=" + this.state.empleado.avatar.file + "&Authorization=" + sessionStorage.getItem("token"),
                                                    logo: this.state.empleado.avatar?.name
                                                } : null}
                                                name={this.state.empleado.nombre + (this.state.empleado.segundo_nombre) ? ` ${this.state.empleado.segundo_nombre}` : null + (this.state.empleado.apellido_paterno) ? ` ${this.state.empleado.apellido_paterno}` : null + (this.state.empleado.apellido_materno) ? ` ${this.state.empleado.apellido_materno}` : null}
                                            />
                                        </Col>

                                    </Row>
                                </Card>
                            </Col>
                            <Col span={24}>
                                <Tabs>
                                    <TabPane tab="Información General" key="info-gral">
                                        <InformacionGeneral id={this.props.params.id} />
                                    </TabPane>
                                    <TabPane tab="Domicilio" key="domicilio">
                                        <Domicilio id={this.props.params.id} />
                                    </TabPane>
                                    <TabPane tab="Documentación" key="documentos">
                                        <Documentacion id={this.props.params.id} />
                                    </TabPane>
                                    <TabPane tab="Contactos de Emergencia" key="emergencia">
                                        <ContactoEmergencia id={this.props.params.id} />
                                    </TabPane>
                                    <TabPane tab="Vacaciones" key="vacaciones">
                                        <Vacaciones id={this.props.params.id} />
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                        
                    </Content>
                </Spin>
            </>
        )
    }
}

export default function () {

    const location = useLocation()
    const user = useContext(Logged);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        crearPropiedades: ["clientes", "propiedades", "create"],

        verTransaccionesPropiedad: ["clientes", "propiedades", "transacciones", "access"],
        crearTransaccionesPropiedad: ["clientes", "propiedades", "transacciones", "create"],

        verTransaccionesProgramadasPropiedad: ["clientes", "propiedades", "transacciones-programadas", "access"],
        crearTransaccionesProgramadasPropiedad: ["clientes", "propiedades", "transacciones-programadas", "create"],
    });

    return <DetalleClientes
        params={useParams()}
        navigate={useNavigate()}
        location={location}
        {...permisos}
    />
}