import React, { Component, useEffect } from "react";
import { Typography, Button, Modal, Form, Input, message, Spin, Row, Col, Divider, Select, Space, Tooltip, InputNumber } from 'antd'


import { DeleteOutlined, EditOutlined, PlusCircleTwoTone, PlusOutlined, SaveOutlined } from '@ant-design/icons';


const { Text } = Typography

const { Option } = Select

const axios = require('axios').default;


function InputEditable(props) {

    useEffect(() => {

        document.getElementById("editInput").focus()


    }, []);


    return <Input
        id="editInput"
        onClick={event => event.stopPropagation()}
        onKeyUp={event => event.stopPropagation()}
        onKeyDown={event => event.stopPropagation()}
        onKeyDownCapture={event => event.stopPropagation()}
        onKeyUpCapture={event => event.stopPropagation()}
        {...props} />


}

/**
 *
 *
 * @class ModalProductos
 * @extends {Component}
 */
class ModalProductoProveedor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            producto_id: this.props.producto_id,

            proveedores: {},

            producto: 1,
            categoria: null,
            subcategoria: null
        }
    }

    ModalProductos = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getProveedores()

        if (this.props.proveedor_id !== undefined) {
            this.getProducto()
        }
    }

    /**
    * @memberof ModalProductoProveedor
    * @method addProducto
    * @description Añade una producto a la BD
    */
    addProducto = (values) => {
        axios.post('/productos-proveedor', {
            producto_id: this.state.producto_id,
            ...values
        }).then(response => {
            message.success('Proveedor agregado')
            this.props.onClose()
        }).catch(error => {
            message.error('Hubo un error, intente de nuevo o verifique los datos')
        })
    }

    /**
     * @memberof ModalProductoProveedor
     * @method updateProducto
     * @description Actualiza la información de una producto
     */
    updateProducto = (values) => {
        this.setState({ loading: true })
        axios.put('/productos-proveedor/' + this.props.proveedor_id, {
            ...values
        }).then(response => {
            message.success('Proveedor Actualizada')
            this.props.onClose()
        }).catch(error => {
            message.error('Error al actualizar Producto')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalProductoProveedor
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {

        if (this.props.proveedor_id)
            this.updateProducto(values)
        else
            this.addProducto(values)

    }

    /**
     * @memberof ModalProductoProveedor
     * @method getClientes
     * @description Trae los negocios
     * 
     */
    getProveedores = (search) => {
        axios.get('/proveedores', {
            params: { page: 1, limit: 10, search }
        }).then(({ data }) => {
            this.setState(state => {
                state.proveedores.data = data.data
                state.proveedores.total = data.total
                state.proveedores.page = data.page

                return state;
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
        * @memberof ModalProductoProveedor
        *
        * @method   selectOptions
        * @description  Metodo que reciebe un array de elementos para devolver un array de opciones para un select,
        * ademas de agrear al arreglo el elemento a editar
        *
        **/
    selectOptions = (array, addElemnt) => {

        let arr = [];
        if (array) {
            array.forEach(function ({ _id, nombre, apellido, razon_social, folio }, index) {
                arr.push(<Option value={_id}>{nombre} {apellido} {razon_social} {folio}</Option>)
            })
        }

        if (array && addElemnt) {
            if (!array.some(element => element._id === addElemnt._id)) {
                arr.push(<Option value={addElemnt._id}> {addElemnt.nombre}{addElemnt.razon_social} {addElemnt.folio}</Option>)
            }
        }
        return arr
    }

    /**
     * @memberof ModalProductos
     * @method getProducto
     * @description Obtiene una producto de la DB
     */
    getProducto = () => {
        this.setState({ loading: true })
        axios.get(`/productos-proveedor/${this.props.proveedor_id}`).then(({ data }) => {

           
            this.setState({
                sku_proveedor: data.sku_proveedor,
                descripcion: data.descripcion,
                costo: data.costo,
                proveedor_id: data.proveedor_id,
            })
            this.ModalProductos.current.setFieldsValue(data)
        }).catch(error => {
            message.error('Error al traer área')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    name="form-productos"
                    ref={this.ModalProductos}
                    onFinish={this.onFinish}
                    className="pd-1"
                >
                    <Row gutter={[16, 16]} className="w-100">
                        <Col span={24}>
                            <Form.Item
                                label="SKU Proveedor"
                                name="sku_proveedor"

                                rules={[
                                    {
                                        required: true,
                                        message: "Indique el sku del proveedor"
                                    }
                                ]}
                            >
                                <Input placeholder="Nombre" ></Input>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                            >
                                <Input.TextArea placeholder="Nombre" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Costo"
                                name="costo"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el costo"
                                }]}
                            >
                                <InputNumber
                                    disabled={false}
                                    min={0.01}
                                    className="width-100"
                                    decimalSeparator=","
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Proveedor"
                                name="proveedor_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el Proveedor"
                                }]}
                            >
                                <Select
                                    placeholder="Seleccione el Proveedor"
                                    showSearch
                                    onSearch={this.getProveedores}
                                    allowClear
                                    filterOption={false}
                                >
                                    {this.selectOptions(this.state.proveedores.data)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, producto_id, proveedor_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={proveedor_id ? "Editar Proveedor de Producto" : "Crear Proveedor de Producto"}
        closable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-productos', key: 'submit', htmlType: 'submit' }}
    >
        <ModalProductoProveedor {...props} />
    </Modal>

}