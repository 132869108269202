import React, { Component } from "react";
import { Modal, Form, Input, message, Spin } from 'antd';
const axios = require('axios').default;


/**
 *
 *
 * @class ModalCategorias
 * @extends {Component}
 */
class ModalCategorias extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    ModalCategorias = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.categoria_id !== undefined) {
            console.log(this.props.categoria_id);
            this.getCategoria()
        }
    }


    /**
     * @memberof ModalCategorias
     * @method getCategoria
     * @description Obtiene una categoria de la DB
     */
    getCategoria = () => {
        this.setState({ loading: true })
        axios.get(`/categorias/${this.props.categoria_id}`, {
            params: {
                id: this.props.categoria_id
            }
        }).then(({ data }) => {
            console.log(data);
            let categoria = data;
            this.setState({
                categoria,
            })

            this.ModalCategorias.current.setFieldsValue({
                nombre: categoria.nombre,
            })

        }).catch(error => {
            message.error('Error al traer categoría')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
        * @memberof ModalCategorias
        * @method addCategoria
        * @description Añade una categoria a la BD
        */
    addCategoria = (values) => {
        axios.post('/categorias', {
            ...values
        }).then(response => {

            
                message.success('Categoría creada')
                this.props.onClose()
            

        }).catch(error => {
            console.log(error)
            message.error('Error al crear Categoría.')
        })
    }

    /**
     * @memberof ModalCategorias
     * @method updateCategoria
     * @description Actualiza la información de una categoria
     */
    updateCategoria = (values) => {
        this.setState({ loading: true })
        axios.put(`/categorias/${this.props.categoria_id}`, {
            ...values,
        }).then(response => {
            message.success('Categoría Actualizada')
            this.props.onClose()
        }).catch(error => {
            message.error('Error al actualizar Categoría')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof ModalCategorias
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => (this.props.categoria_id) ? this.updateCategoria(values) : this.addCategoria(values)



    render() {
        return (
            <Form
                layout="vertical"
                name="form-categoria"
                ref={this.ModalCategorias}
                onFinish={this.onFinish}
                className="pd-1"
            >
                <Spin spinning={this.state.loading}>
                    <Form.Item
                        label="Nombre"
                        name="nombre"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el nombre"
                        }]}
                    >
                        <Input placeholder="Nombre" ></Input>
                    </Form.Item>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, categoria_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={categoria_id ? "Editar Categoría" : "Crear Categoría"}
        closable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-categoria', key: 'submit', htmlType: 'submit' }}
    >
        <ModalCategorias {...props} />
    </Modal>

}