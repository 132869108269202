import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, Upload, Button, } from "antd";
import { DeleteOutlined, FileImageFilled, InboxOutlined, LoadingOutlined, PlusOutlined, UploadOutlined, } from "@ant-design/icons";

//componentes
import ColorPicker from "../../Widgets/ColorPicker";
import { SelectUsuario } from "../../Widgets/Inputs/Selects"
import { SimpleUploader, Uploader } from "../../Widgets/Uploaders";

const axios = require("axios").default;

/**
 * @class ModalProyectos
 * @description Modal para el CRUD de proyectos
 */
class ModalProyectos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roles: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,
            },
            image: undefined,
            loading: false,
            loadingImage: false
        };
    }

    formRef = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("token");

        if (this.props.proyecto_id)
            this.getProyecto();
    }


    /**
     * @memeberof ModalProyectos
     * @method getProyecto
     * @description Añade un nuevo registro de proyecto
     */
    getProyecto = () => {
        this.setState({ loading: true });
        axios
            .get("/proyectos/" + this.props.proyecto_id, {
                params: {
                    id: this.props.proyecto_id
                }
            })
            .then(({ data }) => {
                this.formRef.current.setFieldsValue({
                    ...data,
                });
            })
            .catch((error) => {
                console.log("error", error);
                message.error("Error al obtener el proyecto");
            })
            .finally(() => this.setState({ loading: false }));
    };

    /**
     * @memeberof ModalProyectos
     * @method onFinish
     * @description Se ejecuta al hacer submit al formulario
     */
    onFinish = (values) => {
        const formData = new FormData()
        formData.appendMultiple({
            ...values,
            id: this.props.proyecto_id
        });

        if (this.props.proyecto_id) {
            // // console.log("this.props.proyecto_id",)
            // console.log("formData",formData)
            formData.append("id", this.props.proyecto_id)
            this.updateProyecto(formData);
        } else {
            this.addProyecto(formData);
        }

    };

    /**
     * @method addUser
     * @description Añade un nuevo registro de proyecto
     */
    addProyecto = (values) => {
        this.setState({ loading: true });

        axios
            .post("/proyectos", values)
            .then((response) => {
                message.success("Proyecto creado");
                this.props.onClose();
            })
            .catch((error) => {
                message.error("Error al crear el proyecto");
            })
            .finally(() => this.setState({ loading: false }));
    };

    /**
     * @method updateUser
     * @description Actualiza la informacion de un proyecto
     */
    updateProyecto = (values) => {
        this.setState({ loading: true });

        axios
            .put("/proyectos", values)
            .then((response) => {
                message.success("Proyecto actualizado");
                this.props.onClose();
            })
            .catch((error) => {
                message.error("Error al actualizar el proyecto");
            })
            .finally(() => this.setState({ loading: false }));
    };



    /**
     *
     * @memberof ModalProyectos
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con exito");
                this.setState({ loadingImage: false, image: null })
            })
            .catch(res => {
                console.log("imagen no se puedo remover");
            })
    };



    render() {

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-proyecto"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                >
                    <Row align="center">
                        <Form.Item
                            // labelCol={{ span: 12 }}
                            name="logo"

                        >
                            <SimpleUploader
                                url={axios.defaults.baseURL + "/proyectos/logo/"}
                                _id={this.props.proyecto_id}
                                uploadTitle="Subir Avatar"
                                imageCrop={true}
                                uploadContent={<div>
                                    <UploadOutlined style={{ color: "currentcolor", fontSize: 24 }} />
                                    <div className="ant-upload-text">Subir Logo del Proyecto</div>
                                </div>}
                            />
                        </Form.Item>
                    </Row>
                    <Row className="w-100" gutter={[16, 0]}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                // labelCol={{ span: 12 }}
                                name="nombre"
                                label="Nombre"
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingrese el nombre",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="descripcion"
                                label="Descripcion"
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingrese los descripción",
                                    },
                                ]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                // labelCol={{ span: 12 }}
                                name="direccion"
                                label="Dirección"
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingrese la dirección",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                // labelCol={{ span: 12 }}
                                name="ciudad"
                                label="Ciudad"
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingrese la ciudad del Proyecto",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                // labelCol={{ span: 12 }}
                                name="estado"
                                label="Estado"
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingrese el estado",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                // labelCol={{ span: 12 }}
                                name="razon_social"
                                label="Razón Social"
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingrese la razón social",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                // labelCol={{ span: 12 }}
                                name="url"
                                label="URL del Proyecto"
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingrese la dirección web del proyecto",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="representante_id"
                                label="Representante Legal"
                            >
                                <SelectUsuario />
                            </Form.Item>
                        </Col>
                        <Col
                            xs={24}
                            className="mb-1"
                        >
                            <Form.Item label="Color" name="color">
                                <ColorPicker />
                            </Form.Item>
                        </Col>
                        <Col
                            xs={24}
                            className="mb-1"
                        >
                            <Form.Item
                                name="firma"
                                className="width-100"
                            >
                                <SimpleUploader
                                    url={axios.defaults.baseURL + "/proyectos/firma/"}
                                    _id={this.props.proyecto_id}
                                    uploadTitle="Subir Firma de Representante"
                                    uploadContent={<div style={{ textAlign: "center", backgroundColor: "#fafafa", border: "1px dashed #d9d9d9", padding: "1em", borderRadius: "5px" }}>
                                        <UploadOutlined style={{ color: "currentcolor", fontSize: 24 }} />
                                        <div className="ant-upload-text">Subir Firma de Representante</div>
                                        <small>Se utilizará para la generación de Recibos de Pago</small>
                                    </div>}
                                    buttonDelete={false}
                                    uploaderProps={{
                                        listType: null,
                                        showUploadList: true,
                                    }}
                                    remove={true}
                                    imageCrop={false}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row align="center" className="width-100">
                        <Form.Item
                            name="image"
                            className="width-100"
                        >
                            <SimpleUploader
                                url={axios.defaults.baseURL + "/proyectos/image/"}
                                _id={this.props.proyecto_id}
                                uploadTitle="Subir Avatar"
                                imageCrop={false}
                                uploadContent={<div style={{ textAlign: "center", backgroundColor: "#fafafa", border: "1px dashed #d9d9d9", padding: "1em", borderRadius: "5px" }}>
                                    <UploadOutlined style={{ color: "currentcolor", fontSize: 24 }} />
                                    <div className="ant-upload-text">Subir Logo en PNG</div>
                                    <small>Se utilizará para la generación de Recibos de Pago y Reportes</small>
                                </div>}
                                buttonDelete={false}
                                uploaderProps={{
                                    listType: null,
                                    showUploadList: true,
                                }}
                                remove={true}
                            />
                        </Form.Item>
                    </Row>
                </Form>
            </Spin>
        );
    }
}

export default function Vista(props) {
    const { visible = false, onClose = () => { } } = props;

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            title={props.proyecto_id ? "Editar Proyecto" : "Crear Proyecto"}
            closable={true}
            destroyOnClose={true}
            zIndex={1000}
            cancelText="Cancelar"
            okText="Guardar"
            okButtonProps={{
                form: "form-proyecto",
                key: "submit",
                htmlType: "submit",
            }}
        >
            <ModalProyectos {...props} />
        </Modal>
    );
}
