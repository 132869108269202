import React, { Component } from "react";
//import ReactToPrint from "react-to-print";
import { Row, Col,  Table, Typography, } from 'antd';
 //compoentes
import axios from 'axios';
//css
import './reporteTransaccion.css'

const moment = require('moment');
const { Title, Paragraph, } = Typography;
const formatterDolar = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
var consulta = 0;

class ComponentToPrint extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fecha: '',
            espacio: '',
            dataInfo: [],
            author: '',
            totalTran: '',
            sinFiltros: true,

            fecha_inicio: '',
            fecha_fin: '',
            area: '',
            rubro: '',
            proyectos: [],
            cuentas: [],
            consulta: 0,
            montoPagado: 0,

            totalVenta: 0,
            totalPagado: 0,
            totalDeuda: 0,

            totalMontoPendiente:0,
            totalMontoPrestamo: 0,
            totalMontoRetorno: 0,

        }
    }

    ImpresionRptTransaccion = React.createRef();

    componentDidMount() {
        const { rpt } = this.props;
    }

    componentDidUpdate() {

        const { rpt } = this.props;
        var numConsulta = rpt.numConsulta;
        var consulta = this.state.consulta;

        if (rpt.author !=="" && numConsulta !==consulta) {
            console.log("rpt")
            console.log(rpt)


            var montoTotal = formatterDolar.format(rpt.totalTran).replace('$', '');

            this.setState({
                dataInfo: rpt.dataInfo,
                author: rpt.author,
                fecha: rpt.fecha,
                filtros: (rpt.sinFiltros) ? 'N/A' : '',
                totalTran: rpt.montoPagado,
                sinFiltros: rpt.sinFiltros,
                fecha_inicio: rpt.fecha_inicio,
                fecha_fin: rpt.fecha_fin,

                area: rpt.area,
                rubro: rpt.rubro,
                proyectos: rpt.proyectos,
                cuentas: rpt.cuentas,
                montoPagado: rpt.montoPagado,
                consulta: rpt.numConsulta,
                montoPagado: rpt.montoPagado,

                totalVenta: rpt.totalVenta,
                totalPagado: rpt.totalPagado,
                totalDeuda: rpt.totalDeuda,


                totalMontoPendiente:  rpt._totalMontoPendiente,  
                totalMontoPrestamo:   rpt._totalMontoPrestamo,  
                totalMontoRetorno:    rpt._totalMontoRetorno ,  
                totalMontoPagado:     rpt._totalMontoPagado ,  
 
            })
        }
    }

    render() {

        const columns = [
            {
                title: 'No.',
                dataIndex: 'index',
                key: 'index',
                width: 100
            },
            
            {
                title: 'Acreedor',
                dataIndex: 'nombre',
                key: 'nombre',
            },

            {
                title: 'Proyecto',
                dataIndex: 'proyecto',
                key: 'proyecto',
            },
            
            {
                title: 'Monto a pagar',
                dataIndex: 'montoRetorno',
                key: 'montoRetorno',
            },
            {
                title: 'Monto pagado',
                dataIndex: 'montoPagado',
                key: 'montoPagado',
            },
            {
                title: 'Monto pendiente',
                dataIndex: 'montoPendiente',
                key: 'montoPendiente',
            },
            // {
            //     title: 'Fecha',
            //     dataIndex: 'fecha',
            //     key: 'fecha',
            // },

        ];

        return (
            <section className='print-source' >
                <Row className="sec-encabezado">
                    <Col span={4} className="header-logo">
                        <img src="/images/logo-reporte.png" width="180" alt="" />
                    </Col>
                    <Col span={14}>
                        <h2 style={{ textAlign: "center" }}>REPORTE ACREEDORES</h2>

                        <Row style={{ marginTop: "-30px !important" }}>
                            <h2 style={{ textAlign: "center", marginTop: '0px', padding: '0px' }} >FILTROS</h2>
                            {(this.state.sinFiltros) ?
                                <Row className="row-filtros">
                                    <h3> N/A </h3>
                                </Row>
                                :
                                <Row className="row-filtros">

                                    {(this.state.fecha_inicio) ?
                                        <Col span={8}>
                                            <small>Fecha Inicio</small>
                                            <p>{this.state.fecha_inicio}</p>

                                        </Col>
                                        : ''}

                                    {(this.state.fecha_fin) ?
                                        <Col span={8}>
                                            <small>Fecha Final</small>
                                            <p>{this.state.fecha_fin}</p>

                                        </Col>
                                        : ''}

                                    {(this.state.area) ?
                                        <Col span={8}>
                                            <small>Area</small>
                                            <p>{this.state.area}</p>

                                        </Col>
                                        : ''}

                                    {(this.state.rubro) ?
                                        <Col span={8}>
                                            <small>Rubro</small>
                                            <p>{this.state.rubro}</p>

                                        </Col>
                                        : ''}

                                </Row>
                            }
                        </Row>

                    </Col>
                    <Col span={4}>
                        <Row style={{ textAlign: 'right' }}>
                            <h2>FECHA</h2>
                            <h3>{this.state.fecha}</h3>
                        </Row>

                        <Row style={{ textAlign: 'right', marginTop: '15px' }}>
                            <h2>AUTOR</h2>
                            <h3>{this.state.author}</h3>
                        </Row>

                    </Col>
                </Row>

                <Row style={{ width: '100%', paddingTop: '10px' }} >
                    <Table
                        columns={columns}
                        dataSource={this.state.dataInfo}
                        className="blankTheme tablaRpt"
                        pagination={false}
                    />
                </Row>


                <Row className="secTotalCliente" style={{ float: 'right', marginTop: '50px' }}>
                    <h1 className="tagTotal">TOTAL</h1>
                    <div className="div-total div-totalTran">
                        <h1>$</h1>
                        <h3>{this.state.totalMontoRetorno}</h3>
                        <h2> MXN</h2>
                    </div>
                </Row>

                <Row className="secTotalCliente" style={{ float: 'right', marginTop: '30px' }}>
                    <h1 className="tagTotal">TOTAL PAGADO</h1>
                    <div className="div-total div-totalTran">
                        <h1>$</h1>
                        <h3>{this.state.totalMontoPagado}</h3>
                        <h2> MXN</h2>
                    </div>
                </Row>

                <Row className="secTotalCliente" style={{ float: 'right', marginTop: '30px' }}>
                    <h1 className="tagTotal">TOTAL DEUDA</h1>
                    <div className="div-total div-totalTran">
                        <h1>$</h1>
                        <h3>{this.state.totalMontoPendiente}</h3>
                        <h2> MXN</h2>
                    </div>
                </Row>

            </section>
        );
    }
}



class Reporte extends Component {
    render() {
        return (
            <div style={{ width: '100%' }}>
                {/* <ReactToPrint
                    trigger={() => <a href="#"><Download style={{ marginTop: "5px" }} /></a>}
                    content={() => this.ImpresionRptTransaccion}
                /> */}
                <ComponentToPrint rpt={this.props.rpt} ref={el => (this.ImpresionRptTransaccion = el)} />
            </div>
        );
    }
}


function Download(props) {
    return <svg {...props} width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.86347 11.1094C8.86347 8.56789 8.86347 6.3264 8.86347 4.0849C8.86347 3.03965 8.82048 1.99244 8.88887 0.953078C8.91037 0.62558 9.22887 0.317693 9.4106 0C9.60014 0.315731 9.94795 0.629502 9.95381 0.947195C9.99485 3.94959 9.97922 6.95198 9.97922 9.95633C9.97922 10.2387 9.97922 10.5211 9.97922 10.8035C10.0632 10.8604 10.1473 10.9172 10.2332 10.9741C11.3216 9.8367 12.3963 8.6836 13.5101 7.56971C13.8345 7.24614 14.1784 6.49113 14.7373 7.04022C15.3293 7.6207 14.5927 8.02076 14.2429 8.37963C12.8731 9.77983 11.4604 11.131 10.1004 12.5332C9.59623 13.0528 9.23864 13.047 8.73646 12.5273C7.28852 11.029 5.77806 9.58961 4.34186 8.08155C4.12301 7.85211 4.16209 7.37753 4.08002 7.01473C4.41025 7.10494 4.83818 7.09513 5.05703 7.30301C6.28025 8.46592 7.45071 9.6857 8.86347 11.1094Z"
            fill="white" />
        <path
            d="M17.2996 15.9283C17.2996 14.7264 17.2607 13.586 17.3248 12.4493C17.3423 12.1442 17.6804 11.8558 17.8709 11.56C18.0322 11.8502 18.3159 12.1349 18.3295 12.4307C18.3839 13.5432 18.3489 14.6595 18.3489 15.7757C18.3489 16.5757 17.9136 16.9887 17.0975 16.9906C11.8254 17.0017 6.5513 16.9999 1.27724 16.9999C0.443575 16.9999 0.0277133 16.5608 0.0160537 15.7869C-0.00143584 14.6092 -0.0150388 13.4316 0.0335432 12.2558C0.0432595 12.0195 0.328921 11.7944 0.48827 11.5637C0.680655 11.7944 1.02656 12.0195 1.03822 12.2577C1.09457 13.4558 1.06348 14.6576 1.06348 15.9283C6.48328 15.9283 11.8079 15.9283 17.2996 15.9283Z"
            fill="white" />
    </svg>

}

export default Reporte;
