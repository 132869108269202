import React, { Component } from 'react';
import { Card, Spin, Layout, Typography, Row, Button } from 'antd'
import ReactEcharts from "echarts-for-react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import axios from 'axios';


import './Sections.scss'

import Logged from '../../../../Hooks/Logged'
import usePermissions from '../../../../Hooks/usePermissions';
import moment from 'moment';

const { Content } = Layout;
const { Text } = Typography;


export class HistorialPrecios extends Component {

    constructor(props) {
        super(props)
        this.state = {

            fechaInicio: new Date(),
            fechas: [],
            datos: [],
        }
    }

    componentDidMount() {


        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getHistorialPrecios();
    }

    getHistorialPrecios = () => {
        this.setState({ loading: true })
        axios.get('/productos-proveedores/historial', {
            params: {
                producto_id: this.props.producto_id,
                fechaInicio: moment(this.state.fechaInicio).format('YYYY-MM-DD'),
            }
        }).then(({ data }) => {
            this.setState({ datos: data.historial, fechas: data.fechas })
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            this.setState({ loading: false })
        })
    }


    render() {
        const colors = ['#5470C6', '#EE6666'];
        return (
            <>
                <Spin spinning={false}>
                    <Content className="admin-content content-bg">
                        <Card className='card-shadow card-graph-dashboard' bordered={false}>
                            <Text className='titulo'>Historial de Precios</Text>
                            { }
                            { }
                            <ReactEcharts

                                option={{
                                    legend: {
                                        
                                    },
                                    tooltip: {
                                        trigger: 'item',
                                        axisPointer: { type: 'item' },
                                        formatter: (params) => {
                                            
                                            return `${params.seriesName} <br/> $ ${params.data.toMoney(true)} mxn <br/> ${moment(params.name).format('MMMM yyyy')}`
                                        }
                                    },
                                    grid: {
                                        left: '3%',
                                        right: '4%',
                                        bottom: '3%',
                                        containLabel: true
                                    },
                                    xAxis: [{
                                        type: 'category',
                                        axisTick: {
                                            alighWithLabel: true
                                        },
                                        axisLine: {
                                            onZero: false,
                                        },
                                        axisPointer: {
                                            label: 'axis'
                                        },
                                        data: this.state.fechas
                                    }],
                                    yAxis: [{
                                        type: 'value',
                                    }],
                                    series: this.state?.datos.map((item, index) => {
                                        return {
                                            name: item.alias,
                                            type: 'line',
                                            emphasis: {
                                                focus: 'series'
                                            },
                                            data: this.state?.fechas.map((fecha) => this.state.datos[index]?.precios[`${String(fecha)}`]),
                                            smooth: true,
                                        }
                                    })
                                }}
                            />
                            <Row justify="center">
                                <Button onClick={() => {this.setState({fechaInicio: moment(this.state.fechaInicio).add(1, 'month').toDate()}, () => this.getHistorialPrecios())}}> <AiOutlineArrowLeft/> </Button>
                                <Button onClick={() => {this.setState({fechaInicio: moment(this.state.fechaInicio).subtract(1, 'month').toDate()}, () => this.getHistorialPrecios())}}> <AiOutlineArrowRight/> </Button>
                            </Row>
                        </Card>
                    </Content>



                </Spin>
            </>
        )
    }
}


export default function (props) {




    return <HistorialPrecios {...props} />

}