import React, { Component } from "react";
import { Layout, Row, Col, Button, List, Space, Typography, Card, message, Popconfirm, PageHeader } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FilterOutlined, PlusOutlined, MinusOutlined, SwapOutlined, StopOutlined, EditOutlined, DeleteOutlined, FileSearchOutlined, DownloadOutlined, FilePdfOutlined, FileExcelOutlined } from "@ant-design/icons";

//componentes
import Sort from '../../../Widgets/Sort';
import Logged from '../../../../Hooks/Logged';
import { color_transaccion_tipo } from "../../../Utils";
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import usePermissions from '../../../../Hooks/usePermissions';
import DrawerFiltros from "../../../Widgets/DrawerFiltros/DrawerFiltros";
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
import { IconDelete, IconEdit, IconDetails, IconSATSCR } from '../../../Widgets/Iconos';

//modales
import ModalTransaccion from "./ModalTransaccion";
import ModalTransaccionDetalle from "./ModalTransaccionDetalle"

import useSearchParams from '../../../../Hooks/SearchParams';

const { Content } = Layout;
const { Text } = Typography;

const axios = require('axios')
const moment = require('moment')

/**
 *
 *
 * @export
 * @class Transacciones
 * @extends {Component}
 * @description Vista del listado de Transacciones
 */
class Transacciones extends Component {

	constructor(props) {
		super(props)
		console.log("Params", this.props.params)

		let { page, limit, filters, search, sort } = this.props.params

		try { sort  = JSON.stringify(sort) } catch {} 
		try { filters  = JSON.stringify(filters) } catch {} 

		
		this.state = {


			transacciones: {

				page: page ?? 1,
				limit: limit ?? 20,

				total: 0,
				pages: 0,

				search: search ?? undefined,

				filters: filters ?? [],
				sort: sort ?? {
					fecha: -1
				},

			},

			loading_csv:false,
			loading_pdf:false,
		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

		this.getTransacciones();
	}

	componentDidUpdate(prevProps) {
		if (this.props.search !== prevProps.search) {
			this.getTransacciones({ page: 1, search: this.props.search })
		}
	}

	/**
	*
	*
	* @memberof Transacciones
	* @description Obtiene todas todas las transacciones
	* @param page pagina actual
	* @param limit limite de objetos de una pagina
	*/
	getTransacciones = ({
		page = this.state.transacciones.page,
		limit = this.state.transacciones.limit,
		search = this.props.search,
		filters = this.state.transacciones.filters,
		sort = this.state.transacciones.sort,

	} = this.state.transacciones) => {

		console.log("sort",sort);
		const params = { page, limit, }
		if (search) params.search = search
		if (sort && Object.values(sort).length > 0) params.sort = JSON.stringify(sort)
		if (filters && filters?.length > 0) params.filters = JSON.stringify(filters)
		this.props.setParams(params)

		this.setState({ transacciones: { ...this.state.transacciones, page, limit, search, filters, sort }, loading: true })


		axios.post('/transacciones/filters', {
			page,
			limit,
			filters,
			sort,
			search
		})
			.then(({ data }) => {
				let transacciones = data.data
				this.setState({
					transacciones: {
						...this.state.transacciones,
						data: transacciones,
						total: data.total,
						pages: data.pages,
						limit,
						page,
						loading: false
					},
					loading: false
				});
			})
			.catch(res => {
				message.error('No se pudieron cargar las transacciones')
			})
			.finally(() => this.setState({ loading: false }))
	}

	downloadFile = (type = "csv", {
		search = this.props.search,
		filters = this.state.transacciones.filters,
		sort = this.state.transacciones.sort,

	} = this.state.transacciones) => {

		this.setState({ ['loading_' + type] : true })

		axios.post('/transacciones/filters', {
			filters,
			sort,
			search,
			file: type
		}, {responseType: 'blob',})
			.then(({ data, headers }) => {
				const url = window.URL.createObjectURL(new Blob([data], {type: headers['content-type']}));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'archivo.' + type);
				document.body.appendChild(link);
				link.click();
			})
			.catch(res => {
				console.log("R",res);
				message.error('No se pudieron cargar las transacciones')
			})
			.finally(() => 		this.setState({ ['loading_' + type] : false }))

	}

	/**
	 *
	 *
	 * @param {*} key
	 * @memberof Transacciones
	 */
	setSort = (key) => {
		let value;
		switch (this.state.transacciones.sort[key]) {
			case 1:
				value = -1
				break;
			case -1:
				value = undefined
				break;
			default:
				value = 1
				break;
		}
		this.getTransacciones({
			sort: {
				[key]: value
			}
		})

		this.setState({
			transacciones: {
				...this.states.transacciones,
				sort: {
					[key]: value
				}
			}
		})
	}

	/**
 *
 *
 * @param {*} tipo
 * @memberof Transacciones
 * @description Renderiza el icono según el estatus
 */
	renderIconTransaccion = (tipo) => {
		switch (tipo) {
			case 1:
				return <PlusOutlined style={{ color: "currentColor" }} />
			case 2:
				return <MinusOutlined style={{ color: "currentColor" }} />
			case 3:
				return <SwapOutlined style={{ color: "currentColor" }} />
			case 4:
				return <StopOutlined style={{ color: "currentColor" }} />
		}
	}


	render() {

		console.log("this.state.transacciones?.sort", this.state.transacciones?.sort);

		let pageLimit = (this.state.transacciones.page * this.state.transacciones.limit) - this.state.transacciones.limit

		return (
			<>
				<PageHeader
					className='custom-page-header'
					title="Lista de Transacciones"
					breadcrumb={{
						className: "custom-page-header-breadcrumb",
						itemRender: (route, params, routes, paths) => (routes.indexOf(route) === routes.length - 1) ? route.breadcrumbName : <Link to={route.path}>{route.breadcrumbName}</Link>,
						routes: [
							{
								path: "/admin/finanzas",
								breadcrumbName: 'Finanzas',
							},
							{
								path: "/admin/finanzas/transacciones",
								breadcrumbName: 'Transacciones',
							}
						]
					}}

					extra={
						<Space>
							<Text style={{ color: "white" }}>
								Visualizando de {(pageLimit > 0) ? pageLimit : 1} a {(this.state?.transacciones?.page * this.state?.transacciones?.limit)} de {this.state.transacciones?.total?.toLocaleString()} transacciones</Text>
							<Button
								type="dashed"
								onClick={() => this.setState({ visibleFilters: true })}
								className="button-plus"
								icon={<FilterOutlined />} />
						</Space>
					}
				/>
				<Content className="admin-content content-bg pd-1">
					<List
						loading={this.state.loading}
						className="component-list "
						itemLayout="horizontal"
						locale={{ emptyText: "Sin Transacciones" }}
						dataSource={this.state.transacciones?.data}
						pagination={{
							current: this.state.transacciones.page,
							pageSize: this.state.transacciones.limit,
							total: this.state.transacciones.total,
							position: 'bottom',
							className: "flex-left",
							showSizeChanger: true,
							pageSizeOptions: [20, 50, 100, 500],
							showQuickJumper: true,
							locale: { jump_to: "Ir a la página", page: '' },
							onChange: (page, limit) => {
								this.getTransacciones({ page, limit })
							}
						}}
						header={<Row className="header-list width-100 ">
							<Col xs={2} className=" center">
								<Sort
									sort={this.state.transacciones?.sort["fecha"]} onClick={() => this.setSort("fecha")} >
									<Text strong >FECHA</Text>
								</Sort>
							</Col>
							<Col xs={4} className="center">
								<Sort
									sort={this.state.transacciones?.sort["concepto"]} onClick={() => this.setSort("concepto")} >
									<Text strong >CONCEPTO</Text>
								</Sort>
							</Col>
							<Col xs={3} className="center">
								<Sort
									sort={this.state.transacciones?.sort["area_id.nombre"]} onClick={() => this.setSort("area_id.nombre")} >
									<Text strong >ÁREA</Text>
								</Sort>
							</Col>
							<Col xs={3} className="center">
								<Sort
									sort={this.state.transacciones?.sort["rubro_id.nombre"]} onClick={() => this.setSort("rubro_id.nombre")}
								>
									<Text strong >RUBRO</Text>
								</Sort>
							</Col>
							<Col xs={4} className="center">
								<Sort
									sort={this.state.transacciones?.sort["empresa_id.nombre"]} onClick={() => this.setSort("empresa_id.nombre")}
								>
									<Text strong >EMPRESA/CLIENTE</Text>
								</Sort>
							</Col>
							<Col xs={3} className=" center">
								<Sort
									sort={this.state.transacciones?.sort["cuenta_id.nombre"]}
									onClick={() => this.setSort("cuenta_id.nombre")} >
									<Text strong >CUENTA</Text>
								</Sort>
							</Col>
							<Col xs={3} className="center">
								<Sort
									sort={this.state.transacciones?.sort["monto"]}
									onClick={() => this.setSort("monto")} >
									<Text strong >MONTO</Text>
								</Sort>
							</Col>
							<Col span={2}></Col>
						</Row>
						}

						renderItem={item => (
							<List.Item className="component-list-item ">
								<Card className="card-list" bordered={false}>
									<div className={`badge-card tipo-${item.tipo}`}>
									</div>
									<Row className="width-100" >
										<Col xs={2} className="center">
											<Text strong>{moment(item.fecha).format('YYYY-MM-DD')}</Text>
										</Col>
										<Col xs={4} className="center">
											<Text ellipsis className="text-gray-dark">{item.concepto}</Text>
										</Col>
										<Col xs={3} className="center">
											<Text ellipsis className="text-gray-dark">{item.area_id?.nombre}</Text>
										</Col>
										<Col xs={3} className="center">
											<Text ellipsis className="text-gray-dark">{item.rubro_id?.nombre}</Text>
										</Col>

										<Col xs={4} className="center flex-column">

											{item?.empresa_id && <>
												<CustomAvatar
													size="small"
													image={axios.defaults.baseURL + '/upload/' + item?.empresa_id?.logo}
													color={item?.empresa_id?.color}
													name={item?.empresa_id?.alias}
												/>
												<Text ellipsis>{item?.empresa_id?.alias} </Text>
											</>}

										</Col>
										<Col xs={3} className=" center">
											{item?.cuenta_id && <>
												<CustomAvatar
													image={axios.defaults.baseURL + '/upload/' + item?.cuenta_id?.logo}
													color={item.cuenta_id.color}
													name={item.cuenta_id?.nombre}
												/>
												<Text className="text-gray-dark">{item.cuenta_id?.nombre}</Text>
											</>}

										</Col>
										<Col xs={3} className="center">
											<Text style={{ color: color_transaccion_tipo(item.tipo), fontWeight: "bold" }} >
												{this.renderIconTransaccion(item.tipo)} $ {item.monto.toMoney(true)} MXN</Text>
										</Col>
										<Col span={2} className="center">
											<Space wrap>
												<Button

													icon={<FileSearchOutlined style={{ color: "currentcolor" }} />}
													type="secondary"
													title="Ver Detalle"
													onClick={() => this.setState({ modalTransacDetalleVisible: true, transaccion_id: item._id })}>
												</Button>
												<Button
													className="ant-btn-primary-blue"
													disabled={!this.props.editTransacciones}
													icon={<EditOutlined style={{ color: "currentcolor" }} />}
													type="primary"
													title="Editar"
													onClick={() => this.setState({ modalTransacVisible: true, transaccion_id: item._id })}>
												</Button>

												<Popconfirm
													disabled={!this.props.deleteTransacciones}
													placement="topRight"
													title="¿Deseas eliminar esta Transacción?"
													onConfirm={() => axios.delete('/transacciones', { params: { id: item._id } }).then((response) => {
														message.success('Transacción Eliminada')
														this.getTransacciones()
													}).catch((error) => {
														message.success('Transacción NO Eliminada')
														this.getTransacciones();
													})
													}
													okText="Si"
													cancelText="No"
												>
													<Button
														className={'ant-btn-primary-red'}
														type="primary" size={"middle"}
														disabled={!this.props.deleteTransacciones}
														icon={<DeleteOutlined style={{ color: "currentcolor" }} />} title="Eliminar"
													/>
												</Popconfirm>
											</Space>
										</Col>
									</Row>
								</Card>
							</List.Item>
						)}
					/>
				</Content>
				{(this.props.createTransacciones) ? <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalTransacVisible: true })} /> : null}

				<ModalTransaccion
					visible={this.state.modalTransacVisible}
					transaccion_id={this.state.transaccion_id}
					onClose={() => {
						this.setState({ modalTransacVisible: false, transaccion_id: undefined })
						this.getTransacciones()
					}}
				/>
				<ModalTransaccionDetalle
					visible={this.state.modalTransacDetalleVisible}
					onClose={() => this.setState({
						modalTransacDetalleVisible: false,
						transaccion_id: null
					})}
					transaccion_id={this.state.transaccion_id}
				/>
				<DrawerFiltros
					title="Filtrar Transacciones"
					ref={ref => this.drawerFiltros = ref}
					updateFilters={filters => this.getTransacciones({
						page: 1,
						filters
					})}
					extra={<>
						<Button
							loading={this.state.loading_pdf}
							type="primary"
							onClick={() => this.downloadFile('pdf')}
							icon={<FilePdfOutlined />}
						/>
						<Button
							loading={this.state.loading_csv}
							type="primary"
							onClick={() => this.downloadFile('csv')}
							icon={<FileExcelOutlined />}
						/>
					</>}
					// csv="transacciones"
					onClose={() => this.setState({ visibleFilters: false })}
					visible={this.state.visibleFilters}
					ordenes_compras={false}
					ordenes={false}
					proveedores={true}
					accredores={true}
					inversiones={true}
				/>
			</>
		)
	}
}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {

	let user = React.useContext(Logged)
	const [params, setParams] = useSearchParams();

	let permissions = usePermissions(user?.rol_id?.permisos, {
		createTransacciones: ["transacciones", "create"],
		editTransacciones: ["transacciones", "edit"],
		readTransacciones: ["transacciones", "read"],
		deleteTransacciones: ["transacciones", "delete"],
	})


	return (<Transacciones {...props} navigate={useNavigate()} {...permissions} setParams={setParams} params={params} />)
}