import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Breadcrumb, Typography, Space, List } from 'antd'
import axios from 'axios';
import { Link, useNavigate, useParams } from "react-router-dom";
import { IconArrowBack, IconDelete, IconEdit } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import ModalRubros from './ModalRubros'

import Logged from '../../../Hooks/Logged'
import usePermissions from '../../../Hooks/usePermissions';

const { Content } = Layout;
const { Text } = Typography;

/**
 * @export
 * @class Rubros
 * @extends {Component}
 * @description Vista de Rubros
 */
class Rubros extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modal_visible: false,

            area_id: this.props.params.area_id,
            rubro_id: undefined,
            rubros: {
                data: [],

                page: 1,
                limit: 20,
                
                total: 0,
                pages: 0
            },
            area: undefined,

            page: 1,
            total: 0,
        }
    }

    componentDidMount() {
        console.log(this.props)
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        //this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getArea();
        this.getRubros();
    }



    /**
     * @memberof Rubros
     * @method getArea
     * @description Obtiene una area de la DB
     */
    getArea = () => {
        axios.get(`/areas/${this.state.area_id}`, {
            params: {
                id: this.state.area_id
            }
        }).then(response => {
            let area = response.data.area
            this.setState({
                area
            })


        }).catch(error => {
            message.error('Error al traer el area')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof Rubros
     * @method getRubros
     * @description Obtiene los rubros relacionados al area
     */
    getRubros = ({
        page = this.state.rubros.page,
        limit = this.state.rubros.limit
    } = this.state.rubros) => {

        axios.get('/rubros', {
            params: {
                page,
                limit,
                area_id: this.state.area_id,
            }
        }).then(({ data }) => {
            console.log('rubros', data)
            this.setState({
                rubros: data
            });

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        })
    }


    render() {
        console.log('state rubros',this.state)
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        backIcon={<IconArrowBack/>}
                        onBack={(e) => this.props.navigate(-1)}
                        title={(this.state.area) ?
                            (
                                <Breadcrumb separator="de" className='breadcrumb-header'>
                                    <Breadcrumb.Item >Rubros</Breadcrumb.Item>
                                    <Breadcrumb.Item >
                                        <Link title="Ir a áreas" to={`/admin/areas`}>
                                            {this.state.area.nombre}
                                        </Link>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            )
                            : "Rubros"}
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Rubros " }}
                            dataSource={this.state.rubros.data}
                            pagination={{
                                current: this.state.rubros.page,
                                pageSize: this.state.rubros.limit,
                                total: this.state.rubros.total,
                                showSizeChanger: true,
                                position: 'bottom',
                                className: "flex-left",
                                onChange: (page, limit) => {
                                    console.log("page", page, limit);
                                    this.getRubros({ page, limit })
                                }
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-1" gutter={[16, 16]} >

                                <Col xs={24} lg={10}  className="center">
                                    <Text strong>Nombre</Text>
                                </Col>
                                <Col xs={24} lg={10} className="center">
                                    <Text strong>Descripción</Text>
                                </Col>
                                <Col xs={24} lg={4} className="center">
                                    <Text strong>Acciones</Text>
                                </Col>
                            </Row>}

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 " gutter={[16, 16]}>

                                            <Col xs={24} lg={10}  className="center">
                                                <Text>{item.nombre}</Text>
                                            </Col>
                                            <Col xs={24} lg={10}  className="center">
                                                <Text>{item.descripcion}</Text>
                                            </Col>
                                            <Col xs={24} lg={4} className="center">
                                                <Space size="small" direction="horizontal">
                                                    <Button
                                                        type="primary"
                                                        disabled = {!this.props.editRubros}
                                                        icon={<IconEdit />}
                                                        title="Editar"
                                                        onClick={() => { this.setState({ modal_visible: true, rubro_id: item._id }) }}
                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar este rubro?"
                                                        
                                                        onConfirm={() => axios.delete('/rubros',
                                                            { params: {id: item._id} }).then(() => {
                                                                this.getRubros()
                                                                message.success('Rubro eliminado')
                                                            }).catch(error => console.log(error))}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            type="primary"
                                                            disabled = {!this.props.deleteRubros}
                                                            icon={<IconDelete />}
                                                            title="Eliminar"
                                                            danger
                                                        />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>
                    {this.props.createRubros ? <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} /> : null}

                    <ModalRubros
                        visible={this.state.modal_visible}
                        onClose={() => {
                            this.setState({ modal_visible: false, rubro_id: undefined })
                            this.getRubros()
                        }}
                        rubro_id={this.state.rubro_id}
                        area_id={this.state.area_id}
                    />
                </Spin>
            </>
        )
    }

}

export default function Vista(props) {

    let user = React.useContext(Logged)

    
	let permissions = usePermissions(user?.rol_id?.permisos, {
		editRubros: ["areas-rubros", "edit"],
		createRubros: ["areas-rubros", "create"],
		deleteRubros: ["areas-rubros", "delete"],

	})

    return <Rubros {...props} {...permissions} params={useParams()} navigate={useNavigate()} />

}