import React, { Component } from "react";
import { Layout, Menu, Button } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons';
import '../../Styles/Global/sider.css';
import { Link, useNavigate } from "react-router-dom"
import { FaFileInvoiceDollar, FaBuilding, FaBoxes, FaSitemap } from "react-icons/fa"
import {
    IconProducts,
    IconCuentas,
    IconFinanzas,
    IconDashboard,
    IconContabilidad,
    IconUsuarios,
    IconRubros,
    IconVentas,
    IconCRM,
    IconFinancialHealth,
    IconDeuda,
    IconRH
} from '../Widgets/Iconos.js'

import { ArrowUpOutlined, ArrowDownOutlined, DollarOutlined,ClockCircleOutlined } from "@ant-design/icons";

import usePermissions from '../../Hooks/usePermissions'
import { User } from '../../Hooks/Logged'

const { SubMenu } = Menu;
const { Sider } = Layout;





/**
 *
 *
 * @export
 * @class Admin_Sidebar
 * @extends {Component}
 */
class AdminSidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false
        }
    }


    /**
     *
     *
     * @memberof AdminSidebar
     */
    toogle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }
    render() {

        const {

            accessDashboard,
            accessFinanzas,

            accessTransacciones,
            accessContabilidad,
            accessFacturas,
            accessAcreedores,
            accessInversiones,
            
            accessCashFlow,
            accessCashFlowLista,
            accessCashFlowCuentas,

            accessCRM,
            accessClientes,
            accessBudgeting,
            accessProveedores,
            accessAreasRubros,
            accessEmpresas,
            accessCuentas,
            accessProyectos,
            accessUsuarios,
            accessRoles,
            accessEmpleados
        } = this.props


        return (
            <Sider theme="light"
                width={250}
                breakpoint="xl"
                collapsedWidth="0"
                className="custom-sider sider-width"
            >
                <Menu
                    mode="inline"
                    theme="light"
                    className="heigth-100 "
                    onClick={()=>{
                        this.props.onChangeModulo()
                    }}
                // style={{ borderRight: 0 }}
                >
                    <Button ghost onClick={this.toogle} className="no-border">
                        {this.state.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    </Button>

                    {
                        (accessDashboard || (accessFinanzas || accessTransacciones) || (accessContabilidad || accessFacturas) || (accessCRM || accessClientes) || accessBudgeting || accessProveedores) ? <>
                            <Menu.ItemGroup key="navegacion" title="NAVEGACIÓN">
                                {accessDashboard ? <Menu.Item key="dashboard" icon={<IconDashboard />} onClick={() => this.props.navigate("/admin/dashboard",)}>
                                    Dashboard
                                </Menu.Item> : null}

                                {accessFinanzas ? <SubMenu key="finanzas" title={<a onClick={() => this.props.navigate("/admin/finanzas",)}>Finanzas</a>} icon={<IconFinanzas />}>
                                    <Menu.Item key="transacciones" icon={<DollarOutlined />} onClick={() => this.props.navigate("/admin/finanzas/transacciones",)}>
                                        Transacciones
                                    </Menu.Item>
                                </SubMenu> : null}
                                {(!accessFinanzas && accessTransacciones) ? <Menu.Item key="transacciones-1" icon={<DollarOutlined />} onClick={() => this.props.navigate("/admin/finanzas/transacciones",)}>
                                    Transacciones
                                </Menu.Item> : null}

                                {accessContabilidad ? <SubMenu key="contabilidad" title={<a onClick={() => this.props.navigate("/admin/contabilidad",)}>Contabilidad</a>} icon={<IconContabilidad />}>
                                    <Menu.Item key="facturas" icon={<DollarOutlined />} onClick={() => this.props.navigate("/admin/contabilidad/facturas",)}>
                                        Facturas
                                    </Menu.Item>
                                </SubMenu> : null}
                                {(!accessContabilidad && accessFacturas) ? <Menu.Item key="facturas-1" icon={<DollarOutlined />} onClick={() => this.props.navigate("/admin/contabilidad/facturas",)}>
                                    Facturas
                                </Menu.Item> : null}


                                {(!accessCRM && accessClientes) ? <Menu.Item key="clientes" icon={<IconVentas />} onClick={() => this.props.navigate("/admin/crm/clientes",)}>
                                    Clientes
                                </Menu.Item> : null}
                                {accessCRM ? <SubMenu key="crm" title={<a onClick={() => this.props.navigate("/admin/crm",)}>CRM</a>} icon={<IconCRM />}>
                                    <Menu.Item key="clientes-1" icon={<IconVentas />} onClick={() => this.props.navigate("/admin/crm/clientes",)}>
                                        Clientes
                                    </Menu.Item>
                                </SubMenu> : null}


                                {accessBudgeting ? <Menu.Item key="Budgeting" icon={<FaBuilding />} onClick={() => this.props.navigate("/admin/budgeting",)}>
                                    Presupuestos
                                </Menu.Item> : null}

                                {
                                    accessCashFlow ? (
                                        <SubMenu
                                            icon={<ClockCircleOutlined />}
                                            title={<Link
                                                onClick={(e) => e.stopPropagation()}
                                                to={"/admin/cash-flow"}>Cash Flow</Link>}
                                            key="cash-flow">

                                            {
                                                accessCashFlowLista ? (
                                                    <Menu.Item
                                                        key="cash-flow-lista"
                                                        icon={<DollarOutlined />}
                                                        className="icon-sidebar">
                                                        <Link
                                                            to={"/admin/cash-flow/lista"}>Lista</Link>
                                                    </Menu.Item>
                                                ) : null
                                            }

                                            {
                                                accessCashFlowCuentas ? (
                                                    <Menu.Item
                                                        key="cash-flow-cuentas"
                                                        icon={<IconFinanzas />}
                                                        className="icon-sidebar">
                                                        <Link
                                                            to={"/admin/cash-flow/cuentas"}>Cuentas</Link>
                                                    </Menu.Item>
                                                ) : null
                                            }
                                            
                                        </SubMenu>
                                    ) : null
                                }
                                {
                                    (accessInversiones ||
                                    accessAcreedores) && (
                                        <SubMenu
                                            icon={<IconFinancialHealth />}
                                            title={<Link
                                                onClick={(e) => e.stopPropagation()}
                                                
                                                to={"/admin/financial-health"}>Financial Health</Link>}
                                            key="Health">

                                            {
                                                accessInversiones && (
                                                    <Menu.Item
                                                        key="3-4-56"
                                                        icon={<IconDeuda />}
                                                        className="icon-sidebar">
                                                        <Link
                                                            to={"/admin/financial-health/inversiones"}>Inversiones</Link>
                                                    </Menu.Item>
                                                )
                                            }

                                            {
                                                accessAcreedores && (
                                                    <Menu.Item
                                                        key="4-2-784"
                                                        icon={<IconFinanzas />}
                                                        className="icon-sidebar">
                                                        <Link
                                                            to={"/admin/acreedores"}>Acreedores</Link>
                                                    </Menu.Item>
                                                )
                                            }
                                        </SubMenu>
                                    )
                                }

                                {accessProveedores ? <Menu.Item key="proveedores" icon={<FaBuilding />} onClick={() => this.props.navigate("/admin/proveedores",)}>
                                    Proveedores
                                </Menu.Item> : null}
                                {accessEmpleados ? <Menu.Item key="empleados" icon={<IconRH />} onClick={() => this.props.navigate("/admin/empleados",)}>
                                    Recursos Humanos
                                </Menu.Item> : null}
                                <Menu.Item key="inventario" icon={<IconRubros />} onClick={() => this.props.navigate("/admin/inventario",)}>
                                    Inventario
                                </Menu.Item>
                                <SubMenu
                                    icon={<IconRubros />}
                                    title={<>Materiales</>}
                                    key="requisisciones">

                                    <Menu.Item
                                        key="requisisciones-compras"
                                        icon={<IconDeuda />}
                                        className="icon-sidebar">
                                        <Link
                                            to={"/admin/requisiciones/compras"}>Compras</Link>
                                    </Menu.Item>

                                    <Menu.Item
                                        key="requisisciones-lista"
                                        icon={<IconFinanzas />}
                                        className="icon-sidebar">
                                        <Link
                                            to={"/admin/requisiciones"}>Requisisciones</Link>
                                    </Menu.Item>
                                </SubMenu>
                            </Menu.ItemGroup>
                            <Menu.Divider className="group-sider-divider " />
                        </> : null
                    }
                    {(
                        accessCuentas ||
                        accessEmpresas ||
                        accessProyectos ||
                        accessAreasRubros ||
                        accessUsuarios ||
                        accessRoles
                    ) ? <Menu.ItemGroup key="g2" title="AJUSTES">

                        {accessCuentas ? <Menu.Item key="cuentas" icon={<IconCuentas />} onClick={() => this.props.navigate("/admin/cuentas",)}>
                            Cuentas
                        </Menu.Item> : null}
                        {accessEmpresas ? <Menu.Item key="empresas" icon={<FaBuilding />} onClick={() => this.props.navigate("/admin/empresas",)}>
                            Empresas
                        </Menu.Item> : null}
                        {accessProyectos ? <Menu.Item key="proyectos" icon={<IconRubros />} onClick={() => this.props.navigate("/admin/proyectos",)}>
                            Proyectos
                        </Menu.Item> : null}
                      

                        <Menu.Item key="almacenes" icon={<IconRubros />} onClick={() => this.props.navigate("/admin/almacenes",)}>
                            Almacenes
                        </Menu.Item>

                        <Menu.Item key="productos" icon={<IconRubros />} onClick={() => this.props.navigate("/admin/productos",)}>
                            Productos
                        </Menu.Item>

                        <Menu.Item key="categorias" icon={<IconRubros />} onClick={() => this.props.navigate("/admin/categorias",)}>
                            Categorías
                        </Menu.Item>

                        {accessAreasRubros ? <Menu.Item key="areas" icon={<IconRubros />} onClick={() => this.props.navigate("/admin/areas",)}>
                            Áreas
                        </Menu.Item> : null}
                        {accessUsuarios ? <Menu.Item key="usuarios" icon={<IconUsuarios />} onClick={() => this.props.navigate("/admin/usuarios",)}>
                            Usuarios
                        </Menu.Item> : null}
                        {accessRoles ? <Menu.Item key="clientes" icon={<FaSitemap />} onClick={() => this.props.navigate("/admin/roles",)}>
                            Roles
                        </Menu.Item> : null}
                    </Menu.ItemGroup> : null}
                </Menu>
            </Sider>
        )
    }
}

export default function (props) {

    let user = React.useContext(User)
    let permisos = usePermissions(user?.rol_id?.permisos, {

        accessDashboard: ["dashboard"],
        accessFinanzas: ["finanzas"],
        accessCashFlow: ["cash-flow", "access"],
        accessCashFlowLista: ["cash-flow", "lista"],
        accessCashFlowCuentas: ["cash-flow", "cuentas"],
        accessTransacciones: ["transacciones", "access"],
        accessContabilidad: ["contabilidad"],

        accessFacturas: ["facturas", "access"],

        accessCRM: ["crm"],
        accessClientes: ["clientes", "access"],
        accessBudgeting: ["budgeting"],

        accessInversiones: ["inversiones", "access"],
        accessAcreedores: ["acreedores", "access"],

        accessAreasRubros: ["areas-rubros", "access"],
        accessEmpresas: ["empresas", "access"],
        accessCuentas: ["cuentas", "access"],
        accessProyectos: ["proyectos", "access"],
        accessProveedores: ["proveedores", "access"],
        accessEmpleados: ["empleados", "access"],
        accessUsuarios: ["usuarios", "access"],
        accessRoles: ["roles", "access"],
    })

    return <AdminSidebar navigate={useNavigate()} {...props} {...permisos} />
}