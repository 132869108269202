import React, { Component } from 'react';
import { Row, Col, Card, Button, Spin, Tag, Layout, Space, List, Typography, message, Popconfirm } from 'antd'

import axios from 'axios';

import { IconPlusGroup, IconDelete } from '../../../Widgets/Iconos';
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";

import './Sections.scss'
import Avatar from "../../../Widgets/Avatar/Avatar";
import Logged from '../../../../Hooks/Logged'
import usePermissions from '../../../../Hooks/usePermissions';
import ModalBitacoraInventario from '../ModalBitacoraInventario';
import moment from 'moment';
import ModalBitacoraInventarioDetalle from '../../Almacenes/ModalesSecciones/ModalBitacoraInventarioDetalle';

const { Content } = Layout;
const { Text } = Typography;


export class BitacoraInventario extends Component {

    constructor(props) {
        super(props)
        this.state = {

            bitacora_id: null,
            bitacoras: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

            },

        }
    }

    renderUrl = (item) => {
        const urlParams = new URL(axios.defaults.baseURL + "/proyectos/logo/" + item._id)
        urlParams.searchParams.set("Authorization", sessionStorage.getItem("token"))
        urlParams.searchParams.set("name", item.logo?.name)

        

        return urlParams.href
    }

    

    componentDidMount() {


        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getBitacora();
    }

    /**
     *
     * @memberof BitacoraInventario
     * @method getBitacora
     * @description Obtiene las bitacoras de inventario realcionadas con un producto
     */
    getBitacora = async ({
        page = this.state.bitacoras.page,
        limit = this.state.bitacoras.limit,
        search = this.props.search
    } = this.state.bitacoras) => {

        this.setState({ loading: true, bitacoras: { ...this.state.bitacoras, page, limit } })

        axios.get('/almacenes-bitacoras/list', {
            params: { limit, page, search, producto_id: this.props.producto_id }
        })
            .then(({ data }) => {
                console.log(data)
                this.setState({ bitacoras: data })
            })
            .catch(res => {
                message.error(res.response.data.message)
            })
            .finally(() => this.setState({ loading: false }))
    }

    render() {
        return (
            <>
                <Spin spinning={false}>
                    <Content className="admin-content content-bg">
                        <List
                            loading={false}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Bitacoras " }}
                            dataSource={this.state.bitacoras.data}
                            pagination={{
                                current: this.state.bitacoras.page,
                                pageSize: this.state.bitacoras.limit,
                                total: this.state.bitacoras.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                onChange: (page, limit) => this.getBitacora({ page, limit })

                            }}

                            header={
                                <Row className="width-100" gutter={[16,16]}>
                                    <Col span={4} className="center">
                                        <Text strong>PROYECTO</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text strong>INMUEBLE</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text strong>USUARIO</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text strong>ALMACEN</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text strong>VOLUMEN</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text strong>VOLUMEN ANTERIOR</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text strong>FECHA</Text>
                                    </Col>
                                    
                                    <Col span={2} className="center">
                                        <Text strong>ACCIONES</Text>
                                    </Col>
                                </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100" gutter={[16, 16]}>
                                            <div className={`badge-card tipo-${item.tipo}`}>
                                            </div>
                                            
                                            <Col span={4} className="center">
                                            <Avatar
                                                    color={item.proyecto_id?.color}
                                                    {...(item.proyecto_id.logo ? {
                                                        image: this.renderUrl(item.proyecto_id)
                                                    } : {
                                                        name: item.proyecto_id.nombre
                                                    })}
                                                    
                                                    
                                                // image={}
                                                />
                                                <Text className='ml-1'>{item?.proyecto_id?.nombre}</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Tag className='tag-inmueble'>{item?.inmueble_id?.nombre}</Tag>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Text>{item?.contratista_id?.nombre}</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Text>{item?.almacen_id?.nombre}</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Text>{item?.volumen} {item.producto_id.unidad.simbolo ? item.producto_id.unidad.simbolo : item.producto_id.unidad.nombre}{item?.volumen > 1 ? "s": null}</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Text>{item?.volumen_anterior} {item.producto_id.unidad.simbolo ? item.producto_id.unidad.simbolo : item.producto_id.unidad.nombre}{item?.volumen > 1 ? "s": null}</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Text>{moment(item.fecha).format('YYYY-MM-DD')}</Text>
                                            </Col>
                                            <Col span={2} align="right">
                                                <Space size="small" direction="horizontal">
                                                    <Button
                                                        type="primary"
                                                        disabled={false}
                                                        icon={<IconPlusGroup />}
                                                        title="Editar"
                                                        onClick={() => this.setState({ bitacora_id: item._id, modal_detalle_visible: true })}
                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        disabled={false}
                                                        title="¿Deseas eliminar esta bitacora?"
                                                        onConfirm={() => axios.delete('/almacenes-bitacoras/' + item._id,
                                                            {}).then(() => {
                                                                this.getBitacora()
                                                                message.success('Bitacora eliminada')
                                                            }).catch(error => {
                                                                message.error('Error al eliminar la bitacora')
                                                                console.log(error)
                                                            })}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            disabled={false}
                                                            type="primary"
                                                            icon={<IconDelete />}
                                                            title="Eliminar"
                                                            danger
                                                        />
                                                    </Popconfirm>

                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>

                    {this.props.createBitacora ? <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} /> : null}

                    <ModalBitacoraInventario
                        visible={this.state.modal_visible}
                        onClose={() => { this.setState({ modal_visible: false, producto_almacen: undefined }); this.getBitacora()}}
                        producto_id={this.props.producto_id}
                    />
                    <ModalBitacoraInventarioDetalle
                        visible={this.state.modal_detalle_visible}
                        onClose={() => { this.setState({ modal_detalle_visible: false, bitacora_id: undefined })}}
                        bitacora_id={this.state.bitacora_id}
                    />

                </Spin>
            </>
        )
    }
}


export default function (props) {

    let user = React.useContext(Logged)


    let permissions = usePermissions(user?.rol_id?.permisos, {
        
        createBitacora: ["almacenes-bitacoras", "create"],
        

    })


    return <BitacoraInventario {...props} />

}