import React, { Component } from "react";
import { Modal, Form, Input, message, Spin, InputNumber, Typography, Row, Col, Button } from 'antd';
import { SelectProducto, SelectProyecto, SelectInmueble, SelectAlmacen } from "../../Widgets/Inputs/Selects";
import PrioridadPicker from "../../Widgets/Inputs/PrioridadPicker";
import { PlusOutlined } from "@ant-design/icons";
import AlmacenIngresoInputs from "../../Widgets/Inputs/AlmacenIngresoInputs";

const axios = require('axios').default;
const {Text} = Typography;
/**
 *
 *
 * @class ModalEntrega
 * @extends {Component}
 */
class ModalEntrega extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            requisicion: {},
            total: 0,
            max: 0,
            almacenes: {}
        }
    }

    ModalEntrega = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if(this.props.requisicion_id){
            this.getRequisicion()
        }
    }

    onFinish = (values) => {

        if(!values.almacenes) {
            message.error("No hay movimientos registrados")
            return
        }else if(Array.isArray(values.almacenes) && !values.almacenes[0]){
            message.error("No hay movimientos registrados")
            return
        }

        this.entregaMaterieles(values)
    }


    /**
     *
     * @methodOf ModalEntrega
     * @function getRequisicion
     * @description Añade una nueva requisicion
     */
    getRequisicion = () => {
        this.setState({loading: true})
        axios.get('/requisiciones/'+this.props.requisicion_id,{
        }).then(response => {
                console.log("response.data", response.data);
                const requisicion = response.data
            this.setState({
                requisicion,
                max: requisicion.volumen - (requisicion.volumen_entregado || 0)
            })
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al obtener la requisición")
        }).finally(()=>{
            this.setState({loading: false})
        })
    }


    /**
     *
     * @methodOf ModalEntrega
     * @function entregaMaterieles
     * @description Entrega o Recolecta materiales de la requisicion
     */
    entregaMaterieles = (values) => {
        this.setState({loading: true})
        axios.post('/requisicion-opcion/entraga',{
            ...values,
            total: this.state.total,
            requisicion_id: this.props.requisicion_id
        }).then(response => {
            message.success(`Material ${this.props.estatus === 5 || this.props.estatus === 8 ? "Recolectado" : "Entregado"}`)
            this.props.onClose(true)
        }).catch(error => {
            console.log("error", error);
            
            message.error(error?.response?.data?.message ?? "Error al registrar el material")
        }).finally(()=>{
            this.setState({loading: false})
        })
    }


    render() {

        let { requisicion } = this.state
        let { estatus } = this.props

        return (
            <Form
                layout="vertical"
                name="form-requisiciones"
                ref={this.ModalEntrega}
                onFinish={this.onFinish}
                onValuesChange={(_, {almacenes}) => {
                    let total = 0
                    let ids = {}
                    almacenes.forEach((almacen) => {
                       if(almacen?.cantidad) total += almacen.cantidad
                       if(almacen?.almacen_id) ids[almacen.almacen_id] = (ids[almacen.almacen_id] || 0) + 1
                    })

                    this.setState({total, almacenes: ids})
                }}
                className="pd-1"
                initialValues={{
                    cantidad: 1,
                }}
            >
                <Spin spinning={this.state.loading}>
                    
                    <Row>
                        <Col span={12} className="mb-1">
                            <Text>Volumen Solicitado</Text>
                        </Col>
                        <Col span={12} className="flex-right mb-1">
                            <Text>{requisicion.volumen} {requisicion?.producto_id?.unidad?.nombre ?? "Unidades"}</Text>
                        </Col>
                        <Col span={12} className="mb-1">
                            <Text>Volumen {estatus === 4 ? "Entregado" : "Recolectado"}</Text>
                        </Col>
                        <Col span={12} className="flex-right mb-1">
                            <Text>{requisicion.volumen_entregado} {requisicion?.producto_id?.unidad?.nombre ?? "Unidades"}</Text>
                        </Col>
                    </Row>

                    
                        {/* <InputNumber 
                            className="width-100"
                            max={((requisicion.volumen * 100) - (requisicion.volumen_entregado*100))/100}
                            precision={2}
                            min={0.01}
                        /> */}
                  
                    <Form.List name="almacenes">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <AlmacenIngresoInputs 
                                        key={key} 
                                        name={name} 
                                        almacenes={this.state.almacenes} 
                                        max={this.state.max} 
                                        total={this.state.total}
                                        proyecto_id={this.props.proyecto_id}
                                        restField={{ ...restField }} 
                                        add={add} 
                                        remove={remove} 
                                    />
                                ))}
                                <Form.Item>
                                    <Button type="dashed" disabled={this.state.max <= this.state.total} onClick={() => add()} block icon={<PlusOutlined />}>
                                        Agregar Campo
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, estatus } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={estatus === 5 || estatus === 7 ? "Recolección de Materiales" : "Entrega de Materiales" }
        closable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Aceptar"
        okButtonProps={{ form: 'form-requisiciones', key: 'submit', htmlType: 'submit' }}
    >
        <ModalEntrega {...props} />
    </Modal>

}