import React, { useState, useEffect } from 'react';
import { Input, Form, Space, Select, message, InputNumber } from 'antd'

import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'
const { Option } = Select
const axios = require('axios')

/**
 * @const Budgetting
 * @description Input de Texto que solo acepta numeros telfonicos y los formatea (999) 999 - 9999
 */
const Budgetting = (props) => {

    const { value, onChange, key, name, restField, remove } = props
    

    const [inputValue, setInputValue] = useState(value)

    const [proyectos, setProyectos] = useState([])
    const [conceptos, setConceptos] = useState([])
    const [subconceptos, setSubonceptos] = useState([])



    const getConceptos = ({search, proyecto_id }) => {
        axios.get('/conceptos', { 
            params: { 
                search, 
                proyecto_id: proyecto_id.value
            } 
        })
            .then(({ data }) => {
                
                setConceptos(data.data)
                // if(data.data.length > 0)
                //     getSubConceptos({concepto_id: data.data[0]._id})
            })
            .catch(error => {
                message('Error al cargar los conceptos')
            })
            
    }

    const getSubConceptos = ({search, concepto_id}) => {
        axios.get('/subconceptos', { 
            params: { 
                search, 
                concepto_id: concepto_id.value
            } 
        })
        .then(({ data }) => {
            console.log("data subconceptos", data.data);
            setSubonceptos(data.data)
        })
        .catch(error => {
            message('Error al cargar los Subconceptos')
        })
            
    }

     /**
     *
     * @memberof Budgetting
     * @method getProyectos
     * @description Obtiene la informacion de los proyectos
     */
    const getProyectos = (search) => {
        axios.get("/proyectos", { 
            params: { 
                search 
            } 
        }).then(({ data }) => {
            setProyectos(data.data)
            
        })
        .catch((error) => {
            message.error("Error al obtener los proyectos")
        })
    }

    //componentDidMount
    useEffect(() => {
        getProyectos()
    }, [])

    return (
        <Space
            key={key}
            style={{
                display: 'flex',
                marginBottom: 8,
            }}
            align="baseline"
        >
            <Form.Item
                {...restField}
                name={[name, 'proyecto_id']}
                rules={[
                    {
                        required: true,
                        message: 'Seleccione el proyecto',
                    },
                ]}
            >
                <Select 
                    style={{ width: 140 }}
                    onSelect={(proyecto_id) => getConceptos({proyecto_id})}
                    placeholder="Proyecto"
                    labelInValue
                >
                    {proyectos.map(proyecto => (
                        <Option value={proyecto._id} key={proyecto._id}>{proyecto.nombre}</Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                {...restField}
                name={[name, 'concepto_id']}
                rules={[
                    {
                        required: true,
                        message: 'Seleccione el concepto',
                    },
                ]}
            >
                <Select 
                    style={{ width: 140, }} 
                    onSelect={(concepto_id) => getSubConceptos({concepto_id})}
                    labelInValue
                    placeholder="Concepto"
                >
                    {conceptos.map((item) => (
                        <Option key={item._id} value={item?._id}>
                            {item.nombre}
                        </Option>
                    ))}
                </Select>
            </Form.Item>


            <Form.Item
                {...restField}
                name={[name, 'subconcepto_id']}
                rules={[
                    {
                        required: true,
                        message: 'Seleccione el sub concepto',
                    },
                ]}
            >
                <Select 
                    style={{ width: 140, }}   
                    labelInValue
                    placeholder="Subconcepto"
                >
                    {subconceptos.map((item) => (
                        <Option key={item._id} value={item?._id}>
                            {item.nombre}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                {...restField}
                name={[name, 'monto']}
                rules={[
                    {
                        required: true,
                        message: 'Ingrese el monto',
                    },
                ]}
            >
                <InputNumber min={1} defaultValue={0}  placeholder="Monto"/>
            </Form.Item>
            <MinusCircleOutlined onClick={() => remove(name)} />
        </Space>
    );
}



export default Budgetting;