import React, { Component } from "react";
import { Row, Col, Button, Modal, Form, Input, message, Upload, Spin, InputNumber } from 'antd';
import { PlusOutlined,  DeleteOutlined, UploadOutlined } from '@ant-design/icons';

//componentes
import { Uploader } from "../../Widgets/Uploaders";

const axios = require('axios').default;

class ModalProveedores extends Component {


    constructor(props) {
        super(props);
        this.state = {
            
            loading: false,
            
            
            tipo: 1,
            
        }
    }

    refModalProveedores = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if (this.props.proveedor_id !== undefined) {
            this.getProveedor()
        }
    }

    /**
     * @memberof ModalProveedores
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {

        const formData = new FormData()
        formData.appendMultiple({
            ...values,
            documentos: undefined,
            id: this.props.proveedor_id,
        })

        values.documentos?.forEach(e => {
            if(!e.fromDB)
                formData.append("documentos", e, e.name)
        })


        if (this.props.proveedor_id) {
            this.updateProveedor(formData)
        } else {
            this.addProveedor(formData)
        }

    }

    /**
     * @memberof ModalProveedores
     * @method addProveedor
     * @description Añade un proveedor a la BD
     *
     */
    addProveedor = (values) => {
        this.setState({loading:  true})
        axios.post('/proveedores', values)
        .then(({data}) => {
            message.success('Proveedor creado')
            this.props.onClose(data)
        }).catch(error => {
            message.error(error?.response?.data?.message || error?.response?.data || 'Error al crear al proveedor')
            console.log(error)
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof ModalProveedores
     * @method getProveedor
     * @description Obtiene un proveedor de la DB
     *
     */
    getProveedor = () => {
        this.setState({ loading: true })
        axios.get('/proveedores/'+ this.props.proveedor_id).then(response => {
            let proveedor = response.data;
            console.log(response)

            this.refModalProveedores.current.setFieldsValue({
                alias: proveedor.alias,
                razon_social: proveedor.razon_social,
                plazo: proveedor.plazo,
                rfc: proveedor.rfc,
                linea_credito: proveedor.linea_credito,
                documentos: proveedor.documentos.map((file, index) => ({
                    uid: file.file,
                    name: file.name,
                    status: 'done',
                    fromDB: true,
                    url: axios.defaults.baseURL + "/proveedores/" + this.props.proveedor_id + "?documento=" + file.file +"&Authorization=" + sessionStorage.getItem("token")
                }))
            })

        }).catch(error => {
            message.error('Error al traer la cuenta')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalProveedores
     * @method updateProveedor
     * @description Actualiza la información de un proveedor
     * 
     */
    updateProveedor = (values) => {
        this.setState({ loading: true })
        axios.put('/proveedores', values)
        .then(response => {
            message.success('Proveedor Actualizado')
            this.props.onClose(true)
        }).catch(error => {
            message.error('Error al actualizar al proveedor')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }



    /**
     *
     * @memberof ModalProveedores
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con exito");
                this.setState({ loadingImage: false, image: null })
            })
            .catch(res => {
                console.log("imagen no se puedo remover");
            })
    };

    /**
     *
     * @memberof ModalProveedores
     * @method checkAlias
     * @description Verifica que el alias este disponible.
     */
     checkAlias(rule, value, callback) {
        if (value) {
            let checkAlias = value
            axios.get('/proveedores', {
                params: { limit:10, page:1, checkAlias }
            })
                .then(({data}) => {
                    console.log("Alias", data)
                    if (checkAlias === data.data[0]?.alias) callback()
                    if (data.total) {
                        callback("El alias ya está en uso")
                    } else {
                        callback()
                    }
                })
                .catch(res => {
                    
                })
        } else {
            callback()
        }

            
    }

    /**
     *
     * @memberof ModalProveedores
     * @method checkAlias
     * @description Verifica que el RFC este disponible.
     */
     checkRFC(rule, value, callback) {
        if (value) {
            let checkRFC = value
            axios.get('/proveedores', {
                params: { limit:10, page:1, checkRFC }
            })
                .then(({data}) => {
                    if(checkRFC === data.data[0]?.rfc) callback()
                    if (data.total) {
                        callback("El RFC ya está en uso")
                    } else {
                        callback()
                    }
                })
                .catch(res => {
                    
                })
        } else {
            callback()
        }

            
    }

    /**
     *
     * @memberof ModalProveedores
     * @method checkRazon
     * @description Verifica que la Razón social este disponible.
     */
     checkRazon(rule, value, callback) {
        if (value) {
            let checkRazon = value
            axios.get('/proveedores', {
                params: { limit:10, page:1, checkRazon }
            })
                .then(({data}) => {
                    if(checkRazon === data.data[0]?.razon_social) callback()
                    if (data.total) {
                        callback("La razón social ya está en uso")
                    } else {
                        callback()
                    }
                })
                .catch(res => {
                    
                })
        } else {
            callback()
        }

            
    }


    render() {

        


        return (
            <Form
                layout="vertical"
                name="form-cuenta"
                id="form-cuenta"
                ref={this.refModalProveedores}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>
                    
                    <Row justify="center">
                        <Col xs={24} lg={22}>
                            <Form.Item
                                label="Alias"
                                name="alias"
                                
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el alias"
                                },{
                                    validator: this.checkAlias
                                }
                                ]}
                            >
                                <Input placeholder="Alias" ></Input>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={22}>
                            <Form.Item
                                label="Razón Social"
                                name="razon_social"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la Razón Social"
                                },{
                                    validator: this.checkRazon
                                }]}
                            >
                                <Input placeholder="Nombre" ></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={22} >
                            <Form.Item
                                label="RFC"
                                name="rfc"
                                rules={[
                                    { pattern: /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/, message: "El RFC es inválido" },
                                    {required: true,
                                    message: "Por favor, ingrese el RFC"},
                                    {validator: this.checkRFC}
                                ]}
                            >
                                <Input placeholder="RFC" ></Input>
                            </Form.Item>
                        </Col>

                        
                       
                         
                        <Col xs={24} lg={22}>
                            <Form.Item
                                label="Linea de crédito"
                                name="linea_credito"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la cantidad de crédito"
                                }]}
                            >

                                <InputNumber className="width-100" placeholder="1,000"></InputNumber>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={22}>
                            <Form.Item
                                label="Plazo a pagar el credito (Días)"
                                name="plazo"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el plazo para pagar el crédito"
                                }]}
                            >

                                <InputNumber className="width-100" placeholder="365"></InputNumber>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={22}>
                            <Form.Item
                                label="Documentos"
                                name="documentos"
                                valuePropName="fileList"
                                getValueFromEvent={e => {
                                    if (Array.isArray(e)) {
                                        return e;
                                    }
                                    return e?.fileList;
                                }}>
                                <Uploader
                                    {...this.props.proveedor_id ? {
                                        onRemove: e => {
                                            axios.put('/proveedores/', {
                                                id: this.props.proveedor_id,
                                                documento: e.uid,
                                            })
                                        }
                                    }:{}}>
                                    <Button icon={<UploadOutlined />} block size="small">Subir Documentos </Button>
                                </Uploader>
                            </Form.Item>
                        </Col>
                        
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function Modals(props) {
    const { visible = false, onClose = () => { }, proveedor_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={proveedor_id ? "Editar Proveedor" : "Crear Proveedor"}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-cuenta', key: 'submit', htmlType: 'submit' }}
    >
        <ModalProveedores {...props} />
    </Modal>

}