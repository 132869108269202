import { CloseOutlined, WarningOutlined, PlusOutlined, MinusOutlined, SwapOutlined, StopOutlined, EditOutlined, DeleteOutlined, } from "@ant-design/icons"
import { Tag, Typography } from 'antd'

const { Text } = Typography;

const tipo = { "0": 'Sin Cambios', "1": ' Ingreso', "-1": 'Egreso' }

const estatus_facturas = ['Factura Cancelada', 'Pendiente', 'Liquidad']

const colors = ['#4D4D4D', '#999999', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E']


/** 
 * @param {Number} value Tipo de factura 
*/
const tipos_facturas = (value) => {
    switch (value) {
        case 0:
            return "Sin cambios"
        case 1:
            return "Ingreso"
        case 2:
            return "Egreso"
    }
}


/** 
 * @param {Number} tipo tipo de transaccion
*/
const color_transaccion_tipo = (tipo) => {
    switch (tipo) {
        case 1:
            return "#28A745"
        case 2:
            return "#DC3545"
        case 3:
            return "#007BFF"
        case 4:
            return "#17A2B8"
        default:
            return "#7A7A7A"
    }
}
/**
    *
    *
    * @param {*} tipo
    * @memberof Transacciones
    * @description Renderiza el icono según el estatus
    */
const renderIconTransaccion = (tipo) => {
    switch (tipo) {
        case 1:
            return <PlusOutlined style={{ color: "currentColor" }} />
        case 2:
            return <MinusOutlined style={{ color: "currentColor" }} />
        case 3:
            return <SwapOutlined style={{ color: "currentColor" }} />
        case 4:
            return <StopOutlined style={{ color: "currentColor" }} />
    }
}
/** 
 * @param {Number} tipo tipo de transaccion
*/
const tipo_servicio = (tipo) => {
    switch (tipo) {
        case 0:
            return "Periódico"
        case 1:
            return "Desarrollo"
        case 2:
            return "Único"
    }
}

/** 
 * @param {Number} value Tipo de factura 
*/
const color_tipos_facturas = (value) => {
    switch (value) {
        case 0:
            return "#007BFF"
        case 1:
            return "#DC3545"
        case 2:
            return "#DC3545"
    }
}

/** 
 * @param {Number} value estatus de la requisicion 
*/
const renderEstatusRequisicion = (estatus) => {
    let text = {
        0: "Cancelada",
        1: "Requerido",
        2: "Cotizado",
        3: "Pausado",
        4: "Entrega",
        5: "Recolección",
        6: "Entregado"
    }

    return <Tag className={`tag sm tag-estatus-${estatus}`}>{text[estatus]}</Tag>
}

/**
 * @param {*} tipo
 * @description Renderiza el monto de transacciones con estilos dependiendo del tipo
 */
const renderMontoTransaccion = ({monto = 0, tipo = 4}) => {

    let style = {
        color: color_transaccion_tipo(tipo),
        fontWeight: "bold"
    }

    return <Text style={{...style}}>{renderIconTransaccion(tipo)} $ {monto.toMoney(true)} MXN</Text>

}

/**
 * @param {*} tipo
 * @description Renderiza el monto de facturas con estilos dependiendo del tipo
 */
// const renderMontoFactura = ({monto = 0, tipo = 0}) => {

//     let style = {
//         color: color_transaccion_tipo(tipo),
//         fontWeight: "bold"
//     }

//     return <Text style={{...style}}> {renderIconFactura(tipo)} {monto.toMoney(true)} MXN</Text>

// }

const randomColor = () => {
    const getHex = () => {
        const num = Math.floor(Math.random() * 256)
        return num.toString(16)
    }

    return '#' + getHex() + getHex() + getHex()
}

export {
    tipo,
    estatus_facturas,
    colors,
    tipos_facturas,
    color_transaccion_tipo,
    tipo_servicio,
    renderIconTransaccion,
    color_tipos_facturas,
    renderEstatusRequisicion,
    renderMontoTransaccion,
    randomColor
}