import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, List, message, Tag, Spin, Divider, Space, Tooltip } from 'antd';
import { CopyOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
//componentes
import ChatRequisiciones from './ChatRequisiciones'
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import { IconFlag, IconPDFColor } from '../../Widgets/Iconos';



const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment')


let unidades = {
    'H87': 'Pieza',
    'EA': 'Elemento',
    'KGM': 'Kilogramo',
    'MTR': 'Metro',
    'KT': 'Kit',
    'SET': 'Conjunto',
    'LTR': 'Litro',
    'XBX': 'Caja',
    'xun': 'Unidad',
    'GRM': 'Gramo',
    'BUT': 'Bulto',
    'TON': 'Tonelada',
}

let estatus = {
    1: 'Requerido',
    2: 'Cotizado',
    3: 'Pausado',
    0: 'Cancelado',
    4: 'Entrega',
    5: 'Recoleccion',
    6: 'Entregado',
}

let colors_flag = {
    0: '#5151ec',
    1: '#ecea51',
    2: '#ec5151',
}


/**
 * @class ModalRequisiciones
 * @extends {Component}
 * 
 *  @description Corresponde
 */
class ModalRequisiciones extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            requisicion: {
                proyecto_id: {},
                propiedades: [],
            },
        }
    }

    /**
     *
     * @methodOf ModalRequisiciones
     * @function componentDidMount
     * @description obtiene la informacion inicial
     */
    componentDidMount(){
        this.getRequisicion()
    }

    /**
     *
     * @methodOf ModalRequisiciones
     * @function getRequisicion
     * @description Obtiene la informacion de una requisicion
     */
    getRequisicion = () => {
        this.setState({loading:  true})
        axios.get('/requisiciones/'+this.props.requisicion_id,{
            
        }).then(response => {
            console.log("response.data", response.data);
            this.setState({requisicion: response.data})

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener los datos')
        }).finally(()=>this.setState({loading: false}))

    }


    /**
     *
     * @methodOf ModalRequisiciones
     * @function getDates
     * @description Obtiene la informacion de una requisicion
     */
    getDates = (fecha_entrega) => {
        if(!fecha_entrega){
            return  <Text style={{color: '#FC0000'}}></Text>
        }else{
            let now = moment()
            let date = moment(fecha_entrega).add(5,'days')

            let diff = date.diff(now, 'days')

            if(diff < 0){
                return  <Text style={{color: '#FC0000'}}>Retrasado</Text>
            }else{
                return  <Text style={{color: '#287a11'}}>A tiempo</Text>
            }
        }
    }

    /**
     *
     *
     * @memberof FormOrdenes
     * 
     * @method getPDF
     * @description Generamos el PDF de la contizacion
     */
    getPDF = (requisicion_id) => {
        let urlPDF = new URL(axios.defaults.baseURL + '/requisiciones/pdf');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('requisicion_id', requisicion_id)
        return urlPDF.href
    }
   


    render() {

        const { requisicion } = this.state;

        return [

            <div>
                <Row>
                    <Col xs={24} md={12} className="col-text">
                        <Spin spinning={this.state.loading}>
                            <Row gutter={[0,14]}>
                                <Col span={12} className="flex-left">
                                    <Space>
                                        <Text className="text-lg">{requisicion.folio}</Text>
                                        <Tooltip title="Copiar link">
                                            <Button
                                                type="link"
                                                icon={<CopyOutlined />}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        `${process.env.REACT_APP_PUBLIC_URL}/admin/requisiciones/${requisicion?._id}`
                                                    ).then(() => message.success("Link copiado")).catch((error) => message.error("Error al copiar link"))
                                                }}
                                            />
                                        </Tooltip>
                                    </Space>
                                </Col>
                                <Col span={12} className="flex-right">
                                    <IconFlag fill={colors_flag[requisicion.prioridad] ? colors_flag[requisicion.prioridad] : '#A4A4A4'}/>
                                    <Tag className={`tag tag-estatus-${requisicion.estatus}`} style={{marginLeft: '14px', marginRight: 0}}>{estatus[requisicion.estatus]}</Tag>
                                </Col>
                                <Col span={12} className="flex-left">
                                    <Text strong className="text-lg">{requisicion?.producto_id?.nombre}</Text>
                                </Col>
                                <Col span={12} className="flex-right">
                                     <CustomAvatar 
                                        size="large" 
                                        style={{marginRight: '4px'}}
                                        url={{
                                            url:`${axios.defaults.baseURL}/proyectos/logo/${requisicion?.proyecto_id?._id}`,
                                            name: requisicion?.proyecto_id?.logo?.name
                                        }}
                                        name={requisicion.proyecto_id.nombre}
                                        color={requisicion.proyecto_id.color}/>
                                    <Text strong className="text">{requisicion.proyecto_id.nombre}</Text>
                                </Col>
                            </Row>
                            <Title className="modal-title" level={5}>Información General</Title>
                            <Divider/>
                            <Form
                                layout="vertical"
                            >
                                <Row justify="center">
                                    <Col span={12}>
                                        <Form.Item
                                            label="SKU"
                                        >
                                            <Text className="text">{requisicion?.producto_id?.sku}</Text>
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item
                                            label="Fabricante"
                                        >
                                            <Text className="text">{requisicion?.producto_id?.fabricante ? requisicion?.producto_id?.fabricante : "N/A"}</Text>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Categoría"
                                        >
                                            <Text className="text">{requisicion?.producto_id?.categoria_id?.nombre}</Text>
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item
                                            label="Cantidad / Volumen"
                                        >   
                                            <Text className="text">{requisicion.volumen} {requisicion?.producto_id?.unidad?.nombre}</Text>
                                        </Form.Item>
                                    </Col>
                                    
                                </Row>
                                <Title className="modal-title" level={5}>Información de la Entrega</Title>
                                <Divider/>
                                <Row justify="center">
                                    <Col span={12}>
                                        <Form.Item
                                            label="Tipo de Entrega"
                                        >
                                            <Tag 
                                                className="tag" 
                                                style={{color: 'white', background: '#54d9df'}}
                                            >{requisicion.requisicion_opcion_id?.tipo_entrega === 0 ? 'Directa' : 'Envío'}</Tag>
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item
                                            label="Proveedor"
                                        >
                                            <Text className="text">{requisicion.requisicion_opcion_id?.proveedor_id?.alias}</Text>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Lugar"
                                        >   
                                            <div className="flex-left-column">
                                                <Text className="text">{requisicion.requisicion_opcion_id?.almacen_id?.nombre}</Text>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item
                                            label="Fecha y Hora"
                                        >   
                                            <Text className="text">
                                                {moment(requisicion.fecha_entrega).format("DD/MM/YYYY HH:mm")}
                                            </Text>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Orden de Compra / Evidencias"
                                        >
                                            <div className="flex-left-column">
                                                {requisicion.requisicion_opcion_id?.archivos?.length === 0 ? <Text className="text-gray">Sin Archivos</Text> : null}
                                                {requisicion.requisicion_opcion_id?.archivos?.map(arch => <a target="_blank" href={`${axios.defaults.baseURL}/upload/${arch.filename}`}>{arch.name}
                                                </a>)}
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        {requisicion.estatus >= 4 ? <Form.Item label="Orden de Compra">
                                            <a href={this.getPDF(requisicion._id)} download target="_blank">
                                                <IconPDFColor/>
                                            </a>
                                        </Form.Item> : null}
                                    </Col>
                                </Row>
                            </Form>
                        </Spin>
                    </Col>
                    <Col xs={24} md={12} className="m-0">
                        <div className="chat-side"  style={{ minHeight: "100%" }}>
                            <Title level={4} className="text-center title-chat">Actualizaciones</Title>
                            {this.props.requisicion_id ?  <ChatRequisiciones requisicion_id={this.props.requisicion_id}/> : null}
                        </div>
                    </Col>
                </Row>
            </div>
        ]
    }
}



export default function (props) {

    const {visible, onClose} = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={<Button 
            onClick={onClose}
            type="primary"
        >
            Cerrar
        </Button>}
        closable={false}
        className="modal-form modal-detalle-chat"
        destroyOnClose={true}
        zIndex={1000}
        width={1200}

    >
        <ModalRequisiciones {...props} />
    </Modal>


}
