import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Typography, Tabs } from 'antd'

import axios from 'axios';
import { Link, useNavigate, useParams } from "react-router-dom";

import { IconArrowBack } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import ModalAlmacenes from './ModalAlmacen'

import Logged from '../../../Hooks/Logged'
import usePermissions from '../../../Hooks/usePermissions';
import CardAlmacen from './Cards/CardAlmacen';

import Productos from './Secciones/Productos'
import BitacoraInventario from './Secciones/BitacoraInventario'
import Requisiciones from './Secciones/Requisiciones';

const { Content } = Layout;
const { Text } = Typography;

/**
 * @export
 * @class Almacenes
 * @extends {Component}
 * @description Vista de Almacenes
 */
export class Almacenes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modal_visible: false,

            almacen: {},
            searching: true,
            filtroSearch: '',
            seccion: 0,
        }
    }

    componentDidMount() {
        this.getAlmacen()

        
    }

    /**
     * @methodOf  Almacenes
     * @method getAlmacen
     *
     * @description Obtiene las almacenes
     * */
    getAlmacen = () => {
        this.setState({ loading: true})
        axios.get('/almacenes/' + this.props.params.almacen_id, {
            
        }).then(({ data }) => {
            console.clear()
            console.log(data)
            this.setState({
                almacen: data,
                
            });

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        })
    }

   

    render() {

        let items = [
            { 
                label: 'Productos', 
                key: 0, 
                children: this.props.accessInventario ? <Productos almacen_id={this.props.params.almacen_id} getAlmacen={this.getAlmacen}/> : "No tiene acceso a este modulo" 
            },{ 
                label: 'Requisiciones', 
                key: 1, 
                children: this.props.accessRequisiciones? <Requisiciones almacen_id={this.props.params.almacen_id}/> : "No tiene acceso a este modulo"
            },{ 
                label: 'Bitácora de Inventario', 
                key: 2, 
                children: this.props.accessBitacoras ? <BitacoraInventario almacen_id={this.props.params.almacen_id} getAlmacen={this.getAlmacen}/> : "No tiene acceso a este modulo" 
            },
            
          ];

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={"Almacén / "+ this.state.almacen?.nombre +" / " + items[this.state.seccion]?.label}
                        backIcon={<IconArrowBack/>}
                        onBack={(e) => this.props.navigate(-1)}
                    />
                    
                    <Content className="admin-content content-bg pd-1">
                        <Row gutter={[16, 16]} className="w-100">
                            <Col span={24}>
                                <CardAlmacen almacen={this.state.almacen} className='w-100'/>
                            </Col>
                            
                        </Row>
                        <Row>
                            <Tabs 
                                items={items} 
                                destroyInactiveTabPane 
                                className="width-100" 
                                onChange={(e) => this.setState({seccion: e})}
                            />
                        </Row>
                    </Content>

                </Spin>
            </>
        )
    }
}

export default function (props) {

    let user = React.useContext(Logged)

    const params = useParams()

    let permissions = usePermissions(user?.rol_id?.permisos, {
        
        accessInventario: ["almacenes","productos", "access"],
        accessRequisiciones: ["almacenes","requisiciones", "access"],
        accessBitacoras: ["almacenes","bitacoras", "access"],

    })

    

    return <Almacenes {...props} params={params} {...permissions} navigate={useNavigate()} />

}