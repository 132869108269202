import React, { Component } from 'react';
import { Card, Layout, Modal, Form, Input, message, Spin, Row, Col, Typography, Tag, Space, Tooltip, InputNumber } from 'antd'
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";



import './Cards.scss'

const { Content } = Layout;
const { Title, Text } = Typography;

export class CardPrecio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            producto: {}
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        console.log(this.props.params)

        this.getProducto();

    }

    getProducto = () => {
        axios.get(`/productos/${this.props.producto_id}`)
            .then(res => {
                console.log("Producto",res.data)
                this.setState({
                    producto: res.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }


    render() {
        return (
            <>
                <Card className={`card-shadow card-producto ${this.props.className}`}>
                    <Row align='middle' className='mb-2'>
                        <Col span={24}>
                            <Text className='card-title'>Precio</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Row>
                                <Col span={24}>
                                    <Text className='sub-title'>Precio Más Bajo</Text>
                                </Col>
                                <Col span={24}>
                                    <Text className='precios'>{`$ ${this.state?.producto?.mejor_historico?.precio ? this.state?.producto?.mejor_historico?.precio.toMoney(true) : 0} MXN`}</Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row>
                                <Col span={24}>
                                    <Text className='sub-title'>Proveedor</Text>
                                </Col>
                                <Col span={24}>
                                    <Text className='precios'>{this.state?.producto?.mejor_historico?.proveedor_id?.alias}</Text>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                    <Row className="mt-1">
                        <Col span={12}>
                            <Row>
                                <Col span={24}>
                                    <Text className='sub-title'>Precio Más Alto</Text>
                                </Col>
                                <Col span={24}>
                                    <Text className='precios'>{`$ ${this.state?.producto?.peor_historico?.precio ? this.state?.producto?.peor_historico?.precio.toMoney(true) : 0} MXN`}</Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row>
                                <Col span={24}>
                                    <Text className='sub-title'>Proveedor</Text>
                                </Col>
                                <Col span={24}>
                                    <Text className='precios'>{this.state?.producto?.peor_historico?.proveedor_id?.alias}</Text>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                    
                </Card>
            </>
        )
    }
}



export default function (props) {



    return <CardPrecio {...props} />
}