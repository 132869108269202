import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, Select, message, Upload, Spin, InputNumber } from 'antd';
import { PlusOutlined,  DeleteOutlined, LoadingOutlined } from '@ant-design/icons';

//componentes
import { SimpleUploader, Uploader, AvatarLoader } from "../../../Widgets/Uploaders";
import { UploadOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { Option } = Select;
const axios = require('axios').default;


class ModalClientes extends Component {


    constructor(props) {
        super(props);
        this.state = {
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false,
            tipo: 1,
            image: undefined
        }
    }

    refModalClientes = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if (this.props.cliente_id !== undefined) {
            this.getCliente()
        }
    }

    /**
     * @memberof ModalClientes
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {
        console.log("values", values);

        const formData = new FormData()
        formData.appendMultiple({
            ...values,
            logo: undefined,
            id: this.props.cliente_id,
        })

        if(values.logo && values.logo instanceof File){
            formData.append("logo", values.logo, values.logo.name)
        }

        if (this.props.cliente_id) {
            this.updateCliente(formData)
        } else {
            this.addCliente(formData)
        }

    }

    /**
     * @memberof ModalClientes
     * @method addCliente
     * @description Añade a un cliente
     *
     */
    addCliente = (values) => {
        axios.post('/clientes', values)
        .then(response => {
            message.success('Cliente creado')
            this.props.onClose()
        }).catch(error => {
            message.error(error?.response?.data?.message ?? 'Error al crear el cliente')
            console.log(error)
        })
    }

    /**
     * @memberof ModalClientes
     * @method updateCliente
     * @description Actualiza la información de un cliente
     * 
     */
    updateCliente = (values) => {
        this.setState({ loading: true })
        axios.put('/clientes', values)
        .then(response => {
            message.success('Cliente actualizado')
            this.props.onClose()
        }).catch(error => {
            message.error('Error al actualizar la cliente')
            console.log(error.response)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalClientes
     * @method getCliente
     * @description Obtiene un cliente
     *
     */
    getCliente = () => {
        this.setState({ loading: true })
        axios.get(`/clientes/${this.props.cliente_id}`, {

        }).then(response => {
            let cliente = response.data;
            console.log('cliente',cliente)
            this.refModalClientes.current.setFieldsValue({
                ...cliente, 
                logo: cliente.logo ? {
                    uid: cliente.logo.file,
                    name: cliente.logo.name,
                    status: 'done',
                    fromDB: true,
                    url: `${axios.defaults.baseURL}/clientes/${this.props.cliente_id}?logo=${cliente.logo.file}&Authorization=${sessionStorage.getItem("token")}`
                } : null
            })

        }).catch(error => {
            message.error('Error al traer la cliente')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    


    render() {

        const uploadButton = (
            <div>
                {this.state.loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Subir Imagen</div>
            </div>
        );


        return (
            <Form
                layout="vertical"
                name="form-clientes"
                id="form-clientes"
                ref={this.refModalClientes}
                onFinish={this.onFinish}
            >
                <Spin spinning={this.state.loading}>

                    <Row align="center">
                        <Form.Item
                            name="logo"
                            align="center"
                            //valuePropName="fileList"
                        >
                            <AvatarLoader
                                imageCrop={true}
                                onRemove={()=>{
                                    axios.put('/clientes',{
                                        id:this.props.cliente_id,
                                        delete_logo: true
                                    })
                                }}
                            />
                        </Form.Item>
                    </Row>
                    <Form.Item
                        label="Nombre"
                        name="nombre"
                        wrapper={{span: 24}}
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el nombre"
                        }]}
                    >
                        <Input placeholder="Nombre" ></Input>
                    </Form.Item>
                    <Form.Item
                        label="Apellido"
                        name="apellido"
                        wrapper={{span: 24}}
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el apellido"
                        }]}
                    >
                        <Input placeholder="Apellido" ></Input>
                    </Form.Item>
                    <Form.Item
                        label="RFC"
                        name="rfc"
                        wrapper={{span: 24}}
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el RFC"
                        }, { 
                            pattern: /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/, 
                            message: "El RFC es inválido" 
                        }]}
                    >
                        <Input placeholder="VECJ880326XXX" ></Input>
                    </Form.Item>
                    <Form.Item
                        label="Teléfono"
                        name="telefono"
                        wrapper={{span: 24}}
                    >
                        <Input placeholder="XXXXXXXXXX" />
                    </Form.Item>
                    <Form.Item
                        label="Correo Electrónico"
                        name="email"
                        wrapper={{span: 24}}
                        rules={[{ 
                            pattern: /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/g, 
                            message: "El correo es inválido" 
                        }]}
                    >
                        <Input placeholder="example@domain.ext" />
                    </Form.Item>
                    <Form.Item
                        label="Descripción"
                        name="descripcion"
                        wrapper={{span: 24}}
                    >
                        <Input.TextArea placeholder="Descripción" rows={3} />
                    </Form.Item>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, cliente_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={cliente_id ? "Editar Cliente" : "Nuevo Cliente"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText={"Cancelar"}
        okText={"Guardar"}
        okButtonProps={{
            form: 'form-clientes',
            key: 'submit',
            htmlType: 'submit'
        }}
    >
        <ModalClientes {...props} />
    </Modal>

}