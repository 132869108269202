import React, { Component } from 'react';
import { Card, Checkbox, Col, DatePicker, Divider, Dropdown, Input, Layout, Menu, Row, Typography, Empty, List, Button, Statistic, Spin, Radio, PageHeader, message } from 'antd'
import { Link } from 'react-router-dom';
//componentes
import { GraficaClientesPorProyecto, GraficaVentasPorUsuario } from '../../Widgets/graficas'
import { IconArrow, IconSalesGreen, IconUserBlue } from '../../Widgets/Iconos';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import { FaArrowRight } from "react-icons/fa";
import { BsDownload } from "react-icons/bs";
import { SearchOutlined, ArrowUpOutlined, ArrowRightOutlined } from '@ant-design/icons'
//modales
//import ModalVentasPorUsuarios from '../Modales/CRMDashboard/ModalVentasPorUsuario';

//ReactECharts
import ReactECharts from 'echarts-for-react';

//css

import '../../../Styles/Modules/CRM/Dashboard.css';

const echarts = require('echarts')



const axios = require('axios').default
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
const { Paragraph, Title } = Typography;
const { Content } = Layout;
const { RangePicker } = DatePicker;



/**
 * @class CRMDashboard
 * @description Obtiene los clientes del CRMDashboard
 */
export default class CRMDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            ventas: {
                mensuales: [],
                anuales: []
            },
            contadores: {
                total_facturado: 0,
                total_pagado: 0,
                total_clientes: 0,
                total_pendiente: 0,
                total_vendidas: 0,
            }
        }
    }



    /**
     *
     *
     * @memberof CRMDashboard
     * 
     * @method componentDidMount
     * @description Trae la informacion de la vista
     */
    componentDidMount(){
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getDashboard()
    }

    //Referencia
    refModalCRMDashboard = React.createRef();

    /**
     *
     *
     * @memberof CRMDashboard
     * @method getDashboard
     * @description Obtiene los datos para el analisis
     */
    getDashboard = () => {
        axios.get('/crm/dashboard', {
            params: {}
        }).then(({data}) => {
            console.log("crm dashboard",data)
            let {contadores, ventas_mensuales, ventas_anuales, total_clientes} = data.data

            this.setState({
                ventas: {
                    mensuales: ventas_mensuales,
                    anuales: ventas_anuales
                },
                contadores:{
                    total_facturado: contadores?.total_facturado,
                    total_pagado: contadores?.total_pagado,
                    total_clientes: total_clientes,
                    total_pendiente: contadores?.total_pendiente,
                    total_vendidas: contadores?.count,
                }
            })
        }).catch((error) => {
            console.log(error)
            message.error("Error al obtener datos")
        }).finally(() => {
            this.setState({loading: false})
        })
    }

    render() {

        const meses = [
            "Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Ago",
            "Sep",
            "Oct",
            "Nov",
            "Dic"
        ]
        
        return (
            <div>
                {/* Encabezado */}
                <PageHeader
					className="site-page-header custom-page-header"
                    style={{width: 'auto'}}
					title="Client Relationship Manager"
				/>
                
                {/* Cuerpo */}
                <Spin spinning={this.state.loading}>


                    <Row className="card-container crm" style={{ padding:'1em' }}>
                        <Col xs={24}>
                            <Row className="card-container" style={{ width: '100%', marginBottom: 20 }} gutter={[20, 16]} >
                                <Col xs={24} lg={5} style={{ flex: "0 0 20%" }}>
                                    <Card className="crm-card card-total-facturado">
                                        <Statistic
                                            className="crm-stats"
                                            title="Total Facturado"
                                            value={this.state.contadores.total_facturado}
                                            precision={2}
                                            valueStyle={{ color: 'white' }}
                                            prefix="$"
                                            suffix="MXN"
                                        />
                                    </Card>
                                </Col>
                                <Col xs={24} lg={5} style={{ flex: "0 0 20%" }}>
                                    <Card className="crm-card card-total-pagado">
                                        <Statistic
                                            className="crm-stats"
                                            title="Total Pagado"
                                            value={this.state.contadores.total_pagado}
                                            precision={2}
                                            valueStyle={{ color: 'white' }}
                                            prefix="$"
                                            suffix="MXN"
                                        />
                                    </Card>
                                </Col>
                                <Col xs={24} lg={5} style={{ flex: "0 0 20%" }}>
                                    <Card className="crm-card card-pendiente">
                                        <Statistic
                                            className="crm-stats"
                                            title="Total Pendiente"
                                            value={this.state.contadores.total_pendiente}
                                            precision={2}
                                            valueStyle={{ color: 'white' }}
                                            prefix="$"
                                            suffix="MXN"
                                        />
                                    </Card>
                                </Col>
                                <Col xs={24} lg={5} style={{ flex: "0 0 20%" }}>
                                    <Card className="crm-card card-clientes">
                                        <Statistic
                                            className="crm-stats"
                                            title="Número de Clientes"
                                            value={this.state.contadores.total_clientes}
                                            precision={0}
                                            valueStyle={{ color: 'white' }}
                                            
                                        />
                                    </Card>
                                </Col>
                                <Col xs={24}  style={{ flex: "0 0 20%" }}>
                                    <Card className="crm-card card-total-facturado">
                                        <Statistic
                                            className="crm-stats"
                                            title="Prop. Vendidas"
                                            value={this.state.contadores.total_vendidas}
                                            precision={0}
                                            valueStyle={{ color: 'white' }}
                                            
                                        />
                                    </Card>
                                </Col>
                            </Row>
                            <Row style={{ width: '100%' }} gutter={[20, 16]} >
                                <Col xs={24} md={12}>
                                    <Card className="crm-card" title="Ventas por Mes">
                                        <ReactECharts
                                            option = {{
                                                tooltip: {
                                                  trigger: 'axis',
                                                  axisPointer: {
                                                    type: 'shadow'
                                                  }
                                                },
                                                legend: {},
                                                grid: {
                                                  left: '3%',
                                                  right: '4%',
                                                  bottom: '3%',
                                                  containLabel: true
                                                },
                                                xAxis: {
                                                  type: 'value',
                                                  boundaryGap: [0, 0.01]
                                                },
                                                yAxis: {
                                                  type: 'category',
                                                  data: this.state.ventas.mensuales.map(mes => `${meses[mes._id.month - 1]} ${mes._id.year}`)
                                                },
                                                series: [
                                                  {
                                                    type: 'bar',
                                                    data: this.state.ventas.mensuales.map(mes => mes.total)
                                                  }
                                                ]
                                              }}
                                            className={"crm-graph"}
                                        />
                                    </Card>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Card className="crm-card" title="Ventas por Año">
                                        <ReactECharts
                                            option = {{
                                                
                                                tooltip: {
                                                  trigger: 'axis',
                                                  axisPointer: {
                                                    type: 'shadow'
                                                  }
                                                },
                                                legend: {},
                                                grid: {
                                                  left: '3%',
                                                  right: '4%',
                                                  bottom: '3%',
                                                  containLabel: true
                                                },
                                                xAxis: {
                                                  type: 'value',
                                                  boundaryGap: [0, 0.01]
                                                },
                                                yAxis: {
                                                  type: 'category',
                                                  data: this.state.ventas.anuales.map(year => year._id)
                                                },
                                                series: [
                                                  {
                                                    type: 'bar',
                                                    data: this.state.ventas.anuales.map(year => year.total)
                                                  },
                                                ]
                                              }}
                                            className={"crm-graph"}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        </Col >
                    </Row>

                </Spin>
            </div>
        )
    }
}
