import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import moment from 'moment'

import moment_es from 'moment/locale/es'
moment.locale('es', moment_es);

/**
 *
 *
 * @param {boolean} [string=false]
 * @returns Float
 */
function convertToMoney(string = false) {
  try {
    if (this) {
      var r = this.toString().replace(',', '').match(/^-?\d+(?:\.\d{0,2})?/)[0]
      return (string === true) ? parseFloat(r).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : parseFloat(r)
    }
    else
      return 0.00
  } catch (error) {
    return 0.00
  }
}

Number.prototype.toMoney = convertToMoney
String.prototype.toMoney = convertToMoney

FormData.prototype.appendMultiple = function (values) {
  for (const name in values) {
    if (values[name])
      if ("File" in window && values[name] instanceof File)
        this.append(name, values[name], values[name].name);
      else
        this.append(name, values[name]);
  }
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
