import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, Layout, message, Space, List, Typography } from 'antd'

import axios from 'axios';


import { IconDelete, IconEdit } from '../../../Widgets/Iconos';
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
import ModalProductoProveedor from "../ModalProductoProveedor";


import Logged from '../../../../Hooks/Logged'
import usePermissions from '../../../../Hooks/usePermissions';

const { Content } = Layout;
const { Text } = Typography;


export class Proveedores extends Component {

    constructor(props) {
        super(props)
        this.state = {
            proveedores: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,
            },
            producto_id: this.props.producto_id,
        }
    }

    componentDidMount() {


        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getProveedores();
    }

    getProveedores = ({
        page = this.state.proveedores.page,
        limit = this.state.proveedores.limit,
        producto_id = this.props.producto_id,

    } = this.state.proveedores) => {
        this.setState({ loading: true, proveedores: { ...this.state.proveedores, page, limit } })
        axios.get('/productos-proveedores', {
            params: {
                page,
                producto_id
            }
        }).then(({ data }) => {
            console.log(data)
            this.setState({
                proveedores: data,
            });

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        })
    }


    render() {
        return (
            <>
                <Spin spinning={false}>
                    <Content className="admin-content content-bg">
                        <List
                            loading={false}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Proveedores " }}
                            dataSource={this.state.proveedores.data}
                            pagination={{
                                current: this.state.proveedores.page,
                                pageSize: this.state.proveedores.limit,
                                total: this.state.proveedores.total,
                                position: 'bottom',
                                className: "flex-left",
                                onChange: (page) => this.getProveedores({ page })
                            }}

                            header={<Row className="header-list width-100 pr-1 pl-1" gutter={[16, 16]}>

                                
                                <Col span={5} className="center">
                                    <Text strong>PROVEEDOR</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>SKU PROVEEDOR</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>DESCRIPCIÓN</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>COSTO</Text>
                                </Col>
                                
                            </Row>}

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 " gutter={[16, 16]}>

                                            <Col span={5} className="center">
                                                <Text>{item?.proveedor_id.alias}</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Text >{item?.sku_proveedor}</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Text>{item.descripcion ? item.descripcion : "N/A"}</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Text>{item?.costo.toMoney(true)}</Text>
                                            </Col>
                                            <Col span={4} align="right">
                                                <Space size="small" direction="horizontal">
                                                    <Button
                                                        type="primary"
                                                        disabled={false}
                                                        icon={<IconEdit />}
                                                        title="Editar"
                                                        onClick={() => this.setState({ modal_visible_proveedor: true, producto_id: this.props.producto_id, proveedor_id: item._id })}
                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        disabled={false}
                                                        title="¿Deseas eliminar este proveedor?"
                                                        onConfirm={() => axios.delete(`/productos-proveedores/${item._id}`,
                                                            ).then(() => {
                                                                this.getProveedores()
                                                                message.success('Proveedor eliminado')
                                                            }).catch(error => {
                                                                message.error('Error al eliminar el Proveedor')
                                                                console.log(error)
                                                            })}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            disabled={false}
                                                            type="primary"
                                                            icon={<IconDelete />}
                                                            title="Eliminar"
                                                            danger
                                                        />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>
                    <ModalProductoProveedor
                        visible={this.state.modal_visible_proveedor}
                        onClose={() => {
                            this.setState({ modal_visible_proveedor: false, producto_id: undefined, proveedor_id: undefined })
                            this.getProveedores()
                        }}
                        producto_id={this.state.producto_id}
                        proveedor_id={this.state.proveedor_id}
                    />
                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible_proveedor: true, producto_id: this.props.producto_id })} />



                </Spin>
            </>
        )
    }
}


export default function (props) {




    return <Proveedores {...props} />

}