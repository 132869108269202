import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Typography, Collapse, Dropdown, Menu, Upload, Switch, Modal, Tag, Popover, Empty } from 'antd'
import axios from 'axios';
import { FaTruck } from "react-icons/fa";
import InfiniteScroll from "react-infinite-scroll-component";
import { EllipsisOutlined, DeleteOutlined } from '@ant-design/icons';
//componentes
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import FormRequisicionOpcionAceptada from "./FormRequisicionOpcionAceptada";
import { IconCheckMedalColor, IconPlusGroup, IconFlag, IconEye } from '../../../Widgets/Iconos';

//Modal
import ModalEntrega from "../ModalEntrega"
import ModalRequisicionDetalle from "../ModalRequisicionDetalle"

const { Text } = Typography;
const moment = require('moment')

const { Content } = Layout;
const { Panel } = Collapse;


let colors_flag = {
    0: '#5151ec',
    1: '#ecea51',
    2: '#ec5151',
}

/**
 * @export
 * @class ListaEntrega
 * @extends {Component}
 * @description Vista de ListaEntrega
 */
export default class ListaEntrega extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            requisiciones:{
                data: [],
                page: 1,
                limit: 20,
                total: 0,
                called: false
            },
            modal_visible: false,
            requisicion_id: undefined,

        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getRequisiciones()
    }

    componentDidUpdate(prevProps) {
        if(prevProps.search != this.props.search)
            this.getRequisiciones({page: 1})

    }

    /**
     * @methodOf ModalRequisiciones
     * @function getRequisiciones
     * @description Otiene el listado de requeridos
     */
    getRequisiciones = ({
        page = this.state.requisiciones.page,
        limit = this.state.requisiciones.limit,
        search = this.props.search,
    } = this.state.requisiciones) => {

        this.setState({loading: true})
        axios.get("/requisiciones",{
            params:{
                page,
                limit,
                search,
                estatus: this.props.estatus,
                entrega: true
            }
        }).then(({data}) => {
            let requisiciones = page === 1 ? [] : [...this.state.requisiciones.data]

            this.setState({
                requisiciones: {
                    data: requisiciones.concat(data.data),
                    page: data.page,
                    total: data.total,
                    limit: data.limit,
                    called: true
                },
                hasMore: requisiciones.concat(data.data).length < data.total
            })

        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al obtener las requisiciones")

        }).finally(()=>this.setState({loading: false}))

    }
    

    next = () => {
        if(this.state.requisiciones.data.length < this.state.requisiciones.total && this.state.loading === false){
            return this.getRequisiciones({page: this.state.requisiciones.page + 1})
        }
    }


    render() {

        let { label, name, estatus } = this.props

        return (
            <>
                <Collapse
                    bordered={false}
                    className="mb-1 w-100"
                    defaultActiveKey={[this.props.estatus]}
                >
                    <Panel
                        header={this.props.label}
                        className={`requisiciones-tab tab-${this.props.name}`}
                        key={this.props.estatus}
                    >
                        { this.state.requisiciones.data.length > 0 ? <InfiniteScroll
                            dataLength={this.state.requisiciones.data.length}
                            next={this.next}
                            hasMore={this.state.hasMore}
                            loader={<Spin tip="Cargando..."><h4 style={{height: '100px'}}></h4></Spin>}
                            height={550}
                            className="mb-2"
                        >
                            <List
                                loading={this.state.loading}
                                className="component-list"
                                itemLayout="horizontal"
                                locale={{ emptyText: this.state.loading ? "Cargando" : "Sin Requisiciones" }}
                                dataSource={this.state.requisiciones.data}
                                renderItem={({_id, producto_id, proyecto_id, volumen, prioridad, opcion_final, almacen_id, activado, volumen_entregado, createdAt, fecha_entrega, unidad_id, inmuebles, fecha_autorizacion}, index) => (
                                    <List.Item className="component-list-item" key={_id}>
                                        <Collapse className='width-100'>
                                            <Panel
                                                header={
                                                    <Row className="width-100 " gutter={[0, 12]}>
                                                        <Col xs={24} md={6} className="center" >
                                                            <Text ellipsis>{producto_id?.nombre}</Text>
                                                        </Col>
                                                        <Col xs={24} md={3} className="center" >
                                                            <Text ellipsis >{volumen?.toMoney(true)} {unidad_id?.nombre}</Text>
                                                        </Col>
                                                        <Col xs={24} md={3} className="center" >
                                                            <Popover 
                                                                content={inmuebles?.map(e=><><Text>{e.nombre}</Text><br/></>)} 
                                                            >
                                                                <Text ellipsis>{inmuebles?.map(e=><Tag color="lime">{e.nombre}</Tag>)}</Text>
                                                            </Popover>
                                                        </Col>
                                                        <Col xs={24} md={3} className="center" >
                                                            <Text ellipsis >{volumen_entregado?.toMoney(true)} {this.props.estatus === 4 ? "Entregado" : "Recolectado"}</Text>
                                                        </Col>
                                                        <Col xs={24} md={4} className="center" >
                                                            <CustomAvatar 
                                                                name={proyecto_id.nombre}
                                                                color={proyecto_id.color}
                                                            />
                                                            <Text>{proyecto_id.nombre}</Text>
                                                        </Col>
                                                        <Col xs={24} md={1} className="center" >
                                                            <IconFlag fill={colors_flag[prioridad] ? colors_flag[prioridad] : '#A4A4A4'} />
                                                        </Col>
                                                        <Col xs={24} md={3} className="center" >
                                                            <Text ellipsis >{ fecha_autorizacion ? moment(fecha_autorizacion).format("YYYY-MM-DD") : activado ? moment(createdAt).format("YYYY-MM-DD")  : "-" }</Text>
                                                        </Col>
                                                        <Col xs={24} md={1} className="center">
                                                            <Button
                                                                type="primary"
                                                                icon={<IconEye/>}
                                                                onClick={(e)=>{
                                                                    this.setState({modal_visible_detalle: true, requisicion_id: _id})
                                                                    e.stopPropagation()
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                }
                                                key={_id}
                                                className={`requisiciones-item-${name}`}
                                            >
                                                <List
                                                    className='component-list'
                                                    itemLayout="horizontal"
                                                    locale={{ emptyText: "Sin proveedores" }}
                                                    dataSource={[{...opcion_final}]}
                                                    renderItem={item => (
                                                        <List.Item className="width-100">
                                                            <Row className="width-100">
                                                            	{/* <Col span={10} className="center">
                                                            		<Text> Almacen: <strong>{almacen_id?.nombre}</strong> </Text>
                                                            	</Col> */}
                                                            	<Col span={20} className="center">
                                                            		<Text> Fecha de Entrega: <strong>{moment(fecha_entrega).format("YYYY-MM-DD")}</strong> </Text>
                                                            	</Col>
                                                            	<Col span={40} className="center">
                                                            		<Button
                                                            			type="primary"
    		                                                            icon={<FaTruck/>}
    		                                                            className="center"
    		                                                            onClick={()=>this.setState({modal_visible: true, requisicion_id: _id, proyecto_id: proyecto_id?._id})}
    		                                                        />
                                                            	</Col>
                                                            </Row>
                                                        </List.Item>
                                                    )}
                                                />
                                            </Panel>
                                        </Collapse>
                                    </List.Item>
                                )}
                            />
                        </InfiniteScroll> : <Empty description={"Sin Requisisciones"} className="mb-3 mt-1"/> }

                    </Panel>
                </Collapse>
                <ModalEntrega
                    visible={this.state.modal_visible}
                    onClose={(flag)=>{
                        this.setState({modal_visible: false, requisicion_id: undefined, proyecto_id: undefined})
                        if(flag === true){
                            this.props.getRequisiciones([4,5,6,7])
                        }
                    }}
                    requisicion_id={this.state.requisicion_id}
                    estatus={this.props.estatus}
                    proyecto_id={this.state.proyecto_id}
                />
                <ModalRequisicionDetalle
                    visible={this.state.modal_visible_detalle}
                    onClose={(flag)=>{
                        this.setState({modal_visible_detalle: false, requisicion_id: undefined})
                        
                    }}
                    requisicion_id={this.state.requisicion_id}
                />
            </>
        )
    }
}
