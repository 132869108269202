import React, { Component } from 'react';
import { Avatar, Spin, PageHeader, Layout, message, List, Statistic, Row, Col, Card, Typography, Space, Button, Popconfirm, Tag, Empty, Popover, Tooltip, Dropdown, Menu } from 'antd'
import { useNavigate, useLocation, matchRoutes, Link } from "react-router-dom";
import { FiUsers } from 'react-icons/fi'
import { GrMoney } from 'react-icons/gr'


import {
    IconArrowBack, IconWalletBG,
    IconMoneyBG,
    IconHistorialBG,
    IconBilleteBG,
    IconDeuda,
} from '../../Widgets/Iconos';
import FiltrosDrawer from '../../Widgets/DrawerFiltros/DrawerFiltros';
import { MoreOutlined, FileSearchOutlined, FilterOutlined, FilePdfOutlined, EditOutlined, EllipsisOutlined, CheckCircleOutlined } from "@ant-design/icons";
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import { color_transaccion_tipo } from "../../Utils"

import { CardIconoMoney } from '../../Widgets/Cards';

import '../../../Styles/Modules/CRM/Transacciones.scss'

import useSearchParams from '../../../Hooks/SearchParams';

import axios from 'axios';
import ModalTransaccionProgramadaDetalle from '../Finanzas/Transacciones/ModalTransaccionProgramadaDetalle';
import ModalTransaccionProgramada from '../Finanzas/Transacciones/ModalTransaccionProgramada';
import User from '../../../Hooks/Logged';
import usePermissions from '../../../Hooks/usePermissions';
import NotAllow from '../NotAllow';
const { Content } = Layout;
const { Text, } = Typography;


const moment = require('moment');
moment.locale('es');

/**
 * @export
 * @class Lista
 * @extends {Component}
 * @description Vista de dashboard de transacciones programadas
 */
class Lista extends Component {

    constructor(props) {
        super(props)
        let { page, limit, filters, search, sort, grupo } = this.props.params
        this.state = {
            loading: false,
            transacciones: {

                page: page ?? 1,
                limit: limit ?? 20,

                total: 0,
                pages: 0,

                search: search ?? undefined,

                filters: filters ?? [],
                sort: sort ?? {},
                grupo: grupo ?? undefined,

                vencido: 0,
                proximo: 0,
                recibido: 0,
                por_recibir: 0,
                modalVisibleTransaccion: false

            }
        }
    }

    componentDidMount() {
        this.getTransacciones()
        this.getMontos()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.params?.grupo !== this.props.params?.grupo && this.props.params?.grupo) {
            this.getTransacciones({ grupo: this.props.params.grupo })
        }
    }


    /**
    *
    *
    * @memberof Lista
    * @description Obtiene todas todas las transacciones
    */
    getTransacciones = ({
        page = this.state.transacciones.page,
        limit = this.state.transacciones.limit,
        search = this.props.search,
        filters = this.state.transacciones.filters,
        sort = this.state.transacciones.sort,
        grupo = this.state.transacciones.grupo
    } = this.state.transacciones) => {

        const params = { page, limit, }
        if (search) params.search = search
        if (sort && Object.values(sort).length > 0) params.sort = JSON.stringify(sort)
        if (filters && filters?.length > 0) {
            params.filters = JSON.stringify(filters)
            grupo = undefined
        } else if (grupo) {
            params.grupo = grupo
        }

        this.props.setParams(params)

        this.setState({ transacciones: { ...this.state.transacciones, page, limit, search, filters, sort, grupo }, loading: true })
        axios.get('/transacciones-programadas', {
            params: {
                page,
                limit,
                search,
                anaitycs: true,
                filters,
                grupo
            }
        })
            .then(({ data }) => {
                console.log("data", data);
                this.setState({ transacciones: { ...this.state.transacciones, ...data } });
            })
            .catch(error => {
                message.error('Error al traer los transacciones programadas')
                console.log(error.response)
            }
            ).finally(() => this.setState({ loading: false }))
    }

    getMontos = () => {
        console.log("getMontos");
        axios.get("/cash-flow/montos", {
            params: {}
        })
            .then(response => {
                console.log("response", response.data);
                this.setState({
                    ...response.data
                })

            }).catch(error => {
                console.log("error", error);

            })
    }

    downloadReporte = (key) => {

        let url
        console.log(key)

        switch (key) {
            case 'inversion':
                url = new URL(axios.defaults.baseURL + '/transacciones-programadas/cartera-vencida/inversion')
                break
            case 'cliente':
                url = new URL(axios.defaults.baseURL + '/transacciones-programadas/cartera-vencida/cliente')
                break
            default:
                const { filters } = this.state.transacciones
                url = new URL(axios.defaults.baseURL + '/transacciones-programadas')
                if (Array.isArray(filters) && filters?.length > 0) url.searchParams.append("filters", JSON.stringify(filters))
                url.searchParams.append('file', 'pdf')
        }

        url.searchParams.append('Authorization', sessionStorage.getItem("token"))
        window.open(url.href)
    }

    diasAtraso = (item) => {
        if (item.monto_pagado == item.monto)
            return

        const fecha_limite = moment(item.fecha_limite)

        console.log('fecha_limite', fecha_limite);

        //Si la fecha actual esta dsepues de la fecha limite
        if (moment().isAfter(fecha_limite))
            return <div style={{ position: "absolute", right: '-100%', top: 0, color: "#F50" }}>
                (-{moment().diff(fecha_limite, 'day')})
            </div>

        if (!fecha_limite.subtract(item.dias_limite, 'days').isAfter(moment()))
            return <div style={{ position: "absolute", right: '-100%', top: 0, color: "#FBBC30" }}>
                ({moment().diff(fecha_limite, 'day')})
            </div>
    }

    renderEstatus = (item) => {
        if (item.monto_pagado == item.monto)
            return <Tag color="#87d068" icon={<CheckCircleOutlined />}><strong>PAGADO</strong></Tag>

        const fecha_limite = moment(item.fecha_limite)

        //Si la fecha actual esta dsepues de la fecha limite
        if (moment().isAfter(fecha_limite))
            return <Tag color="#F50">Vencida</Tag>

        if (fecha_limite.subtract(item.dias_limite, 'days').isAfter(moment()))
            return <Tag color="#2db7f5">Pendiente</Tag>

        return <Tag color="#FBBC30">Proxima a pagar</Tag>
    }


    render() {


        // vencido: 0,
        // proximo: 0,
        // recibido: 0,
        // por_recibir: 0,

        return (
            <Spin spinning={false}>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title="Listado de Cash Flow"
                    extra={
                        <Button
                            type="dashed"
                            onClick={() => this.setState({ visibleFilters: true })}
                            className="button-plus"
                            icon={<FilterOutlined />} />
                    }
                />
                <Content className="admin-content content-bg pd-1 ">
                    <Row gutter={[16, 16]} style={{ marginBottom: "2em" }}>
                        <Col xs={24} md={12} lg={6}>
                            <CardIconoMoney
                                to={`/admin/cash-flow/lista?grupo=vencidos`}
                                extra={<MoreOutlined style={{ transform: 'rotate(90deg)' }} />}
                                title="Vencidos"
                                icon={<IconWalletBG />}

                                // ingreso_vencido: vencidos ? vencidos.ingreso : 0,
                                // egreso_vencido: vencidos ? vencidos.egreso : 0,
                                first_subtitle="Pagos Vencidos"
                                second_subtitle="Cobros Vencidos"
                                first={this.state.egreso_vencido}
                                second={this.state.ingreso_vencido}
                            />
                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <CardIconoMoney
                                to={`/admin/cash-flow/lista?grupo=por_vencer`}
                                extra={<MoreOutlined style={{ transform: 'rotate(90deg)' }} />}
                                title="Por Vencer"
                                // monto={1254.25}
                                first_subtitle="Pagos por Vencer"
                                second_subtitle="Cobros por Vencer"
                                first={this.state.egreso_por_vencer}
                                second={this.state.ingreso_por_vencer}

                                icon={<IconMoneyBG />}
                            />

                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <CardIconoMoney
                                to={`/admin/cash-flow/lista?grupo=recibidos`}
                                extra={<MoreOutlined style={{ transform: 'rotate(90deg)' }} />}
                                // title="Pagos Recibidos"
                                // // monto={1254.25}
                                // monto={this.state.recibido?.toMoney()}

                                title="Recibidos"
                                // monto={1254.25}
                                first_subtitle="Pagos Recibidos"
                                second_subtitle="Cobros Recibidos"
                                first={this.state.ingreso_recibido}
                                second={this.state.egreso_recibido}


                                icon={<IconHistorialBG />}
                            />

                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <CardIconoMoney
                                to={`/admin/cash-flow/lista?grupo=por_recibir`}
                                extra={<MoreOutlined style={{ transform: 'rotate(90deg)' }} />}

                                title="Por Recibir"
                                first_subtitle="Pagos por Recibir"
                                second_subtitle="Cobros por Recibir"
                                first={this.state.ingreso_por_recibir}
                                second={this.state.egreso_por_recibir}
                                icon={<IconBilleteBG />}
                            />
                        </Col>
                    </Row>
                    <Row className='width-100 '>
                        <List
                            loading={this.state.loading}
                            className="component-list width-100 "
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Transacciones" }}
                            dataSource={this.state.transacciones?.data}
                            pagination={{
                                current: this.state.transacciones.page,
                                pageSize: this.state.transacciones.limit,
                                total: this.state.transacciones.total,
                                showSizeChanger: true,
                                position: 'bottom',
                                className: "flex-right",
                                onChange: (page, limit) => this.getTransacciones({ page, limit })
                            }}
                            header={<Row className="header-list width-100 ">
                                <Col xs={12} md={3} className="center">
                                    <strong>CONCEPTO</strong>
                                </Col>
                                <Col xs={12} md={3} className="center">
                                    <strong>FECHA DE CREACIÓN</strong>
                                </Col>
                                <Col xs={12} md={3} className="center">
                                    <strong>FECHA DE PAGO</strong>
                                </Col>
                                <Col xs={12} md={3} className="center">
                                    <strong>ÁREA/RUBRO</strong>
                                </Col>
                                <Col xs={12} md={3} className="center">
                                    <strong>MONTO</strong>
                                </Col>
                                <Col xs={12} md={3} className="center">
                                    <strong>MONTO PENDIENTE</strong>
                                </Col>
                                <Col xs={12} md={3} className="center">
                                    {/* <strong>PROYECTO</strong> */}
                                </Col>
                                <Col xs={12} md={1} className="center">
                                    <strong>ESTATUS</strong>
                                </Col>
                                <Col xs={24} md={2} align="right"></Col>
                            </Row>
                            }

                            renderItem={item => <List.Item className="component-list-item transacciones">
                                <Card className="ant-card-list width-100" style={{ position: "relative" }}>
                                    {(item.tipo == 1) ?
                                        <div style={{ height: "100%", background: "#87d068", width: 7, borderRadius: "10px 0px 0 10px ", position: "absolute", left: 0, top: 0 }} />
                                        :
                                        <div style={{ height: "100%", background: "#ff4d4f", width: 7, borderRadius: "10px 0px 0 10px ", position: "absolute", left: 0, top: 0 }} />
                                    }
                                    <Row className="width-100" align={"middle"} gutter={[16, 16]}>
                                        <Col xs={12} md={3} className="center">
                                            {item.concepto}
                                        </Col>
                                        <Col xs={12} md={3} className="center">
                                            {moment(item.createdAt).format('YYYY-MM-DD')}
                                        </Col>
                                        <Col xs={12} md={3} className="center">
                                            <span style={{ position: "relative" }}>
                                                {this.diasAtraso(item)}
                                                {moment(item.fecha_limite).format('YYYY-MM-DD')}
                                            </span>
                                        </Col>
                                        <Col xs={12} md={3} className="center">
                                            <Space direction='vertical' size='small' className="space-small-vertical">
                                                <Text ellipsis className="text-gray-dark">{item.area_id?.nombre ? item.area_id?.nombre : 'No Asignado'}</Text>
                                                <Text ellipsis className="text-gray-dark">{item.rubro_id?.nombre ? item.rubro_id?.nombre : 'No Asignado'}</Text>
                                            </Space>
                                        </Col>
                                        <Col xs={12} md={3} className="center">
                                            {item.monto.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}
                                        </Col>
                                        <Col xs={12} md={3} className="center">
                                            {item.monto_pagado.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}
                                        </Col>
                                        <Col xs={12} md={3} className="center">
                                            {item.monto_pendiente.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}
                                        </Col>
                                        <Col xs={12} md={1} className="center">
                                            {this.renderEstatus(item)}
                                        </Col>
                                        <Col xs={24} md={2} align="right">
                                            <Space direction="horizontal" size={5}>
                                                <Button
                                                    icon={<FileSearchOutlined style={{ color: "currentcolor" }} />}
                                                    type="secondary"
                                                    title="Ver Detalle"
                                                    onClick={() => this.setState({ modal_visible: true, transaccion_id: item._id })}>
                                                </Button>

                                                <Button
                                                    icon={<EditOutlined style={{ color: "currentcolor" }} />}
                                                    type="secondary"
                                                    title="Ver Detalle"
                                                    onClick={() => this.setState({ modalVisibleTransaccion: true, transaccion_id: item._id })}>
                                                </Button>

                                            </Space>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>}
                        />
                    </Row>

                </Content>
                <ModalTransaccionProgramadaDetalle
                    visible={this.state.modal_visible}
                    transaccion_programada_id={this.state.transaccion_id}
                    onClose={() => {
                        this.setState({ modal_visible: false, transaccion_id: undefined })
                    }}
                />
                <ModalTransaccionProgramada
                    visible={this.state.modalVisibleTransaccion}
                    transaccion_programada_id={this.state.transaccion_id}
                    onClose={() => {
                        this.setState({ modalVisibleTransaccion: false, transaccion_id: undefined })
                    }}
                />
                <FiltrosDrawer
                    title="Filtrar Transacciones"
                    ref={ref => this.drawerFiltros = ref}
                    updateFilters={filters => this.getTransacciones({
                        page: 1,
                        filters
                    })}
                    extra={<>
                        <Dropdown overlay={
                            <Menu
                                onClick={({ key }) => {
                                    this.downloadReporte(key)
                                }}
                            >
                                <Menu.Item
                                    key="general"
                                    icon={<FilePdfOutlined />}
                                >
                                    Reporte General
                                </Menu.Item>
                                <Menu.Item
                                    key="cliente"
                                    icon={<FiUsers />}
                                >
                                    Reporte por Cliente
                                </Menu.Item>
                                <Menu.Item
                                    key="inversion"
                                    icon={<GrMoney />}
                                >
                                    Reporte por Inversión
                                </Menu.Item>
                            </Menu>
                        }>
                            <Button
                                icon={<EllipsisOutlined />}
                            />
                        </Dropdown>

                    </>}
                    // csv="transacciones"
                    onClose={() => this.setState({ visibleFilters: false })}
                    visible={this.state.visibleFilters}
                    clientes={true}
                    ordenes={false}
                    ordenes_compras={false}
                    proveedores={false}
                    proyectos_inmueble={false}
                    empresas={false}
                    accredores={true}
                    inversiones={true}
                    area_rubro={false}
                    cuentas={false}
                />
            </Spin>
        )
    }
}

export default function () {
    const location = useLocation()
    const [params, setParams] = useSearchParams();

    let user = React.useContext(User);
    let permissions = usePermissions(user?.rol_id?.permisos, {
        access: ["cash-flow", "lista"],
    })

    return <Lista
        setParams={setParams}
        params={params}
        navigate={useNavigate()}
        location={location}
    />
}