import React, { Component } from 'react';
import { Button, Col, Row,  PageHeader, Layout, Space, message, Typography, Statistic, Card, List, Tooltip } from 'antd'
import { FaArrowRight } from "react-icons/fa";
import Carousel from 'react-multi-carousel';
import axios from 'axios'
import { IconDetails } from '../../Widgets/Iconos';
import { CardSaldo, CardCuenta } from "../../Widgets/Cards"
import ModalTransaccionDetalle from './Transacciones/ModalTransaccionDetalle';

import 'react-multi-carousel/lib/styles.css';
import { useNavigate } from 'react-router-dom';
import { RedoOutlined } from '@ant-design/icons';
import ModalRenovarCuentasSyncfy from './ModalRenovarCuentasSyncfy';

const { Content, } = Layout;
const { Text, Title } = Typography;

const moment = require('moment');
moment.locale('es');


const responsive = {
	desktop_fhq: {
		breakpoint: { max: 4000, min: 1200 },
		items: 2
	},
	desktop_hq: {
		breakpoint: { max: 1500, min: 1200 },
		items: 1
	},
	desktop_xxl: {
		breakpoint: { max: 1200, min: 992 },
		items: 1
	},
	desktop_xl: {
		breakpoint: { max: 992, min: 768 },
		items: 1
	},
	desktop_lg: {
		breakpoint: { max: 768, min: 576 },
		items: 1
	},
	desktop_md: {
		breakpoint: { max: 576, min: 464 },
		items: 1
	},
	desktop_sm: {
		breakpoint: { max: 464, min: 0 },
		items: 1
	},

	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1
	}
};


/**
 * 
 * @export
 * @class DashboardFinanzas
 * @extends {Component}
 * @description Componente del Dashboard de Finanzas
 */
class DashboardFinanzas extends Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			cuentas: {
				data: [],
				limit: 20,
				page: 1,
				total: 0,
				pages: 0,
				loading: false,
			},
			montos: {},
			transacciones: {
				data: [],
				limit: 10,
				page: 1,
				total: 0,
				pages: 0,
				loading: false,
			},
			modal_transaccion_detalle_visible: false,
			transaccion_id: undefined
		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getTransacciones();
		this.getCuentas();
	}

	/**
	 *
	 *
	 * @memberof Transacciones
	 * 
	 * @method getTransacciones
	 * @description Obitiene las Transacciones
	 */
	getTransacciones = (
		{
			page = this.state.transacciones.page,
			limit = this.state.transacciones.limit,
			search = this.props.search
		} = this.state.transacciones
	) => {
		axios.post('/transacciones/filters', {
			page, limit, search
		})
			.then(({ data }) => {
				console.log("data", data);
				let transacciones = data.data
				this.setState({
					transacciones: {
						...this.state.transacciones,
						data: transacciones,
						total: data.total,
						pages: data.pages,
						loading: false
					},
					loading: false
				});
			})
			.catch(error => {
				this.setState({
					transacciones: { ...this.state.transacciones, loading: false }
				})
				console.log('error al cargar transacciones', error)
				message.error(error.message)
			})
	}

	/**
	 *
	 *
	 * @memberof Transacciones
	 * 
	 * @method getCuentas
	 * @description Obtiene las Cuentas 
	 */
	getCuentas = () => {
		axios.get('/cuentas', {
			params: {
				limit: 20,
				page: 1
			}
		}).then(({ data }) => {
			this.setState({ cuentas: { ...this.state.cuentas, ...data, }, montos: data.montos })

		}).catch(error => {
			console.log(error)
			message.error('Error al traer la información de las cuentas')
		}).finally(() => this.setState({ loading: false }))
	}

	/**
	 *
	 * @memberof DashboardFinanzas
	 * @description Renderiza el monto con el color correspondiente segun el tipo de transaccion
	 */
	renderMonto = (tipo, monto) => {
		let colors = ['#000000', '#00FF19', '#FF0000'];
		return <Statistic value={monto} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: colors[tipo] }} prefix={'$'} />
	}

	render() {
		const { cuentas, montos } = this.state
		return (
			<>
				<PageHeader
					className='custom-page-header'
					title="Dashboard Finanzas"
					extra={
						<Tooltip title="Renovar tokens de cuentas (Scynfy)">
							<Button icon={<RedoOutlined />} onClick={() => this.setState({modal_renovar: true})} >
								Renovar Credenciales
							</Button>
						</Tooltip>
					}
				/>
				<Content className='admin-content content-bg pd-1'>
					<Space gutter={[16, 16]} direction="vertical" className="width-100">
						<Row align="middle" >
							<Col xs={24} sm={24} md={12} lg={10} xl={8}>
								<CardSaldo 
									{...montos}
								/>
							</Col>
							<Col xs={24} sm={24} md={12} lg={14} xl={16}>
								<Carousel
									responsive={responsive}
									className='carousel-cards'
								>
									{cuentas.data.map(cuenta => {
										return <CardCuenta cuenta={cuenta} />
									})}
								</Carousel>
							</Col>


						</Row>
						<Row gutter={[16, 16]}>
							{/* <Col xs={24} sm={24} md={24} lg={16} xl={17} xxl={17}> */}
							<Col xs={24}>
								<Card className='height-100 border-16 mt-1 card-shadow'>
									<Row justify='space-around'>
										<Col span={21}>
											<Title level={3} className='text-gray'>Últimas Transacciones</Title>
										</Col>
										<Col span={3} className='center'>
											<Button type="primary" className='ml-1' onClick={() => this.props.navigate('/admin/finanzas/transacciones')} icon={<FaArrowRight style={{ marginTop: 4 }} />} />
										</Col>
									</Row>
									<List
									size='small'
										className="component-list medium-list"
										dataSource={this.state.transacciones.data}
										locale={{ emptyText: 'Sin Transacciones' }}
										key={(item => item._id)}
										renderItem={item => (
											<List.Item className="component-list-item">
												<Card className="card-list" bordered={false}>
													<Row className="width-100" align="middle">
														<Col span={3} >
															<Text strong>{moment(item.fecha).format('YYYY-MM-DD')}</Text>
														</Col>
														<Col xs={5} className="center">
															<Text>{item.concepto}</Text>
														</Col>
														<Col xs={6} className="center">
															<Text>{item.cuenta_id?.nombre}</Text>
														</Col>
														<Col xs={4} className="center">
															<Text>{item.rubro_id?.nombre}</Text>
														</Col>
														<Col xs={4} className="center">
															<Text>{this.renderMonto(item.tipo, item.monto)}</Text>
														</Col>
														<Col xs={2} className="center">
															<Button type="ghost" icon={<IconDetails />} onClick={() => this.setState({ modal_transaccion_detalle_visible: true, id: item._id })} />
														</Col>
													</Row>
												</Card>
											</List.Item>
										)}
									/>
								</Card>
							</Col>
							{/* <Col xs={24} sm={24} md={24} lg={8} xl={7} xxl={7}>
								<Space direction="vertical" className='width-100'>
									<CardGains title={'Ingresos'} status={'success'} />
									<CardGains title={'Gastos'} status={'exception'} />
								</Space>
							</Col> */}
						</Row>
					</Space>
				</Content>

				<ModalTransaccionDetalle
					visible={this.state.modal_transaccion_detalle_visible}
					onClose={() => this.setState({
						modal_transaccion_detalle_visible: false,
						transaccion_id: undefined
					})}
					transaccion_id={this.state.id}
				/>
				<ModalRenovarCuentasSyncfy
					visible={this.state.modal_renovar}
					onClose={() => {
						this.setState({
							modal_renovar: false
						})
						this.getCuentas()
						this.getTransacciones()
					}}
				/>
			</>
		)
	}
}

export default function (props) {

	return <DashboardFinanzas {...props} navigate={useNavigate()} />

}