import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'
import CustomAvatar from "../Avatar/Avatar";
const { Option } = Select 
const axios = require("axios");

/**
 * @const SelectProyecto
 * @description Select para los proyectos registrados en el sistema
 */
const SelectProyecto = (props) => {

    const { 
        value, 
        onChange, 
        placeholder = "Seleccione el proyecto", 
        onSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        bordered, 
        allowClear = true, 
        onClear = () => {},
        proyecto= {} 
    } = props

    const [ proyectos, setProyectos ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })

    /**
     * @const getProyectos
     * @description Obitiene los proyectos
     */
    const getProyectos = ({page, limit, search} = proyectos) => {
        console.log("Proyecto",props.proyecto)
        axios.get('/proyectos', {   
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            let datos = data
            if (props.proyecto) {
                datos.data = [...datos.data, props.proyecto]
            } 
            setProyectos(datos)
        }).catch(error => {
            console.log("ERR, error",error)
            message.error(error?.response?.data?.message ?? 'Error al obtener los proyectos')
        })
    }

    //componentDidMount
    useEffect(() => {
        getProyectos()
    }, [])

    useEffect(() => {
        if(value)
            onChange(value?.value ?? value)
    }, [value])

    return (
        <Select
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getProyectos({search})}
            onSelect={(proyecto)=> {
                onChange(proyecto.value)
                onSelect(proyecto.value)
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
        >
            {
                proyectos?.data?.map(({ _id, nombre, color, logo }) => <Option value={_id} key={_id}>
                    <CustomAvatar
                        size="small"
                        url={{
                            url:`${axios.defaults.baseURL}/proyectos/logo/${_id}`,
                            name: logo?.name
                        }}
                        color={color}
                        name={nombre}
                    />
                    {nombre ?? ""}
                </Option>)
            }
        </Select>
    );
}



export default SelectProyecto;