import React from 'react'
import { Route, Routes } from "react-router-dom";

import Acreedores from '../components/Admin/Acreedor/Acreedores'
import AcreedoresDetalle from '../components/Admin/Acreedor/AcreedorDetalle'



/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterAcreedores(props) {
    return (
        <Routes>
            <Route path="" element={<Acreedores {...props} />} />
            <Route path="detalle/:id" element={<AcreedoresDetalle  {...props}  />} />
        </Routes>
    )
}

export default RouterAcreedores;
