import { Avatar, Button, Form, Input, Space, Typography, Dropdown, Card, Upload, message, Progress, Image } from "antd";
import React from "react";
import moment from "moment";
import EmojiPicker from 'emoji-picker-react';
import SocketIOFileClient from 'socket.io-file-client';
import { DeleteOutlined, FileOutlined, LeftOutlined, ReloadOutlined, SendOutlined, SmileOutlined } from "@ant-design/icons";

import Logged from "../../../Hooks/Logged";
import { SocketContext } from '../../../Hooks/Socket';  

import "../../../Styles/Modules/Requisiciones/Chat.scss";
// import SocketIOFileUpload from 'socketio-file-upload/client';



import axios from "axios";


const { Text, Title, Paragraph } = Typography

class ChatApp extends React.Component {


    static defaultProps = {
        type: "admin"
    }


    constructor(props) {
        super(props);
        this.state = {
            messages: {
                data: [],
                page: 1,
                limit: 10,
                pages: 0,
                total: 0
            },

            message: '',
            filesUploaded: {},

            shiftKey: false,
        };
    }

    static contextType = SocketContext;

    componentDidMount() {
        
        this.context.emit("cliente_start", sessionStorage.getItem("token"));
        this.context.removeListener('successful');
        this.context.on('successful', this.IO_start);


        let uploader = new SocketIOFileClient(this.context);

        // uploader.on('start', function (fileInfo) {
        //     console.log('Start uploading', fileInfo);
        // })
        // uploader.on('stream', (file) => {
        //     this.setState(state => {
        //         state.filesUploaded[file.uploadId].progress = (file.size / file.sent) * 100
        //         return state;
        //     })
        // })
        uploader.on('complete', (file) => {
            this.setState(state => {
                state.filesUploaded[file.uploadId].progress = 100
                state.filesUploaded[file.uploadId].filename = file.name
                return state;
            })
        })
        uploader.on('error', function (err) {
            console.log('Error!', err);
            message.error("No fue posible subir el archivo")
        })
        uploader.on('abort', function (fileInfo) {
            console.log('Aborted: ', fileInfo);
            message.error("El proceso fue abortado")
        })
        this.uploader = uploader



    }

    componentDidUpdate(){

        document.getElementById("messages-body").addEventListener('scroll', this.handleScroll, true);
    }

    componentWillUnmount(){
        this.context.removeListener('successful');
        this.context.removeListener('mensaje_nuevo');
    }


    /***
     *
     * @methodOf ChatCliente
     * @function IO_start
     * 
     * @description Inicio del socket, obtebemos los mensajes, 
     * nos regresa el usuario logeado y lo ponemos el state
     */
    IO_start = ({ user_id }) => {
        const { page, limit } = this.state;

        this.setState({ remitente_id: user_id })

        //Entramos al room del cliente
        this.context.emit('cliente_enter_room', { requisicion_id: this.props.requisicion_id })

        //Eliminamos estos metodos para volverlos a declarar
        this.context.removeListener('mensaje_nuevo');

        //Se declaran de nuevo
        this.context.on('mensaje_nuevo', this.IO_mensaje_nuevo)

        //Obetenemos los mensajes relacionado al cliente
        this.getMensajes({page: 1})
        
    }


    /***
     *
     * @methodOf ChatCliente
     * @function getMensajes
     * 
     * @description Obtiene los mensajes relacionados al cliente
     */
    getMensajes = ({
        page = this.state.messages.page,
        limit = this.state.messages.limit
    } = this.state.messages) => {

        axios.get('/mensajes',{
            params: {
                page,
                limit,
                requisicion_id: this.props.requisicion_id
            }
        }).then(response => {
            let mensajesList = this.state.messages.data;
            mensajesList.push(...response.data.data)
            this.setState(state => {
                state.messages.data = mensajesList;
                state.messages.page = response.data.page;
                state.messages.limit = response.data.limit;

                //Se hace scroll hasta abajo
                if (this.scrollToBottomOption === true) {
                    setTimeout(this.scrollToBottom, 250)
                }

                return state;
            })
            
        }).catch(error => {
            console.log("error", error);

        })

    }

    /***
     *
     * @methodOf ChatCliente
     * @function handleSubmit
     * 
     * @description Se ejecuta para enviar el mensaje
     */
    handleSubmit = ({ message }) => {
        let filesUploaded = Object.values(this.state.filesUploaded)
        if ((message && message.length > 0) || filesUploaded.length > 0) {

            this.formRef.setFieldsValue({ message: "" })
            this.setState({ filesUploaded: {} })

            this.context.emit('cliente_new_message', {
                message: message,
                remitente_id: this.state.remitente_id,
                requisicion_id: this.props.requisicion_id,
                files: filesUploaded.map(({ filename, file }) => ({ filename: filename, name: file.name })),
            })
        }
    }

    /**
     *
     * @methodOf ChatCliente
     * @function IO_mensaje_nuevo
     * 
     * @description Cuando llegue un mensaje nuevo, se agrega a la lista de mensajes
     */
    IO_mensaje_nuevo = ({ mensaje }) => {
        let data = this.state.messages.data
        data.unshift(mensaje)
        this.setState({ 
            messages:{
                ...this.state.messages,
                data
            }
        })

        //Se hace scroll hasta abajo
        if (this.scrollToBottomOption === true) {
            setTimeout(this.scrollToBottom, 250)
        }
    }

    /**
     * @methodOf ChatCliente
     * @function uploadFile
     * 
     * @description Sube un archivo 
     */
    uploadFile = (file) => {
        let [_id] = this.uploader.upload([file.file]);
        this.setState(state => {
            state.filesUploaded[_id] = {
                ...file,
                progress: 0,
                uploadId: _id,
                isImage: Boolean(/\.(png|jpe?g|gif|webp|svg)(\?.*)?$/.test(file.file.name))
            }
            return state
        })
    }


    /**
     *
     * @methodOf ChatCliente
     * @function deleteFile
     * 
     * @description elimina un archivo del server y del front
     */
    deleteFile = ({ filename, uploadId }) => {
        this.context.emit('delete-file', { filename })
        this.setState(state => {
            delete state.filesUploaded[uploadId]
            return state
        })
    }

    /**
     *
     * @methodOf ChatCliente
     * @function handleScroll
     * 
     * @description Se ejecuta cuando se scrollea el chat hasta, si es hasta arriba carga los mensajes antiguos 
     * 
     * */
    handleScroll = (event) => {
        let { scrollTop, scrollHeight, clientHeight } = document.getElementById("messages-body");
        let { page, limit, search } = this.state.messages;
        if ((scrollHeight + scrollTop) === clientHeight) {
            this.scrollToBottomOption = false;
            page = page + 1
            this.getMensajes({page, search, limit})
        }else{
             this.scrollToBottomOption = false;
        }

        if (scrollTop === 0){
            this.scrollToBottomOption = true;
        }
    }


    /***
     *
     * @methodOf ChatCliente
     * @function renderMessage
     * 
     * @description REnderiza los mensajes de la requisiscion
     */
    renderMessage = ({ mensaje, _id, remitente_id, createdAt, archivos, actualizacion }) => {

        let me = (this.state.remitente_id === remitente_id?._id)

        if(actualizacion){
            return <div className={`message`} key={_id} style={{width: '100%'}}>
                <div class="message-body" style={{width: '100%', maxWidth: "unset"}}>
                    <Title level={5} style={{ color: "currentcolor", display: "inline-block" }}>{remitente_id.nombre} {remitente_id.apellidos}</Title>
                    <Paragraph style={{ color: "currentcolor", marginBottom: 0, textAlign: "center" }}>
                        {mensaje}
                    </Paragraph>
                    <Text style={{ opacity: 0.5, width: "100%", color: "currentcolor", display: "inline-block", textAlign: "end", fontSize: 11}}>
                        {moment(createdAt).format("LL HH:mm:ss")}
                    </Text>
                </div>
            </div>
        }

        return <div className={`message ${me ? "me" : ''}`} key={_id} style={me ? { justifyContent: "flex-end" } : {}}>
            {(!me ? <Avatar /> : null)}
            <div class="message-body">
                {me ? <Title level={5} style={{ color: "currentcolor", display: "inline-block" }}>{remitente_id.nombre} </Title> : null}
                <Paragraph style={{ color: "currentcolor", marginBottom: 0 }}>{mensaje}</Paragraph>
                <Space style={{ width: "100%" }} direction="vertical">
                    {archivos?.map(e => Boolean(/\.(png|jpe?g|gif|webp|svg)(\?.*)?$/.test(e.filename)) ? <Image style={{ maxWidth: 200 }} src={axios.defaults.baseURL + "/upload/" + e.filename} className="mb-1" /> 
                        : <Button size="small" target="_blank" download href={axios.defaults.baseURL + "/upload/" + e.filename} block >{e.name}</Button>)}
                </Space>
                <Text style={{
                    opacity: 0.5,
                    width: "100%",
                    color: "currentcolor", display: "inline-block", textAlign: "end",
                    fontSize: 11,
                }}>{moment(createdAt).format("LL HH:MM")}</Text>
            </div>
        </div>
    }

    render() {

        let filesUploaded = Object.values(this.state.filesUploaded)

        return (<>
            <div class="chat">
                <div class="messages">
                    <div class="messages-history" id="messages-body">
                        {this.state.messages.data.map(this.renderMessage)}
                    </div>
                    {(filesUploaded.length > 0) ? <Space className="chat-form-file">
                        {filesUploaded.map(e => <Card className="chat-form-file-card" size="small">
                            <div className="hover-content">
                                <Button 
                                    onClick={() => this.deleteFile({ uploadId: e.uploadId, filename: e.filename })} 
                                    type="primary" 
                                    className="button-delete-file" 
                                    danger 
                                    icon={<DeleteOutlined style={{ color: "currentcolor" }} />} 
                                />
                            </div>
                            {(e.progress < 100) ? <Progress
                                style={{
                                    position: 'absolute',
                                    top: 30,
                                    width: 76
                                }}
                                type="line"
                                percent={e.progress}
                                showInfo={false}
                                size="small"
                                strokeLinecap="round"
                            /> : (
                                e.isImage ? <Avatar className="chat-form-file-image" shape="square" src={axios.defaults.baseURL + "/upload/" + e.filename} /> : <FileOutlined className={"chat-form-file-image-icon"} />
                            )}
                            <br />
                            <Text style={{ fontSize: 10 }}>{e?.file?.name?.substring(0, 10)}</Text>
                        </Card>)}
                    </Space> : null}

                    <Form
                        style={{ background: "white" }}
                        className="chat-form"
                        ref={e => this.formRef = e}
                        onFinish={this.handleSubmit}
                    >
                        <Dropdown
                            forceRender={true}
                            overlay={<EmojiPicker
                                onEmojiClick={e => {
                                    this.formRef.setFieldsValue({ message: (this.formRef.getFieldValue("message") ?? "") + e.emoji })
                                }}
                            />} trigger={"click"}>
                            <Button type="link" icon={<SmileOutlined style={{ color: "currentcolor" }} />} ></Button>
                        </Dropdown>
                        <Form.Item name="message" noStyle>
                            <Input.TextArea
                                style={{ flex: "0 0 1", }}
                                bordered={false}
                                autoSize={{ minRows: 1 }}
                                placeholder={"Envia un mensaje..."}
                                onPressEnter={e => {
                                    if (this.state.shiftKey == false) {
                                        e.preventDefault()
                                        this.formRef.submit()
                                    }
                                }}
                            />
                        </Form.Item>
                        <Upload
                            multiple
                            showUploadList={false}
                            customRequest={this.uploadFile}>
                            <Button type="link" icon={<FileOutlined style={{ color: "currentcolor" }} />} ></Button>
                        </Upload>
                        <Button 
                            htmlType="submit" 
                            type="link" 
                            icon={<SendOutlined 
                            style={{ color: "currentcolor" }} />} 
                        />
                    </Form>
                </div>
            </div>
        </>
        );
    }
}


export default function (props) {

    return <ChatApp {...props} user={React.useContext(Logged)} />
}