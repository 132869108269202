import React from 'react'
import { Route, Routes } from "react-router-dom";

// Components
import Clientes from '../components/Admin/CRM/Clientes/Clientes';
import DetallesClientes from '../components/Admin/CRM/Clientes/DetallesClientes';
import DashboardCRM from '../components/Admin/CRM/DashboardCRM';
//import DetallesProyecto from '../components/Admin/CRM/Clientes/DetallesProyecto';


/**
 * 
 * @export
 * @function RouterCRM
 * @description Router para CRM routes 
 */
function RouterCRM(props) {
  return (
    <Routes>
      {/* render={(props) => } */}
      <Route path="" element={<DashboardCRM {...props} />} />
      <Route path="clientes" element={<Clientes {...props} />} />
      <Route key="cliente" path="clientes/:cliente_id" element={<DetallesClientes {...props} key="cliente-detalles" />} />
      <Route key="cliente-propiedad" path="clientes/:cliente_id/propiedad/:propiedad_id" element={<DetallesClientes {...props} key="cliente-detalles-propiedad" />} />

    </Routes>
  )
}

export default RouterCRM