import React, { Component } from "react";
import { Row, Col, Modal, Form, Checkbox, Input, message, Spin, Select, Space, List, DatePicker, Divider, InputNumber, Button } from 'antd';
import moment from "moment";

import { UploadOutlined } from "@ant-design/icons"

import { Uploader } from "../../../Widgets/Uploaders";
// import "../../../../Styles/Modules/Usuarios/ModalUsuarios.scss";
import "../../../../Styles/Modules/Usuarios/ModalUsuarios.scss";

const axios = require('axios').default;
const { Option } = Select;



/**
 * @class ModalUsuarios
 * @description Modal para el CRUD de Usuarios
 */
class ModalVacaciones extends Component {

    constructor(props) {
        super(props)
        this.state = {
            empresas: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

            },
            loading: false,
            mostrarProyectos: false
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')


        if (this.props.vacaciones_id) {
            this.get()
        }
    }




    formRef = React.createRef();

    /**
    * @method addUser
    * @description Añade un nuevo registro de usuario
    */
    get = () => {
        this.setState({ loading: true })
        axios.get('/vacaciones/' + this.props.vacaciones_id)
            .then(({ data }) => {
                let datos = data
                console.log(datos)
                datos.fecha_inicio = datos.fecha_inicio ? moment(datos.fecha_inicio) : null
                datos.fecha_fin = datos.fecha_fin ? moment(datos.fecha_fin) : null

                console.log('datos.comprobantes', datos);

                this.formRef.current.setFieldsValue({
                    ...datos,
                    comprobantes: (Array.isArray(datos.comprobantes)) ? datos.comprobantes.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/vacaciones/" + this.props.vacaciones_id + "?comprobante=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                    })) : [],
                })
            }).catch(error => {
                console.log(error)
                message.error('Error al obtener la solicitud')
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method onFinish
    * @description Se ejecuta al hacer submit al formulario
    */
    onFinish = (values) => {

        const formData = new FormData()


        // values.fecha_inicio = values.fecha_inicio
        formData.appendMultiple({
            ...values,
            vacacion_id: this.props.vacaciones_id,
            id: this.props.vacaciones_id,
            empleado_id: this.props.empleado_id,
        })


        console.log('values', values);
        values.comprobantes?.forEach(e => {
            if (!e.fromDB)
                formData.append("comprobantes", e, e.name)
        })


        if (this.props.vacaciones_id) {
            this.update(formData)
        } else {
            this.add(formData)
        }

    };


    /**
    * @method addUser
    * @description Añade un nuevo registro de usuario
    */
    add = (values) => {
        this.setState({ loading: true })
        axios.post('/vacaciones', values)
            .then(response => {

                message.success('Vacacion Solicitada!')
                this.props.onClose()
            }).catch(error => {
                console.log("error", error);
                message.error('Error al crear la solicitud')
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method updateUser
    * @description Actualiza la informacion de un usuario
    */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/vacaciones', values)
            .then(response => {
                message.success('Vacacion Actualizada!')
                this.props.onClose()
            }).catch(error => {
                console.log("error", error);
                message.error('Error al actualizar la solicitud')
            }).finally(() => this.setState({ loading: false }))
    }



    render() {

        window.a = this.formRef

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-empleado"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                >
                    <Row justify="center" className="center" gutter={[16, 16]}>
                        <Col md={24} xl={12}>
                            <Form.Item
                                name="fecha_inicio"
                                label="Fecha de Inicio"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese la fecha de inicio',
                                    },
                                ]}
                            >
                                <DatePicker className="width-100" />
                            </Form.Item>
                        </Col>
                        <Col md={24} xl={12}>
                            <Form.Item
                                name="fecha_fin"
                                label="Fecha de Fin"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese la fecha de inicio',
                                    },
                                ]}
                            >
                                <DatePicker className="width-100" />
                            </Form.Item>
                        </Col>
                        <Col md={24}>
                            <Form.Item
                                name="comprobantes"
                                label="Documentos Evidencia"
                                getValueFromEvent={e => {
                                    if (Array.isArray(e)) {
                                        return e;
                                    }
                                    return e?.fileList;
                                }}>
                                <Uploader
                                    {...this.props.vacaciones_id ? {
                                        method: "PUT",
                                        name: "comprobante",
                                        headers: {
                                            authorization: sessionStorage.getItem("token")
                                        },
                                        action: axios.defaults.baseURL + '/vacaciones',
                                        onRemove: e => {
                                            axios.put('/vacaciones', {
                                                id: this.props.vacaciones_id,
                                                comprobante: e.uid,
                                            })
                                        }
                                    } : {}}>
                                    <Button icon={<UploadOutlined />} block size="small">Subir Documentos </Button>
                                </Uploader>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Vista(props) {

    const { visible = false, onClose = () => { }, vacaciones_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={vacaciones_id ? "Editar Solicitud" : "Crear Solicitud"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-empleado', key: 'form-empleado', htmlType: 'submit' }}
    >
        <ModalVacaciones  {...props} />
    </Modal>

}