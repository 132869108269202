import React from 'react';
import { Row, Col, Button, Popconfirm, PageHeader, message, Space, List, Dropdown, Menu, Typography, Collapse, Modal, Empty, Avatar, Checkbox } from 'antd'
import axios from 'axios';
import { Link, useSearchParams } from "react-router-dom"
import { Content } from 'antd/lib/layout/layout';

// Componentes
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { IconDelete, IconEdit, IconArrow } from '../../../Widgets/Iconos';
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
import Logged from '../../../../Hooks/Logged'
import usePermissions from '../../../../Hooks/usePermissions';
import FiltrosDrawer from '../../../Widgets/DrawerFiltros/DrawerFiltros';

// Css
import '../../../../Styles/Modules/CRM/Clientes.scss';
import ModalClientes from './ModalClientes';

// import usePermissions from '../../../../Hooks/usePermissions';
// import Logged from '../../../../Hooks/Logged';
import { EyeOutlined, MinusOutlined, PlusOutlined, WarningOutlined, FilterOutlined } from '@ant-design/icons';
import Propiedades from './Propiedades';
import Decimal from 'decimal.js';

const { Text } = Typography;
const { Panel } = Collapse

/**
 *
 *
 * @export
 * @class Clientes
 * @extends {Component}
 * @description Componente del listado de transacciones
 */

function Item({ inmuebles, ...props }) {
	let renderUrl = (item) => {

		const urlParams = new URL(axios.defaults.baseURL + "/proyectos/logo/" + item?.proyecto_id)
		urlParams.searchParams.set("Authorization", sessionStorage.getItem("token"))

		return urlParams.href
	}

	console.log('inmuebles', inmuebles);

	return <span {...props}>
		<Avatar.Group >
			{inmuebles?.map((inmueble, index) => (
				<>

				{/* {console.log('inmueble?.proyecto?.color', inmueble?.proyecto?.color)} */}
				{console.log('inmueble?.proyecto?.color', inmueble?.proyecto?.color || inmueble?.color || '#1890ff')}

				<CustomAvatar
					// {...(() => {e => {
						
					// 	console.log('inmueble', inmueble)
					// 	return {}
					// }})()}
					image={renderUrl(inmueble)}
					name={inmueble?.nombre}
					title={inmueble?.nombre}
					color={inmueble?.proyecto?.color || inmueble?.color || '#1890ff'}
					// style={{ backgroundColor: (inmueble?.color !== undefined) ? inmueble.color : '#1890ff', marginRight: "2px", cursor: "pointer" }}
				/>
				</>
			))}
		</Avatar.Group>
	</span>
}

class Clientes extends React.Component {

	constructor(props) {
		super(props)

		// console.log("asda", this.props.searchParams.get("search"))
		// console.log("page", this.props.searchParams.get("page"))
		// console.log("limit", this.props.searchParams.get("limit"))
		this.state = {
			modalVisible: false,

			cliente_id: undefined,

			clientes: {
				data: [],

				page: this.props.searchParams.get("page") ?? 1,
				limit: this.props.searchParams.get("limit") ?? 100,
				filters: JSON.parse(this.props.searchParams.get("filters")) ?? [],
				total: 0,
				pages: 0,


			},

			minHeight: 0,
			loading: false,
		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getClientes();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.search !== this.props.search) {
			this.getClientes()
		}
	}

	/**
	 *
	 *
	 * @memberof Clientes
	 * 
	 * @method getClientes
	 * @description Obtiene los Clientes
	 */
	getClientes = ({
		page = this.state.clientes.page,
		limit = this.state.clientes.limit,
		search = this.props.search,
		filters = this.state.clientes.filters
	} = this.state.clientes) => {
		this.setState({ clientes: { ...this.state.clientes, page, limit }, loading: true })

		console.log("filters", filters)
		const params = { page, limit, }
		if (search) params.search = search
		if (filters && filters?.length > 0) params.filters = JSON.stringify(filters)
		this.props.setSearchParams(params)

		console.log("los filtros", filters)

		this.setState({ clientes: { ...this.state.clientes, page, limit, filters } })
		axios.get('/clientes', {
			params: { page, limit, search, filters: JSON.stringify(filters), inmuebles: true }
		}).then(({ data }) => {

			console.log("data", data)

			this.setState(state => {
				state.clientes.data = data.data
				state.clientes.total = data.total
				state.clientes.page = data.page
				return state;
			})
		}).catch(error => {
			console.log(error)
			message.error('Error al traer la información')
		}).finally(() => this.setState({ loading: false }))
	}

	render() {

		let pageLimit = (this.state.clientes.page * this.state.clientes.limit) - this.state.clientes.limit

		return (
			<>
				<PageHeader
					className="site-page-header custom-page-header"
					title="Clientes"
					extra={
						<Space size={20}>
							<Text className="contador">
								Visualizando de {(pageLimit > 0) ? pageLimit : 1} a {(this.state?.clientes?.page * this.state?.clientes?.limit)} de {this.state.clientes?.total?.toLocaleString()} clientes
							</Text>
							<Button
								type="dashed"
								onClick={() => this.setState({ visibleFilters: true })}
								className="button-plus"
								icon={<FilterOutlined />} />
						</Space>

					}
				/>
				<Content className="admin-content content-bg pd-1">
					<Row className='parent clientes' gutter={20} >
						<Col
							style={{ minHeight: this.state.minHeight }}
							className='custom-colum'
							span={24}
						>
							<List
								loading={this.state.loading}
								itemLayout="horizontal"
								dataSource={this.state.clientes.data}
								key={(item => item._id)}
								className='component-list w-100'
								locale={{ emptyText: "Sin Cliente" }}
								pagination={{
									current: this.state.clientes.page,
									pageSize: this.state.clientes.limit,
									total: this.state.clientes.total,
									showSizeChanger: true,
									// hideOnSinglePage: true,

									position: 'bottom',
									className: "flex-left",
									onChange: (page, limit) => this.getClientes({ page, limit })

								}}
								header={
									<Row className='header-list width-100' style={{ padding: "0 1rem 0 3rem " }} gutter={[16, 16]}>
										<Col xs={24} lg={8} >
											<Text strong>Nombre</Text>
										</Col>
										<Col xs={24} lg={5} >
											<Text strong>RFC</Text>
										</Col>
										<Col xs={24} lg={7} >
											<Text strong>Inmuebles</Text>
										</Col>
										<Col xs={24} lg={4} className='center'>

										</Col>
									</Row>
								}
								renderItem={(item, index) => (
									<Collapse
										bordered={false}
										expandIcon={({ isActive }) => isActive ? <MinusOutlined style={{ marginTop: 8, fontSize: 16 }} /> :
											<PlusOutlined style={{ marginTop: 8, fontSize: 16 }} />}
										className=""
									// activeKey={}
									>
										<Panel
											// collapsible={(!this.props.verPropiedades) ? "disabled": undefined}
											className="collapse-list"
											extra={
												<Row className='width-100' align='middle' gutter={[16, 16]}>
													<Col xs={24} lg={8}>
														<Space size={12}>
															<Text>
																{Decimal(this.state.clientes.page).sub(1).mul(this.state.clientes.limit).add(index + 1).toNumber()}
															</Text>
															<CustomAvatar

																height={42}
																width={107}
																placeholder={true}
																url={item.logo ? {
																	url: axios.defaults.baseURL + '/clientes/' + item._id,
																	logo: item.logo?.name
																} : null}
																name={`${item.nombre} ${item.apellido ?? ''}`}
															/>
															<Text ellipsis>{item.nombre} {item.apellido ? item.apellido : ''}</Text>
														</Space>
													</Col>
													<Col xs={24} lg={5} >
														<Text ellipsis>{item.rfc}</Text>
													</Col>
													<Col xs={24} lg={7} >
														<Dropdown overlay={
															<Menu>
																{item?.inmuebles.map(({ proyecto_id, nombre }) => <Menu.Item>
																	{proyecto_id ? <Link to={`/admin/proyectos/propiedades/${proyecto_id}`} >
																		{nombre}
																	</Link> : null}
																</Menu.Item>)}
															</Menu>
														}>
															<Item inmuebles={item?.inmuebles} />
														</Dropdown>
													</Col>
													<Col xs={24} lg={4} className='flex-right'>
														<Space>
															<Link title="Detalles" to={`/admin/crm/clientes/${item._id}`}>
																<Button
																	disabled={!this.props.puedeVerDetalles}
																	className="ant-btn-primary-lime" name={item.nombre} id={item._id}
																	title="Ver Detalles" icon={<EyeOutlined style={{ color: "#FFF" }} />}>
																</Button>
															</Link>


															<Button
																type="primary"
																disabled={!this.props.editClientes}
																icon={<IconEdit />}
																title="Editar"
																onClick={() => { this.setState({ modalVisible: true, cliente_id: item._id }) }}
															/>
															<Button
																type="primary"
																disabled={!this.props.deleteClientes}
																icon={<IconDelete />}
																title="Eliminar"
																onClick={() => {
																	Modal.confirm({
																		title: "¿Desea eliminar al Cliente?",
																		icon: <WarningOutlined />,
																		content: "Todo lo relacionado al cliente será eliminado.",
																		okText: "Eliminar",
																		okButtonProps: {
																			danger: true
																		},
																		cancelText: "Cancelar",
																		onOk: () => axios.delete(`/clientes`, {
																			params: { id: item._id }
																		}).then(() => {
																			this.getClientes()
																			message.success('Cliente eliminado')
																		}).catch(error => console.log(error))
																	})
																}}
																danger
															/>
														</Space>

													</Col>
												</Row>
											}
										>
											{this.props.verPropiedades ? <Propiedades
												cliente_id={item._id}
												{...this.props}
											/> : <Empty description="No tiene permiso para ver propiedades del cliente" />}

										</Panel>
									</Collapse>
								)}
							/>
						</Col>

					</Row>
					{this.props.createClientes ? <FloatingButton title="Nuevo registro" onClick={() => {
						this.setState({ modalVisible: true })
					}} /> : null}

				</Content>
				<FiltrosDrawer
					title="Filtrar Clientes"
					ref={ref => this.filtrosRef = ref}
					updateFilters={filters => {

						this.getClientes({
							page: 1,
							filters
						})
					}
					}
					onClose={() => this.setState({ visibleFilters: false })}
					visible={this.state.visibleFilters}
					cuentas={false}
					ordenes={false}
					ordenes_compras={false}
					area_rubro={false}
					empresas={false}
					proveedores={false}
					proyectos={false}
					inversiones={false}
					acreedores={false}
				/>
				<ModalClientes
					visible={this.state.modalVisible}
					onClose={(flag) => {
						this.setState({ modalVisible: false, cliente_id: undefined })
						this.getClientes()
					}}
					cliente_id={this.state.cliente_id}
				/>
			</>
		)
	}
}

export default function (props) {

	let user = React.useContext(Logged)

	let permissions = usePermissions(user?.rol_id?.permisos, {
		createClientes: ["clientes", "create"],
		editClientes: ["clientes", "edit"],
		deleteClientes: ["clientes", "delete"],

		verPropiedades: ["clientes", "propiedades", "read"],
		crearPropiedades: ["clientes", "propiedades", "create"],
		editarPropiedades: ["clientes", "propiedades", "edit"],
		eliminarPropiedades: ["clientes", "propiedades", "delete"],

		puedeVerDetalles: ["clientes", "propiedades", "access"],


	})

	let [searchParams, setSearchParams] = useSearchParams();

	return <Clientes
		{...props}
		{...permissions}

		searchParams={searchParams}
		setSearchParams={setSearchParams}

	/>

}