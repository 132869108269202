import React, { Component } from "react";
import { Layout, Row, Col, PageHeader, Tooltip, Button, List, Typography, Card, message, Spin, Popconfirm, Statistic, Checkbox, Modal, Space } from "antd";

import { Link } from 'react-router-dom';

//Componentes
import { IconDelete, IconCheckGreen } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
//Modales
import ModalFacturas from './ModalFacturas'
import ModalFacturasDetalle from './ModalFacturaDetalle'
import { useParams } from "react-router-dom";
import { ReloadOutlined } from "@ant-design/icons";

import SyncWidget from "@paybook/sync-widget";


window.SyncWidget = SyncWidget



const { Content } = Layout;
const { Text } = Typography;
const axios = require('axios')
const moment = require('moment')

/**
 *
 *
 * @export
 * @class FacturasSyncfy
 * @extends {Component}
 * @description Vista del listado de facturas
 */
class FacturasSyncfy extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            facturas: [],
            empresa: undefined,
            seleccionadas: {},

            page: 1,
            limit: 20,
            total: 0,
            id_site_organization_type: "56cf4f5b784806cf028b4569"
        }
    }

    componentDidMount() {
        this.setState({ empresa_id: this.props.params.empresa_id })

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getEmpresa();
    }

    componentWillUnmount(){
        window.syncWidget = undefined
    }


    /**
    *
    *
    * @memberof FacturasSyncfy
    * @description Obtiene todas todas las facturas
    * @param values pagina
    */
    getEmpresa = () => {
        this.setState({ loading: true })
        axios.get('/empresas/' + this.props.params.empresa_id, {
            params: {
                id: this.props.params.empresa_id
            }
        }).then(({ data }) => {
            this.setState({ empresa: data }, () => {
                this.getToken()
            })
        }).catch(error => {
            message.error('Error al cargar la empresa')
            this.setState({ loading: false })
        })

    }

    /**
    *
    *
    * @memberof FacturasSyncfy
    * @description Obtiene todas todas las facturas
    * @param values pagina
    */
    getToken = () => {
        axios.get('/syncfy/session', {
            params: {
                id_user_syncfy: this.state.empresa?.syncfy_usuario_id,
            }
        }).then(response => {
            sessionStorage.setItem('syncfy', response.data.token)
            axios.defaults.headers.common['syncfy'] = response.data.token
            this.setState({ token: response.data.token }, () => {
                this.getFacturas();
            })
        }).catch(error => {
            message.error('Error al obtener el token')
            this.setState({ loading: false })
        })

    }

    /**
    *
    *
    * @memberof FacturasSyncfy
    * @description Obtiene todas todas las facturas
    * @param values pagina
    */
    getFacturas = () => {
        this.setState({ loading: true })
        axios.get('/syncfy/facturas', {
            params: {
                empresa_id: this.props.params.empresa_id,
                token: this.state.token,
                page: this.state.page,
                limit: this.state.limit,
            }
        }).then(response => {
            this.setState({
                facturas: response.data.data.data,
                total: response.data.data.total,
            })
        }).catch(error => {
            message.error('Error al obtener las facturas (S)')
            this.setState({ loading: false })
        }).finally(() => this.setState({ loading: false }))

    }

    /**
    *
    * @memberof FacturasSyncfy
    * 
    * @method clasificarFacturas
    * @description Abre el modal si se seleccionaron facturas
    */
    clasificarFacturas = () => {
        const selecionadasLenght = Object.keys(this.state.seleccionadas).length
        if (selecionadasLenght === 0)
            return Modal.error({
                title: "Debe seleccionar algunas facturas",
                content: "Para poder agregar facturas "
            })
        this.setState({ modalFacturaVisible: true })
    }




    /**
     *
     *
     * @param {*} id_user_syncfy
     * @memberof EmpresaForm
     * 
     * @method renderWidget
     * @description Renderizamos el widget para declara las solicitudes.
     */
    renderWidget = (credential = this.state.empresa?.syncfy_credenciales_id) => {
        if (sessionStorage.getItem('syncfy') && sessionStorage.getItem('syncfy') !== null) {
            if (!window.syncWidget)
                window.syncWidget = new SyncWidget({
                    // Set up the token you created in the Quickstart:	
                    token: this.state.token,
                    config: {
                        // Set up the language to use:	
                        locale: 'es',
                        navigation: {
                            displayStatusInToast: true,
                            displaySiteOrganizationTypes: [this.state.id_site_organization_type]
                        },
                        entrypoint: {
                            credential
                        }
                    }
                })
            else {
                window.syncWidget.setConfig({
                    navigation: {
                        displayStatusInToast: true,
                        displaySiteOrganizationTypes: [this.state.id_site_organization_type]
                    },
                    entrypoint: {
                        credential: credential ? credential : undefined
                    }
                })
                window.syncWidget.setToken(this.state.token)
            }

            window.syncWidget.$on("error", () => {
                message.error("Hubo un error al actualizar")
            });
            window.syncWidget.$on("success", () => this.getFacturas());

            if (!credential)
                window.syncWidget.open()
            return
        }
        return Modal.error({
            title: "Debe seleccionar un Usuario de Syncfy para poder generar las credenciales."
        })
    }




    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Lista de Facturas"

                        breadcrumb={{
                            className: "custom-page-header-breadcrumb",
                            itemRender: (route, params, routes, paths) => (route.path && !(routes.indexOf(route) === routes.length - 1)) ? <Link to={route.path}>{route.breadcrumbName}</Link> : route.breadcrumbName,
                            routes: [
                                {
                                    path: "/admin/contabilidad",
                                    breadcrumbName: 'Contabilidad',
                                },
                                {
                                    breadcrumbName: 'Facturas Bancarias',
                                },
                                {
                                    breadcrumbName: this.state.empresa?.alias
                                }
                            ]
                        }}

                        extra={[
                            <Space>
                                <Button
                                    type="ghost"
                                    icon={<ReloadOutlined />}
                                    style={{ color: "white" }}
                                    onClick={() => this.renderWidget()}
                                >Sincronizar Credenciales</Button>
                            </Space>
                        ]}
                    />
                    {/* <PageHeader
                        className="site-page-header custom-page-header"
                        title="Facturas no clasificadas"
                    /> */}
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            dataSource={this.state.facturas}
                            header={<Row className="header-list width-100 pl-1 pr-1">

                                <Col span={2} className=" center">
                                    <Text strong></Text>
                                </Col>
                                <Col xs={12} className="center">
                                    <Text strong>Concepto</Text>
                                </Col>
                                <Col xs={5} className="center">
                                    <Text strong>Fecha</Text>
                                </Col>
                                <Col xs={4} className="center">
                                    <Text strong>Monto</Text>
                                </Col>
                                <Col xs={1} className="center">
                                    <Text strong></Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => {

                                console.log('item', item);
                                const { amount, dt_invoice,dt_transaction, description, factura, id_invoice, category, currency } = item;

                                return <List.Item className="component-list-item">
                                    <Tooltip trigger={(item.factura !== null) ? ["hover"] : []} title=" Esta Transacción se clasificó previamente" placement="topLeft">
                                        <Card
                                            onClick={(item.factura !== null) ? () => this.setState({ modalFacturaDetalleVisible: true, factura_id: item.factura?._id }) : null}
                                            className="card-list" style={{ ...(item.factura !== null) ? { background: "rgba(0,0,0,0.1)" } : {} }}>
                                            <Row className="width-100">
                                                <Col span={2} className="center">

                                                    <Checkbox className="radio-purple"
                                                        disabled={(item.factura !== null)}
                                                        checked={(item.factura == null) ? this.state.seleccionadas[id_invoice] : true}
                                                        onChange={value => {
                                                            this.setState(state => {
                                                                if (value.target.checked === true)
                                                                    state.seleccionadas[id_invoice] = item
                                                                else
                                                                    delete state.seleccionadas[id_invoice]
                                                                return state;
                                                            })
                                                        }}
                                                    ></Checkbox>

                                                </Col>
                                                <Col span={12} className="center">
                                                    <Text>{description}</Text>
                                                </Col>
                                                <Col span={5} className="center">
                                                    <Text strong>{moment.unix(dt_transaction).format('YYYY-MM-DD')}</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Statistic value={amount} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: (amount < 0) ? '#FF0000' : '#00FF19' }} prefix={'$'} suffix={currency} />
                                                </Col>
                                                <Col span={1} className="center">
                                                    {this.state.vista_guardadas ? <Popconfirm placement="topRight"
                                                        title="¿Eliminar de Guardados?" okText="Si, seguro" cancelText="No"
                                                        onConfirm={() => {
                                                            this.deleteGuardados(item._id)
                                                        }}>
                                                        <Button type="primary"
                                                            className="ant-btn button-survey btn-delete button-delete ant-btn-primary"
                                                            title="Eliminar">
                                                            <IconDelete />
                                                        </Button>
                                                    </Popconfirm> : null
                                                    }
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Tooltip>
                                </List.Item>
                            }}

                            pagination={{
                                onChange: (page, limit) => this.setState(state => {
                                    state.page = page;
                                    state.limit = limit;
                                    return state;
                                }, () => this.getFacturas()),

                                pageSizeOptions: [10, 20, 50, 100, 500],
                                showSizeChanger: true,

                                pageSize: this.state.limit,
                                total: this.state.total,
                                current: this.state.page,

                                hideOnSinglePage: false,
                                showQuickJumper: false,
                                className: "pagination-list",
                            }}

                        />

                        <FloatingButton title="Clasificar" icon={<IconCheckGreen />} onClick={() => this.clasificarFacturas()} />
                    </Content>
                </Spin>
                <ModalFacturasDetalle
                    visible={this.state.modalFacturaDetalleVisible}
                    onClose={() => this.setState({
                        modalFacturaDetalleVisible: false,
                        factura_id: null
                    })}
                    id={this.state.factura_id}
                />

                <ModalFacturas
                    clasificacion={true}
                    visible={this.state.modalFacturaVisible}
                    seleccionadas={this.state.seleccionadas}
                    onClose={() => {
                        this.setState({ modalFacturaVisible: false })
                        this.getFacturas()
                    }}
                    clearSeleccionadas={() => this.setState({ seleccionadas: {} })}
                    empresa_id={this.state.empresa_id}
                />

            </>
        )
    }
}
export default function () {
    return <FacturasSyncfy params={useParams()} />
}