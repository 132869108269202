import React, { Component } from "react";
import { Modal, Form, Input, message, Spin } from 'antd';
const axios = require('axios').default;


/**
 *
 *
 * @class ModalRubros
 * @extends {Component}
 */
class ModalRubros extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    ModalRubros = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.rubro_id !== undefined) {
            this.getRubro()
        }
    }


    /**
    * @memberof ModalRubros
    * @method getRubro
    * @description Obtiene un rubro de la DB
    */
    getRubro = () => {
        this.setState({ loading: true })

        axios.get(`/rubros/${this.props.rubro_id}`, {
            params: {
                id: this.props.rubro_id
            }
        }).then(({ data }) => {
            console.log('get rubro', data)
            let rubro = data.rubro
            this.setState({ rubro})
            this.ModalRubros.current.setFieldsValue({
                nombre: rubro.nombre,
                descripcion: rubro.descripcion,
            })

        }).catch(error => {
            message.error('Error al traer rubro')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalRubros
     * @method addRubro
     * @description Añade un rubro a la BD
     */
    addRubro = (values) => {
        axios.post('/rubros', {
            area_id: this.props.area_id,
            ...values
        }).then(response => {
            message.success('Rubro creado')
            this.props.onClose()

        }).catch(error => {
            console.log(error)
            message.error('Error al crear rubro.')
        })
    }

    /**
     * @memberof ModalRubros
     * @method updateRubro
     * @description Actualiza la información de un rubro
     */
    updateRubro = (values) => {
        this.setState({ loading: true })
        axios.put('/rubros', {
            ...values,
            id: this.props.rubro_id,
        }).then(response => {
            message.success('Rubro Actualizado')
            this.props.onClose()
        }).catch(error => {
            message.error('Error al actualizar rubro')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof ModalRubros
    * @method onFinish
    * @description Se ejecuta al dar enter al formulario
    */
    onFinish = (values) => (this.props.rubro_id) ? this.updateRubro(values) : this.addRubro(values)


    render() {
        return (
            <Form
                layout="vertical"
                name="form-rubros"
                ref={this.ModalRubros}
                onFinish={this.onFinish}
            >
                <Spin spinning={this.state.loading}>
                    <Form.Item
                        label="Nombre"
                        name="nombre"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el nombre"
                        }]}
                    >
                        <Input placeholder="Nombre" ></Input>
                    </Form.Item>
                    <Form.Item
                        label="Descripción"
                        name="descripcion"
                    // rules={[{
                    //     required: true,
                    //     message: "Por favor, ingrese una descipción"
                    // }]}
                    >
                        <Input placeholder="Descripción" ></Input>

                    </Form.Item>
                </Spin>
            </Form>
        )
    }
}



export default function Vista(props) {
    const { visible = false, onClose = () => { }, rubro_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={rubro_id ? "Editar Rubro" : "Crear Rubro"}
        closable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-rubros', key: 'submit', htmlType: 'submit' }}
    >
        <ModalRubros {...props} />
    </Modal>

}