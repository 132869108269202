import React, { Component } from "react";
import { Modal, Form, Input, message, Spin } from 'antd';
const axios = require('axios').default;


/**
 *
 *
 * @class ModalSubCategorias
 * @extends {Component}
 */
class ModalSubCategorias extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    ModalSubCategorias = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.subcategoria_id !== undefined) {
            this.getSubCategoria()
        }
    }


    /**
    * @memberof ModalSubCategorias
    * @method getSubCategoria
    * @description Obtiene una subcategoria de la DB
    */
    getSubCategoria = () => {
        this.setState({ loading: true })

        axios.get(`/sub-categorias/${this.props.subcategoria_id}`, {
        }).then(({ data }) => {
            
            this.setState({
                subcategoria: data
            })

            this.ModalSubCategorias.current.setFieldsValue({
                nombre: data.nombre,
            })

        }).catch(error => {
            message.error(error.response?.data?.message ?? 'Error al traer la subcategoría')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalSubCategorias
     * @method addSubCategoria
     * @description Añade una subcategoria a la BD
     */
    addSubCategoria = (values) => {
        axios.post('/sub-categorias', {
            categoria_id: this.props.categoria_id,
            ...values
        }).then(response => {
            message.success('Subcategoría creada')
            this.props.onClose()

        }).catch(error => {
            console.log(error)
            message.error(error.response?.data?.message ?? 'Error al crear la subcategoría')
        })
    }

    /**
     * @memberof ModalSubCategorias
     * @method updateSubCategoria
     * @description Actualiza la información de una subcategoria
     */
    updateSubCategoria = (values) => {
        this.setState({ loading: true })
        axios.put('/sub-categorias/'+this.props.subcategoria_id, {
            ...values,
            id: this.props.subcategoria_id
        }).then(response => {
            message.success('Subcategoría actualizada')
            this.props.onClose()
        }).catch(error => {
            message.error(error?.response?.data?.message ?? 'Error al actualizar subcategoría')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof ModalSubCategorias
    * @method onFinish
    * @description Se ejecuta al dar enter al formulario
    */
    onFinish = (values) => (this.props.subcategoria_id) ? this.updateSubCategoria(values) : this.addSubCategoria(values)


    render() {
        return (
            <Form
                layout="vertical"
                name="form-subcategoria"
                ref={this.ModalSubCategorias}
                onFinish={this.onFinish}
            >
                <Spin spinning={this.state.loading}>
                    <Form.Item
                        label="Nombre"
                        name="nombre"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el nombre"
                        }]}
                    >
                        <Input placeholder="Nombre" ></Input>
                    </Form.Item>
                </Spin>
            </Form>
        )
    }
}



export default function Vista(props) {
    const { visible = false, onClose = () => { }, subcategoria_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={subcategoria_id ? "Editar Subcategoría" : "Crear Subcategoría"}
        closable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-subcategoria', key: 'submit', htmlType: 'submit' }}
    >
        <ModalSubCategorias {...props} />
    </Modal>

}