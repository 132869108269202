import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Statistic, Tag, Spin, Divider } from 'antd';
//componentes
import { PaperClipOutlined } from "@ant-design/icons"
import CustomAvatar from "../../../Widgets/Avatar/Avatar";

const { Text } = Typography;
const axios = require('axios').default;
const moment = require('moment');



/**
 *
 *
 * @class ModalTransaccionesDetalle
 * @extends {Component}
 */
class ModalTransaccionesDetalle extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            transaccion: {
                _id: 0,
                concepto: '',
                proyecto_id: {
                    nombre: ''
                },
                cuenta_id: {
                    nombre: ''
                },
                cuenta_destino_id: {
                    nombre: ''
                },
                monto: 0,
                fecha: new Date(),

                tipo: 0,

                usuario_id: {
                    nombre: ''
                },
                area_id: {
                    nombre: ''
                },
                rubro_id: {
                    nombre: ''
                },
                descripcion: '',
                comprobantes: [],
                actualiza_saldo: false,
                descripcion: '',

                facturas: [{
                    factura_id: {

                    },
                    monto_abonado: 0
                }],

                conceptos: []
            },
        }

    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getTransaccion()
    }


    /**
    * @memberof ModalTran
    * @method getTransaccion
    * @description Obtiene la informacion de la transacciones para el modal de detalle. Actualiza el state
    * con informacion obtenida.
    */
    getTransaccion = () => {
        this.setState({ loading: true })
        axios.get(`/transacciones/${this.props.transaccion_id}`, {
            params: {
                id: this.props.id
            }
        }).then(({ data }) => {
            console.log("{data}", data);

            this.setState({ transaccion: data });
        })
            .catch(error => {
                console.log('error', error)
            })
            .finally(() => {
                this.setState({ loading: false })
            })

    }

    render() {
        const { transaccion } = this.state;
        return (
            <Spin spinning={false}>
                <Form layout="vertical"   >
                    <Divider orientation="left">Información General</Divider>
                    <Row justify="center">
                        <Col span={12} >
                            <Form.Item label="Concepto" >
                                <Text type="secondary" >{transaccion.concepto}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item label="Fecha" >
                                <Text type="secondary" >{moment(transaccion.fecha).format('YYYY-MM-DD')}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={24} >
                            <Form.Item label="Descripción" >
                                <Text type="secondary" >{transaccion.descripcion}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item label="Area" >
                                <Text type="secondary" >{transaccion.area_id?.nombre}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item label="Rubro" >
                                <Text type="secondary" >{transaccion.rubro_id?.nombre}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={24} >
                            <Form.Item label="Empresa" >
                                <Text type="secondary" >{transaccion.empresa_id?.alias}</Text>
                            </Form.Item>
                        </Col>


                    </Row>
                    <Divider orientation="left">Información Financiera</Divider>

                    <Row>
                        <Col span={12} >
                            <Form.Item label="Cuenta" >
                                {(transaccion.actualiza_saldo === true) ? <Tag color="#87d068" className="tag-actualiza-saldo" >Actualiza Saldo</Tag> : null}
                                <Text type="secondary" > <CustomAvatar name={transaccion?.cuenta_id?.nombre} /> {transaccion?.cuenta_id?.nombre}</Text>
                            </Form.Item>
                        </Col>

                        <Col span={12} >
                            <Form.Item label="Tipo" >
                                <Text type="secondary" >{
                                    (transaccion.tipo != 3) ? ((transaccion.tipo == 1) ? 'Ingreso' : 'Egreso') : 'Traspaso'}
                                </Text>
                            </Form.Item>
                        </Col>

                        <Col span={12} >
                            <Form.Item label="Monto" >
                                {(transaccion.tipo == 2) ?
                                    <Statistic
                                        value={transaccion.monto}
                                        valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#FF0000' }}
                                        prefix={'$'}
                                        suffix={'MXN'}
                                    /> :
                                    <Statistic
                                        value={transaccion.monto}
                                        valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#00FF19' }}
                                        prefix={'$'}
                                        suffix={'MXN'}
                                    />
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    {transaccion.conceptos.length > 0 ? <>
                        <Divider orientation="left"> Budgeting </Divider>
                        <Row>
                            {transaccion.conceptos.map(e=> <>
                                <Col span={6}>
                                    <Text ellipsis>{e.proyecto_id?.nombre}</Text>
                                </Col>
                                <Col span={6}>
                                    <Text ellipsis>{e.concepto_id?.nombre}</Text>
                                </Col>
                                <Col span={6}>
                                    <Text ellipsis>{e.subconcepto_id?.nombre}</Text>
                                </Col>
                                <Col span={6}>
                                    <Text ellipsis>$ {e.monto.toMoney(true)} MXN</Text>
                                </Col>
                            </>)}
                        </Row> 
                    </> : null }
                    <Divider orientation="left"> Comprobantes </Divider>
                    <Row>
                        <Col span={24} >
                            <Form.Item>
                                {transaccion.comprobantes?.map((comprobante, index) => {
                                    return <><a target="_blank" icon={<PaperClipOutlined />} 
                                        href={axios.defaults.baseURL + "/transacciones/" + this.props.transaccion_id + "?comprobante=" + comprobante.file +"&Authorization=" + sessionStorage.getItem("token")}
                                    >{comprobante.name}</a><br/></>
                                })}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Divider />
                        <Col span={12} >
                            <Form.Item label="Autor" name="usuario_id">
                                <div>
                                    <CustomAvatar name={`${transaccion.usuario_id?.nombre} ${transaccion.usuario_id?.apellidos}`} size="small" style={{ marginRight: '5px' }} />
                                    {`${transaccion.usuario_id?.nombre} ${transaccion.usuario_id?.apellidos}`}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item label="Editores" name="editado_por">
                                {transaccion.editado_por?.map(function ({ _id, nombre, apellidos }, index) {
                                    return <div>
                                        <CustomAvatar name={`${nombre} ${apellidos}`} size="small" style={{ marginRight: '5px' }} />
                                        {`${nombre} ${apellidos}`}
                                    </div>
                                })}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin >

        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props
    return <Modal
        visible={visible}
        onCancel={onClose}
        title="Detalle Transacción"
        closable={true}
        destroyOnClose={true}
        footer={<Button 
            onClick={onClose}
            type="primary"
        >
            Cerrar
        </Button>}
    >
        <ModalTransaccionesDetalle {...props} />
    </Modal>

}