import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'

const { Option } = Select 
const axios = require("axios");

/**
 * @const SelectPropiedad
 * @description Select para las propiedades registrados en el sistema
 */
const SelectPropiedad = (props) => {

    const { 
        value, 
        onChange, 
        placeholder = "Seleccione la propiedad", 
        onSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        bordered, 
        allowClear = true, 
        onClear = () => {},
        cliente_id
    } = props


    const [ propiedades, setPropiedades ] = useState({
        data: [],
        page: 1,
        limit: 100,
        total: 0,
        search: null,
    })


    /**
     * @const getPropiedades
     * @description Obitiene las propiedades
     */
    const getPropiedades = ({page, limit, search} = propiedades) => {

        axios.get('/propiedades', {
            params: {
                page,
                limit,
                search,
                cliente_id,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            console.log("data", data);
            setPropiedades(data)
        }).catch(error => {
            console.log("ERR, error",error)
            message.error(error?.response?.data?.message ?? 'Error al obtener las propiedades')
        })
    }

    //componentDidMount
    useEffect(() => {
        if(cliente_id)
            getPropiedades()
    }, [cliente_id])

    return (
        <Select
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getPropiedades({search})}
            onSelect={(propiedad)=> {
                onChange(propiedad.value)
                onSelect(propiedad.value)
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
        >
            {
                propiedades?.data?.map(({ _id, inmueble_id, monto_restante }) => <Option value={_id} >
                    {inmueble_id?.nombre ?? ""}
                </Option>)
            }
        </Select>
    );
}



export default SelectPropiedad;