import React, { Component } from 'react';
import { Button, Checkbox, Col, DatePicker, Dropdown, Spin, Menu, Radio, Row, Select, Card, Statistic, Typography, List, Avatar } from 'antd'

import ReactEcharts from "echarts-for-react";
import axios from 'axios';


const { Title, Paragraph, } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;


export default class GraficaNominasPorGrupos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            projects: [],
            // nomina_id: null
        }
    }

    /**
    * @memberof GraficaNominasPorGrupos
    *
    * @method   componentDidMount
    * @description  Recibe los datos por medio de props y los alamacena en el state
    *
    **/
    componentDidMount() {
        this.getProyectos()
    }


    /**
    * @memberof GraficaNominasPorGrupos
    *
    * @method   componentDidUpdate
    * @description Actualiza el state al recibir nuevos valores del props
    *
    **/
    componentDidUpdate() {
        if (this.state.update !== this.props.update) {
            this.state.update = this.props.update
            this.state.filters = this.props.filters
            this.getProyectos()
        }
    }

    /**
     *
     *
     * @param {*} [{ filters = this.state.filters }={}]
     * @memberof CardEgresosPorProyecto
     * 
     * @method getProyectos Obtenemos los proyectos.
     */
    getProyectos = ({
        filters = this.state.filters
    } = {}) => {
        this.setState({
            loading: true
        })
        axios.post('/dashboard/grafico', filters)
            .then(({ data }) => {
                this.setState({
                    projects: data.data,
                    loading: false
                })
            })
    }


    render() {

        var dataAxis = this.props.acreedores_nombre ? this.props.acreedores_nombre: [] ;
        var data = this.props.acreedores_monto ? this.props.acreedores_monto: [];
        var yMax = 500;
        var dataShadow = [];

        if(data?.length > 0){
            for (var i = 0; i < data.length; i++) {
                dataShadow.push(yMax);
            }

        }

        return (
            <Spin spinning={this.state.loading}>
                <ReactEcharts
                    option={
                        {
                            title: {
                                text: 'Deuda',
                            },
                            tooltip: {
                                trigger: "axis",
                                axisPointer: {
                                  type: "shadow"
                                },
                               
                            },
                            xAxis: {
                                data: dataAxis,
                                axisLabel: {
                                    inside: false,
                                    textStyle: {
                                        color: '#000'
                                    },
                                },
                                axisTick: {
                                    show: false
                                },
                                axisLine: {
                                    show: false
                                },
                                z: 10
                            },
                            yAxis: {
                                axisLine: {
                                    show: false
                                },
                                axisTick: {
                                    show: false
                                },
                                axisLabel: {
                                    textStyle: {
                                        color: '#999'
                                    }
                                }
                            },
                            dataZoom: [
                                {
                                    type: 'inside'
                                }
                            ],
                            series: [
                                {
                                    type: 'bar',
                                    showBackground: true,
                                    itemStyle: {
                                        color: '#FF0000'
                                    },
                                    emphasis: {
                                        itemStyle: {
                                            color: '#FF0000'
                                        }
                                    },
                                    data: data
                                }
                            ]
                        }
                    }
                    style={{ height: '400px', width: '100%' }}
                    opts={{ renderer: 'svg' }}
                    className='react_for_echarts' />
            </Spin>
        )
    }
}


