import { Col, Row } from 'antd'
import Title from 'antd/lib/typography/Title'
import React from 'react'
import { Link } from 'react-router-dom'
import '../../Styles/Global/navbar.css'

/**
 *
 * Navbar
 * @param {*} props
 * @description Navbar for Landing Page 
 */
function PublicNavbar(props) {

  return(
    <Row className='public-navbar'>
      <Col span={12} className='container-logo'>
        <img src="/img/logo_white.png" alt="Logo" className='logo pd-1' />
      </Col>
      <Col span={12} className='container-btn-login'>
        <Link style={{ float: 'right' }} to='/login'>
          <Title level={3} className='text-login'>LOGIN</Title>
        </Link>
      </Col>
    </Row>
  )
}

export { PublicNavbar }