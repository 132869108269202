import React, { Component, useContext } from 'react';
import { Avatar, Spin, PageHeader, Tooltip, Layout, message, Row, Col, Card, Typography, Tabs, Menu, Space, Button, Result, Popconfirm, Tag, Empty, List } from 'antd'
import axios from 'axios';

import { FaRegHandshake } from 'react-icons/fa'
import { useParams, useNavigate, useLocation, matchRoutes } from "react-router-dom";


//Componentes
import Logged from '../../../../Hooks/Logged';
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import usePermissions from '../../../../Hooks/usePermissions';
import { IconEdit, IconCheck, IconDelete } from '../../../Widgets/Iconos';
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";

import ModalVacaciones from './ModalVacaciones';

//Modales






//modal


const { Content } = Layout;
const { Text, Title, Paragraph } = Typography;
const { TabPane } = Tabs;


const moment = require('moment');
moment.locale('es');

/**
 * @export
 * @class DetalleClientes
 * @extends {Component}
 * @description Vista de Detalle Clientes
 */
class InformacionGeneral extends Component {

    constructor(props) {
        super(props)
        this.state = {
            empleado: {},
            vacaciones: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

            },
        }
    }



    componentDidMount() {
        this.get()
        this.getVacaciones()
    }



    /**
     * @memberof DetallesClientes
     * @method get
     * @description Obtiene los detalles y propiedades del cliente
     *
     */
    get = () => {
        this.setState({ loading: true })
        axios.get('/empleados/' + this.props.id)
            .then(({ data }) => {
                this.setState({ empleado: data })
            }).catch(error => {
                message.error('Error al obtener el empleado')
            }).finally(() => this.setState({ loading: false }))
    }

    /**
     *
     *
     * @memberof empleados
     * 
     * @method getempleados
     * @description Obtiene los empleados
     */
    getVacaciones = ({
        page = this.state.vacaciones.page,
        limit = this.state.vacaciones.limit,
        search = this.props.search,
    } = this.state.vacaciones
    ) => {
        this.setState({ vacaciones: { ...this.state.vacaciones, page, limit, search } })
        axios.get('/vacaciones', {
            params: { page, limit, search, empleado_id: this.props.id }
        }).then(({ data }) => {
            console.log("data vacaciones", data)

            this.setState(state => {
                state.vacaciones.data = data.data
                state.vacaciones.total = data.total
                state.vacaciones.page = data.page
                return state;
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => this.setState({ loading: false }))
    }


    render() {
        const { empleado, cliente } = this.state


        return (
            <>
                <Spin spinning={false}>


                    <Col span={24}>
                        <Card bordered>
                            <Row align="middle" className='mb-1' gutter={[12, 12]}>
                                <Col>
                                    <Title level={4} >Vacaciones</Title>
                                </Col>
                            </Row>



                            <Col xs={24} lg={24}>
                                <Row>
                                    {
                                        empleado?.vacaciones_dias_permitidos ?
                                            <Col xs={24} lg={12} xl={8}>
                                                <Paragraph>Dias Permitidos</Paragraph>
                                                <Paragraph strong style={{ fontSize: 16 }}>{empleado?.vacaciones_dias_permitidos}</Paragraph>
                                            </Col> : null
                                    }

                                    {
                                        empleado?.vacaciones_dias_tomados ?
                                            <Col xs={24} lg={12} xl={8}>
                                                <Paragraph>Dias Tomados</Paragraph>
                                                <Paragraph strong style={{ fontSize: 16 }}>{empleado?.vacaciones_dias_tomados}</Paragraph>
                                            </Col> : null
                                    }
                                    {
                                        empleado?.vacaciones_dias_disponibles ?
                                            <Col xs={24} lg={12} xl={8}>
                                                <Paragraph>Dias Disponibles</Paragraph>
                                                <Paragraph strong style={{ fontSize: 16 }}>{empleado?.vacaciones_dias_disponibles}</Paragraph>
                                            </Col> : null
                                    }


                                </Row>
                            </Col>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Content className="admin-content content-bg pd-1">
                            {console.log(this.state.vacaciones.data)}
                            <List
                                loading={this.state.loading}
                                className="component-list"
                                itemLayout="horizontal"
                                locale={{ emptyText: "Sin Vacaciones Solicitadas" }}
                                dataSource={this.state.vacaciones.data}
                                pagination={{
                                    current: this.state.vacaciones.page,
                                    pageSize: this.state.vacaciones.limit,
                                    total: this.state.vacaciones.total,
                                    hideOnSinglePage: false,
                                    position: 'bottom',
                                    onChange: (page, limit) => console.log({ page, limit })
                                }}
                                header={<Row className="header-list width-100 pr-1 pl-1" >
                                    <Col span={6} >
                                        <Text strong>Fecha</Text>
                                    </Col>

                                    <Col span={6} className="center">
                                        <Text strong>Nombre</Text>
                                    </Col>
                                    <Col span={6} className="center">
                                        <Text strong>Días</Text>
                                    </Col>
                                    <Col span={6} className="center">
                                        <Text strong>Autoriza</Text>
                                    </Col>

                                </Row>
                                }

                                renderItem={item => (
                                    <List.Item className="component-list-item">

                                        <Card className="card-list">
                                            <Row className="width-100 ">

                                                <Col span={6} >
                                                    <Text>{moment(item.fecha_inicio).format('YYYY-MM-DD')}</Text>
                                                </Col>

                                                <Col span={6} className="center">
                                                    <Text className="text-gray">{empleado.nombre}</Text>
                                                </Col>
                                                <Col span={6} className="center">
                                                    <Text className="text-gray">{item?.dias}</Text>
                                                </Col>
                                                <Col span={6} className="center">
                                                    {item.estatus === 1 ? <Text>{item.autoriza?.nombre}</Text> :
                                                        <Space>
                                                            <Button type="primary" icon={<IconEdit />} onClick={() => this.setState({ modalVacaciones: true, vacaciones_id: item._id })} />
                                                            <Popconfirm
                                                                placement="topRight"

                                                                title="¿Deseas autorizar esta solicitud?"
                                                                onConfirm={() => axios.put('/vacaciones/autorizar', {
                                                                   
                                                                        id: item._id
                                                                    
                                                                }).then((response) => {
                                                                    message.success(response?.data?.message)
                                                                    this.getVacaciones()
                                                                    this.get()
                                                                })
                                                                    .catch((error) => {
                                                                        message.error(error?.response?.data?.message);

                                                                    })
                                                                }
                                                                okText="Si"
                                                                cancelText="No"
                                                            >
                                                                <Button type="normal" title="Autorizar" icon={<IconCheck />} />
                                                            </Popconfirm>

                                                            <Popconfirm
                                                                placement="topRight"

                                                                title="¿Deseas eliminar esta solicitud?"
                                                                onConfirm={() => axios.delete('/vacaciones', {
                                                                    params: {
                                                                        id: item._id
                                                                    }
                                                                }).then((response) => {
                                                                    message.success(response?.data?.message)
                                                                    this.getVacaciones()
                                                                })
                                                                    .catch((error) => {
                                                                        message.error(error?.response?.data?.message);

                                                                    })
                                                                }
                                                                okText="Si"
                                                                cancelText="No"
                                                            >
                                                                <Button type="normal" danger title="Eliminar" icon={<IconDelete />} />
                                                            </Popconfirm>
                                                        </Space>}
                                                </Col>

                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </Content>

                        {true ? <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalVacaciones: true })} /> : null}
                    </Col>

                </Spin>
                <ModalVacaciones
                    visible={this.state.modalVacaciones}
                    empleado_id={this.props.id}
                    vacaciones_id={this.state.vacaciones_id}
                    onClose={() => {
                        this.setState({ modalVacaciones: false, vacaciones_id: undefined })
                        this.getVacaciones()
                        this.get()
                    }} />
            </>
        )
    }
}

export default function (props) {


    const user = useContext(Logged);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        crearPropiedades: ["clientes", "propiedades", "create"],

        verTransaccionesPropiedad: ["clientes", "propiedades", "transacciones", "access"],
        crearTransaccionesPropiedad: ["clientes", "propiedades", "transacciones", "create"],

        verTransaccionesProgramadasPropiedad: ["clientes", "propiedades", "transacciones-programadas", "access"],
        crearTransaccionesProgramadasPropiedad: ["clientes", "propiedades", "transacciones-programadas", "create"],
    });

    return <InformacionGeneral

        {...permisos}
        {...props}
    />
}