import React, { Component } from "react";
import { SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons";

export default function Sort(props) {

    const { sort, children } = props

    let sortIcon;
    switch (sort) {
        case 1:
            sortIcon = <SortAscendingOutlined style={{ color: "currentcolor" }} />
            break;

        case -1:
            sortIcon = <SortDescendingOutlined style={{ color: "currentcolor" }} />
            break;

        default:
            sortIcon = null;
            break;
    }
    return <span {...props} style={{...props?.style, cursor: "pointer", userSelect: "none"}} >
        {children} {sortIcon}
    </span>
}
