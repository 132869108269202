import React, { Component } from 'react'
import { Row, Col, Form, Input, Button, Modal, Spin, Card, Typography} from 'antd';
import { Navigate, useParams, useNavigate } from "react-router-dom";
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';


const axios = require("axios").default;
const { Title } = Typography

/**
 *
 *
 * @class UpdatePassword
 * @extends {Component}
 */
class UpdatePassword extends Component {



    /**
     *Creates an instance of UpdatePassword.
     * @param {*} props
     * @memberof UpdatePassword
     */
    constructor(props) {
        super(props);
        this.state = {
            log: false,
            isAdmin: false,
            isLogged: false,

            isRecovery: false,
            isRecoveryLoading: false,
            redirect: false,
            form: {},
            loading: false
        }
    }

    recoveryPassword = React.createRef();

    redirectTo = "/"


    /**
     *
     *
     * @memberof UpdatePassword
     */
    async componentDidMount(){

        console.log("this.props", this.props.params);

        window.a = this
        const { token } = this.props.params;
        if (token === undefined) {
            this.redirectTo = "/";
            this.setState({ redirect: true })
        } else {
            this.recoveryPassword.current.setFieldsValue({ token });
        }
    }


    /**
     *
     *
     * @memberof UpdatePassword
     */
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Navigate to={this.redirectTo} replace />;
        }
    };


    /**
     *
     *
     * @memberof UpdatePassword
     */
    onFinish = async values => {

        console.log("values", values)
        this.setState({ loading: true })
        axios.put('/password/recovery/update', values)
            .then(() => {
                this.to = '/login';
                this.setState({
                    redirect: true
                })

            })
            .catch((error) => {
                console.log(error.response)
                Modal.error({
                    title: "No fue posible actualizar.",
                    content: "El token es invalido o el correo no existe."
                })
            })
            .finally(() => this.setState({
                loading: false
            }))
    };


    render() {
        return (
            <Spin spinning={this.state.loading}>
                {this.renderRedirect()}
                <div style={{ minHeight: '100vh', backgroundImage: 'url("/img/background/bg-landing.png")', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} className="center pd-1">
                        <Card>
                            <img src={'/img/logo-black.png'} alt="logo" height="120"/>
                            <Title level={3} className="mt-2 mb-2">Nueva Contraseña</Title>
                            <Form 
                                name="basic" 
                                layout={"vertical"} 
                                onFinish={this.onFinish} 
                                initialValues={this.state.form} 
                                ref={this.recoveryPassword}
                            >
                                <Form.Item name="token" noStyle >
                                    <Input type="hidden" />
                                </Form.Item>
                                <Form.Item
                                    label="Correo Electrónico"
                                    name="email"
                                    rules={[{
                                        required: true,
                                        message: 'Para poder iniciar sesión, debe haber ingresado su correo electrónico'
                                    }]}
                                >
                                    <Input className="input-login" />
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    label="Contraseña"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor, ingrese la contraseña.',
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password
                                        className='input-login'
										placeholder="********"
										iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
									/>
                                </Form.Item>

                                <Form.Item
                                    name="confirm"
                                    label="Confirmar Contraseña"
                                    dependencies={['password']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor, confirme su contraseña!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('Las contraseñas no coinciden.');
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password
                                        className='input-login'
										placeholder="********"
										iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
									/>
                                </Form.Item>

                                <Form.Item >
                                    <Button type="primary" htmlType="submit" block size="large" className="button-login">
                                        Cambiar Contraseña
                                    </Button>
                                </Form.Item>
                            </Form>  
                        </Card>              
                </div >
            </Spin>
        )
    }
}



export default function Vista(props) {
    console.log("UdatePassword", props)
    return <UpdatePassword {...props} params={useParams()} navigate={useNavigate()} />

}