import React, { Component } from 'react';
import { Button, Col, Row, Spin, Tag, PageHeader, Layout, Space, message, Typography, Statistic, Card, List } from 'antd'
import { MoreOutlined, FileSearchOutlined } from "@ant-design/icons";

import CustomAvatar from '../../Widgets/Avatar/Avatar';
import Carousel from 'react-multi-carousel';
import axios from 'axios'
import { IconCheckMedal } from '../../Widgets/Iconos';
import { CardSaldo, CardCuenta } from "../../Widgets/Cards"
import { color_transaccion_tipo, renderIconTransaccion } from "../../Utils"
import MultiProgress from '../../Widgets/MultiProgress';

import 'react-multi-carousel/lib/styles.css';
import { useNavigate } from 'react-router-dom';
import ModalTransaccionProgramadaDetalle from '../Finanzas/Transacciones/ModalTransaccionProgramadaDetalle';
import usePermissions from '../../../Hooks/usePermissions';
import User from '../../../Hooks/Logged';
import NotAllow from '../NotAllow';

const { Content, } = Layout;
const { Text, Title } = Typography;

const moment = require('moment');
moment.locale('es');


const responsive = {
    desktop_fhq: {
        breakpoint: { max: 4000, min: 1200 },
        items: 2
    },
    desktop_hq: {
        breakpoint: { max: 1500, min: 1200 },
        items: 1
    },
    desktop_xxl: {
        breakpoint: { max: 1200, min: 992 },
        items: 1
    },
    desktop_xl: {
        breakpoint: { max: 992, min: 768 },
        items: 1
    },
    desktop_lg: {
        breakpoint: { max: 768, min: 576 },
        items: 1
    },
    desktop_md: {
        breakpoint: { max: 576, min: 464 },
        items: 1
    },
    desktop_sm: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    },

    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};


/**
 * 
 * @export
 * @class Cuentas
 * @extends {Component}
 * @description Componente de la vista de cuentas de transacciones programadas
 */
class Cuentas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            cuentas: {
                data: [],
                limit: 20,
                page: 1,
                total: 0,
                pages: 0,
                loading: false,
            },
            transacciones: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
                loading: false,
            },
            modal_visible: false,
            transaccion_id: undefined
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getCuentas();
        this.getTransacciones()
    }

    /**
     *
     *
     * @memberof Transacciones
     * 
     * @method getTransacciones
     * @description Obitiene las Transacciones
     */
    getTransacciones = ({
        page = this.state.transacciones.page,
        limit = this.state.transacciones.limit,
        search = this.props.search,

    } = this.state.transacciones) => {
        this.setState({ loading: true })
		axios.get('/transacciones-programadas', {
			params: {
				page,
				limit,
                search,
                cuentas: true
			}
		})
			.then(({ data }) => {
				console.log("data", data);
				this.setState({ transacciones: { ...this.state.transacciones, ...data } });
			})
			.catch(error => {
				message.error('Error al traer los transacciones programadas')
				console.log(error.response)
			}
			).finally(() => this.setState({ loading: false }))
    }

    /**
     *
     *
     * @memberof Transacciones
     * 
     * @method getCuentas
     * @description Obtiene las Cuentas 
     */
    getCuentas = () => {
        axios.get('/cuentas', {
            params: {
                limit: 20,
                page: 1
            }
        }).then(({ data }) => {
            this.setState({ cuentas: { ...this.state.cuentas, ...data, }, saldo: data.saldo, total: data.total })

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información de las cuentas')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     *
     * @memberof Cuentas
     * @description Renderiza el monto con el color correspondiente segun el tipo de transaccion
     */
    renderMonto = (tipo, monto) => {
        let colors = ['#000000', '#00FF19', '#FF0000'];
        return <Statistic value={monto} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: colors[tipo] }} prefix={'$'} />
    }

    render() {
        const { cuentas, total, saldo } = this.state
        return (
            <>
                <PageHeader
                    className='custom-page-header'
                    title="Cuentas de Cash Flow"
                />
                <Content className='admin-content content-bg pd-1'>
                    <Space gutter={[16, 16]} direction="vertical" className="width-100">
                        <Row align="middle" >
                            <Col xs={24} sm={24} md={12} lg={10} xl={7} xxl={7}>
                                <CardSaldo saldo={saldo} cuentas={total} />
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={14} xl={17} xxl={17}>
                                <Carousel
                                    responsive={responsive}
                                    className='carousel-cards'
                                >
                                    {cuentas.data.map(cuenta => {
                                        return <CardCuenta cuenta={cuenta} />
                                    })}
                                </Carousel>
                            </Col>


                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col xs={24}>
                                <List
                                    loading={this.state.loading}
                                    className="component-list width-100 "
                                    itemLayout="horizontal"
                                    locale={{ emptyText: "Sin Transacciones" }}
                                    dataSource={this.state.transacciones?.data}
                                    pagination={{
                                        current: this.state.transacciones.page,
                                        pageSize: this.state.transacciones.limit,
                                        total: this.state.transacciones.total,
                                        position: 'bottom',
                                        className: "flex-right",
                                        showSizeChanger: true,
                                        onChange: (page, limit) => this.getTransacciones({ page, limit })
                                    }}
                                    header={<Row className="header-list width-100 ">
                                        <Col xs={3} className=" center">
                                            <Text strong >FECHA</Text>
                                        </Col>
                                        <Col xs={4} className="center">
                                            <Text strong >CONCEPTO</Text>
                                        </Col>
                                        <Col xs={3} className="center">
                                            <Text strong >ÁREA/RUBRO</Text>
                                        </Col>
                                        <Col xs={3} className="center">
                                            <Text strong >PROYECTO</Text>
                                        </Col>
                                        <Col xs={3} className=" center">
                                            <Text strong >CUENTA</Text>
                                        </Col>
                                        <Col xs={3} className=" center">
                                            <Text strong >AVANCES</Text>
                                        </Col>
                                        <Col xs={3} className="center">
                                            <Text strong >MONTO</Text>
                                        </Col>
                                        <Col span={2}></Col>
                                    </Row>
                                    }
                                    renderItem={item => (
                                        <List.Item className="component-list-item ">
                                            <Card className="card-list" bordered={false}>
                                                <Row className="width-100" >
                                                    <Col xs={3} className="center">
                                                        <Tag className='tag-large' color={color_transaccion_tipo(item.tipo)} >
                                                            {moment(item.fecha).format('YYYY-MM-DD')}
                                                        </Tag>
                                                    </Col>
                                                    <Col xs={4} className="center">
                                                        <Text ellipsis className="text-gray-dark">{item.concepto}</Text>
                                                    </Col>
                                                    <Col xs={3} className="center">
                                                        <Space direction='vertical' size='small' className="space-small-vertical">
                                                            <Text ellipsis className="text-gray-dark">{item.area_id?.nombre ? item.area_id?.nombre : 'No Asignado'}</Text>
                                                            <Text ellipsis className="text-gray-dark">{item.rubro_id?.nombre ? item.rubro_id?.nombre : 'No Asignado'}</Text>
                                                            
                                                        </Space>
                                                    </Col>
                                                    <Col xs={3} className="center">
                                                        <Space direction='horizontal' align='center' size={0} >
                                                            <CustomAvatar
                                                                image={`${axios.defaults.baseURL}/upload/${item.proyecto_id?.logo}`}
                                                                color={item.proyecto_id?.color}
                                                                name={item.proyecto_id?.nombre}
                                                            />
                                                            <Text ellipsis className="text-gray-dark">{item.proyecto_id.nombre ? item.proyecto_id.nombre : 'No Asignado'}</Text>

                                                        </Space>
                                                    </Col>
                                                    <Col xs={3} className="center">
                                                        <Space direction='horizontal' align='center' size={0} >
                                                            {/* <CustomAvatar
                                                                image={`${axios.defaults.baseURL}/upload/${item.cuenta_id?.logo}`}
                                                                color={item.cuenta_id?.color}
                                                                name={item.cuenta_id?.nombre}
                                                            />
                                                            <Text ellipsis className="text-gray-dark">{item.cuenta_id.nombre ? item.cuenta_id.nombre : 'No Asignado'}</Text> */}

                                                        </Space>
                                                    </Col>
                                                    <Col xs={3} className="center">
                                                        <MultiProgress
                                                            max={item.monto}
                                                            elements={[
                                                                {
                                                                    value: item.monto_pagado
                                                                },
                                                                {
                                                                    value: item.monto_pagar
                                                                },
                                                                {
                                                                    value: item.monto_pendiente
                                                                }
                                                            ]}
                                                        />
                                                    </Col>
                                                    <Col xs={3} className="center">
                                                        <Text className="text-gray-dark" strong>
                                                            $ {item.monto.toMoney(true)} <small>MXN</small></Text>
                                                    </Col>
                                                    <Col span={2} className="center">
                                                        <Space wrap>
                                                            <IconCheckMedal />
                                                            <Button
                                                                icon={<FileSearchOutlined style={{ color: "currentcolor" }} />}
                                                                type="secondary"
                                                                title="Ver Detalle"
                                                                onClick={() => this.setState({ modal_visible: true, transaccion_id: item._id })}>
                                                            </Button>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </List.Item>
                                    )}
                                />
                            </Col>
                        </Row>
                    </Space>
                </Content>
                <ModalTransaccionProgramadaDetalle
                    visible = {this.state.modal_visible}
                    transaccion_programada_id = {this.state.transaccion_id}
                    onClose={() => {
                        this.setState({ modal_visible: false, transaccion_id: undefined })
                    }}
                />
            </>
        )
    }
}

export default function (props) {

    let user = React.useContext(User);
    let permissions = usePermissions(user?.rol_id?.permisos, {
        access: ["cash-flow", "cuentas"],
    })

    return <Cuentas {...props} navigate={useNavigate()} />

}