import React from 'react'
import { Route, Routes } from "react-router-dom";

import Almacenes from '../components/Admin/Almacenes/Almacenes'
import DetalleAlmacen from '../components/Admin/Almacenes/DetalleAlmacen'



/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterAreas(props) {
    return (
        <Routes>
            <Route path="" element={<Almacenes {...props} />} />
            <Route path=":almacen_id" element={<DetalleAlmacen  {...props}  />} />
        </Routes>
    )
}

export default RouterAreas;
