import React from 'react'
import { Route, Routes } from "react-router-dom";

import Categorias from '../components/Admin/Categorias/Categorias';
import SubCategorias from '../components/Admin/SubCategorias/SubCategorias';

/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterCategorias(props) {
    return (
        <Routes>
            <Route path="" element={<Categorias {...props} />} />
            <Route path="subcategorias/:categoria_id" element={<SubCategorias  {...props}  />} />
            
        </Routes>
    )
}

export default RouterCategorias;
