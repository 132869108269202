import React, { Component } from 'react';
import { Row, Col, Card, Button, Menu, Checkbox, PageHeader, Dropdown, Statistic } from 'antd'
import { Link } from 'react-router-dom';

//componentes

import { IconArrow as Arrow, IconPlus, IconBars, IconDownloadCustom as Download, IconDeudas } from '../../Widgets/Iconos';


import CardInfoDashboard from './Cards/InfoDashboard';


import DeudasChart from './Widgets/DeudasChart';
import InversionesChart from './Widgets/InversionesChart';

//css
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import "./HealthFinance.css";
import { Content } from 'antd/lib/layout/layout';




const axios = require('axios').default


axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');


//Variables
export default class Dashboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            projects: []
        }
    }

    componentDidMount() {
        this.getInfo()
    }

    getInfo = () => {
        axios.get('/inversiones/dashboard', {})
            .then(response => {
                console.log(response.data.data)
                this.setState({
                    inversiones_total: response.data.data.inversiones_total.total_inversion,
                    inversiones_nombre: response.data.data.inversiones_total.inversiones?.map(inv => inv.nombre),
                    inversiones_monto: response.data.data.inversiones_total.inversiones?.map(inv => inv.monto),
                    acreedores_nombre: response.data.data.acreedores_total.acreedores.map(ac => ac.nombre),
                    acreedores_monto: response.data.data.acreedores_total.acreedores.map(ac => ac.monto),
                    acreedores_total: response.data.data.acreedores_total.total_inversion,

                })

            }).catch(error => {
                console.log(error)
            })
    }


    render() {
        return (
            <div className="financial-health">

                <PageHeader
                    className="site-page-header custom-page-header"
                    title="Financial Health"
                    extra={[


                        <Col className="row-filter" xl={10} lg={10} md={11} sm={12} xs={12} >
                            <Dropdown
                                className="filter-element"
                                overlay={
                                    <Menu>
                                        {this.state.projects.map(({ _id, nombre, logo, color }, index) => (
                                            <Menu.Item key={index}>
                                                <Checkbox>
                                                    <CustomAvatar
                                                        image={logo}
                                                        name={nombre}
                                                        color={color}
                                                        size="small"
                                                        style={{
                                                            margin: '0 5px'
                                                        }}
                                                    />
                                                    {nombre}
                                                </Checkbox>
                                            </Menu.Item>
                                        ))}

                                    </Menu>
                                }>
                                <p>Proyectos <Arrow className="arrow" /></p>
                            </Dropdown>
                        </Col>

                        ,
                        <Col className="row-filter" span={1}>
                            <Button disabled={!this.state.datosConsultados} style={{
                                background: "transparent", border: "none"
                            }} title="Reporte">
                                <Download />

                            </Button>
                        </Col>

                    ]}
                />




                <Content className="admin-content content-bg pd-1">

                    <Row gutter={12}>
                        <Col xs={24} lg={12} xl={8}>
                            <Link to={'/admin/financial-health/inversiones'}>
                                <CardInfoDashboard
                                    text="card-info-dsh-fh"
                                    title='Inversiones Totales'
                                    textAlign={false}
                                    hover={true}
                                >
                                    <Row style={{ width: '100%' }}>
                                        <Col span={16} >
                                            <Statistic prefix={'$'} value={this.state.inversiones_total} precision={2} suffix={<small>MXN</small>} className="statistic-financial" />
                                        </Col>
                                        <Col span={8}>
                                            <IconBars style={{ maxWidth: '100%' }} />
                                        </Col>

                                    </Row>
                                </CardInfoDashboard>
                            </Link>
                        </Col>
                        <Col xs={24} lg={12} xl={8}>
                            <Link to={'/admin/acreedores'}>
                                <CardInfoDashboard
                                    text="card-info-dsh-fh"
                                    title='Deudas Totales'
                                    textAlign={false}
                                    hover={true}
                                >
                                    <Row style={{ width: '100%' }}>
                                        <Col span={16} >
                                            <Statistic prefix={'$'} value={this.state.acreedores_total} precision={2} suffix={<small>MXN</small>} className="statistic-financial" />
                                        </Col>
                                        <Col span={8}>
                                            <IconDeudas style={{ maxwidth: '100%', width: '100%', height: '180px' }} />
                                        </Col>

                                    </Row>
                                </CardInfoDashboard>
                            </Link>
                        </Col>
                        <Col xs={24} lg={24} xl={8}>
                            <CardInfoDashboard
                                title={<Row style={{ width: '100%', marginTop: '0.5em' }} gutter={[20, 20]}>
                                    <Col span={15}>
                                        New Worth
                                    </Col>
                                    <Col span={9}>
                                        <img src="/img/logo_white.png" style={{ width: '100%' }} />
                                    </Col>
                                </Row>}
                                background='linear-gradient(89.47deg, #3988D0 0.39%, #0066FF 126.36%)'
                                textAlign={false}
                                style={{ height: '100%' }}
                            >
                                <Statistic prefix={'$'} value={this.state.inversiones_total - this.state.acreedores_total} precision={2} suffix={<small>MXN</small>} className="statistic-financial net-worth" />
                            </CardInfoDashboard>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col span={24}>
                            <Card className="info-card-fh">
                                <InversionesChart inversiones_nombre={this.state.inversiones_nombre} inversiones_monto={this.state.inversiones_monto} />
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card className="info-card-fh">
                                <DeudasChart acreedores_nombre={this.state.acreedores_nombre} acreedores_monto={this.state.acreedores_monto} />
                            </Card>
                        </Col>
                    </Row>
                </Content>
                
            </div>
        )
    }
}
