import React, { Component } from "react";
import { Row, Col, Button, Modal, Form, message, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

//componentes
import { Uploader } from "../../../Widgets/Uploaders";

const axios = require('axios').default;

class ModalDocumentosInversion extends Component {

    modalInversion = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    /**
    * @memberof ModalDocumentosInversion
    * @method   componentDidMount
    * @description  Al montar el componente:
    * Se inicializa header de axios
    **/
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.inversion_id !== undefined) {
            this.getInversion(this.props.inversion_id)
        }
    }


    getInversion = (_id) => {
        this.setState({ loading: true })
        axios.get('/inversiones/get', {
            params: {
                _id
            }
        }).then(response => {

            //Actualiza los valores del form dentro del modal.
            let data = response.data.data.inversion

            this.modalInversion.current.setFieldsValue({
                documentos: data?.documentos?.map((file, index) => ({
                    uid: file.file,
                    name: file.name,
                    status: 'done',
                    fromDB: true,
                    url: axios.defaults.baseURL + "/inversiones/get?_id=" + this.props.inversion_id + "&documento=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                })),
            });

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
      * @memberof ModalDocumentosInversion
      * @method   onFinish
      * @description  Al completar formulario :
      * Se valida el tipo de accion modal para crear nuevo material o editar el registro seleccionado
      **/
    onFinish = async (values) => {

        const formData = new FormData()

        formData.appendMultiple({
            ...values,
            _id: this.props.inversion_id,
            documentos: undefined
        });

        values.documentos?.forEach(e => {
            if (!e.fromDB) formData.append("documentos", e, e.name)
        })

        if (this.props.inversion_id) {
            this.update(formData)
        } else {
            this.save(formData)
        }


    }

    /**
      * @memberof ModalDocumentosInversion
      * @method   save
      * @description crea una nueva inversion
      **/
    save = async (values) => {
        axios.post('/inversiones/add', values)
            .then(response => {
                message.success('Inversion Creada')
                this.props.onClose()
            }).catch(error => {
                message.error('Error')
                console.log(error)
            })
    }

    /**
      * @memberof ModalDocumentosInversion
      * @method   update
      * @description Actualiza la informacion de una inversion
      **/
    update = async (values) => {
        axios.post('/inversiones/update', values)
            .then(response => {
                message.success('Inversion Actualizada')
                this.props.onClose()
            }).catch(error => {
                message.error('Error')
                console.log(error)
            })
    }


    renderUrl = (inversion_id, filename) => {
        const urlParams = new URL(axios.defaults.baseURL + "/inversiones/images/" + inversion_id)
        urlParams.searchParams.set("Authorization", sessionStorage.getItem("token"))
        urlParams.searchParams.set("file", filename)
        return urlParams.href
    }


    render() {

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    className="frm-cuentas"
                    name="form-materiales"
                    ref={this.modalInversion}
                    onFinish={this.onFinish}

                >
                    <Row align="center" gutter={20}>
                    <Col span={24}>
                            <Form.Item
                                name="documentos"
                                valuePropName="fileList"
                                getValueFromEvent={e => {
                                    if (Array.isArray(e)) {
                                        return e;
                                    }
                                    return e?.fileList;
                                }}>
                                <Uploader
                                    {...this.props.inversion_id ? {
                                        method: "POST",
                                        name: "documentos",
                                        headers: {
                                            authorization: sessionStorage.getItem("token")
                                        },
                                        //action: axios.defaults.baseURL + '/transacciones',
                                        onRemove: e => {
                                            axios.post('/inversiones/update', {
                                                documento: e.uid,
                                                _id: this.props.inversion_id
                                            })
                                        }
                                    } : {}}>
                                    <Button icon={<UploadOutlined />} block size="small">Subir Documentos </Button>
                                </Uploader>
                            </Form.Item>
                        </Col>
                    </Row>


                </Form>
            </Spin>
        )
    }
}



export default function (props) {

    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={`Documentos de la Inversión`}

        closable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-materiales', key: 'submit', htmlType: 'submit' }}
    >
        <ModalDocumentosInversion {...props} />
    </Modal>

}