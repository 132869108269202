import React, { Component } from "react";
import { Row, Col, Button, Modal, Form, Input, InputNumber, Select, message, Upload, Checkbox, Spin } from 'antd';
import { PlusOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import ColorPicker from '../../Widgets/ColorPicker';
import { AvatarLoader } from "../../Widgets/Uploaders"

const { Option } = Select;
const axios = require('axios').default;


class ModalCuentas extends Component {


    constructor(props) {
        super(props);
        this.state = {
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false,
            tipo: 1,
        }
    }

    refModalCuentas = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if (this.props.cuenta_id !== undefined) {
            this.getCuenta()
        }
    }

    /**
     * @memberof ModalCuentas
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {

        const formData = new FormData()
        formData.appendMultiple({
            ...values,
            logo: undefined,
            id: this.props.cuenta_id,
        })

        if(values.logo && values.logo instanceof File){
            formData.append("logo", values.logo, values.logo.name)
        }

        if(this.props.cuenta_id){
            formData.append("historial", true)
            this.updateCuenta(formData)
        }else{
            this.addCuenta(formData)
        }
    }




    /**
     * @memberof ModalCuentas
     * @method addCuenta
     * @description Añade una cuenta a la BD
     *
     */
    addCuenta = (values) => {
        axios.post('/cuentas', values)
        .then(response => {
            message.success('Cuenta creada')
            this.props.onClose()
        }).catch(error => {
            message.error('Error al crear la cuenta')
            console.log(error)
        })
    }

    /**
     * @memberof ModalCuentas
     * @method getCuenta
     * @description Obtiene una Cuenta de la DB
     *
     */
    getCuenta = () => {
        this.setState({ loading: true })
        axios.get('/cuentas/' + this.props.cuenta_id).then(({ data }) => {
            let cuenta = data;
            
            this.setState({
                cuenta: data,
                tipo: cuenta.tipo,
                
            })

            this.refModalCuentas.current.setFieldsValue({
                nombre: cuenta.nombre,
                titular: cuenta.titular,
                banco: cuenta.banco,
                cuenta: cuenta.cuenta,
                privado: cuenta.privado,
                tipo: cuenta.tipo,
                saldo: cuenta.saldo,
                color: cuenta.color,
                marca: cuenta.marca,
                logo: cuenta.logo ? {
                    uid: cuenta.logo.file,
                    name: cuenta.logo.name,
                    status: 'done',
                    fromDB: true,
                    url: `${axios.defaults.baseURL}/cuentas/${this.props.cuenta_id}?logo=${cuenta.logo.file}&Authorization=${sessionStorage.getItem("token")}`
                } : null
            })

        }).catch(error => {
            message.error('Error al traer la cuenta')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalCuentas
     * @method updateCuenta
     * @description Actualiza la información de una Cuenta
     * 
     */
    updateCuenta = (values) => {
        this.setState({ loading: true })
        axios.put('/cuentas', values)
        .then(response => {
            message.success('Cuenta Actualizada')
            this.props.onClose()
        }).catch(error => {
            message.error('Error al actualizar la cuenta')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     *
     * @memberof ModalProyectos
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    deleteImage = () => {
        this.setState({ loadingImage: true })
        const { image } = this.state;
        this.removeFile(image.name);
        if (this.props.cuenta_id !== undefined)
            axios.post('/cuentas', { logo: null, id: this.props.cuenta_id })
                .catch(error => console.log(error)).finally(() => {
                    this.setState({ loadingImage: false, image: null })
                })
        else {
            this.setState({ loadingImage: false })
        }

    };

    /**
     *
     * @memberof ModalUsuarios
     *
     * @method normFile
     * @description Se ejecuta cuando se actualiza el estado uploader. Si hay un archivo como "done", se actualiza como el nuevo archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {

        const { file } = e;

        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "uploading")
            this.setState({ loadingImage: true })


        if (file.status === "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                },
                loadingImage: false
            })

            if (this.props.cuenta_id !== undefined)
                axios.post('/cuentas', {
                    logo: e.file.response.filename,
                    id: this.props.cuenta_id
                })

        }


        return e && e.fileList;
    };

    /**
     *
     * @memberof ModalUsuarios
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con exito");
                this.setState({ loadingImage: false, image: null })
            })
            .catch(res => {
                console.log("imagen no se puedo remover");
            })
    };




    render() {

        const uploadButton = (
            <div>
                {this.state.loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Subir Imagen</div>
            </div>
        );


        return (
            <Form
                layout="vertical"
                id="form-cuenta"
                name="form-cuenta"
                ref={this.refModalCuentas}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>

                    <Row align="center">
                        <Form.Item
                            name="logo"
                            align="center"
                            //valuePropName="fileList"
                        >
                            <AvatarLoader
                                imageCrop={true}
                                onRemove={()=>{
                                    axios.put('/cuentas',{
                                        id:this.props.cuenta_id,
                                        delete_logo: true
                                    })
                                }}
                            />
                        </Form.Item>
                    </Row>
                    <Row gutter={[8, 8]}>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="Nombre" ></Input>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="Titular"
                                name="titular"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el titular"
                                }]}
                            >
                                <Input placeholder="Titular" ></Input>

                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11} >
                            <Form.Item
                                label="Banco"
                                name="banco"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el banco"
                                }]}
                            >
                                <Input placeholder="Banco" ></Input>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={{ span: 11, push: 2 }} >
                            <Form.Item
                                label="Número de Cuenta"
                                name="cuenta"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el número de cuenta"
                                }]}
                            >

                                <Input placeholder="Número de cuenta" maxLength={24} ></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11} >
                            <Form.Item
                                label="Cuenta privada"
                                name="privado"
                                valuePropName="checked"
                            >
                                <Checkbox> Privada</Checkbox>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={{ span: 11, push: 2 }} >
                            <Form.Item
                                label="Marca"
                                name="marca"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la marca"
                                }]}
                            >
                                <Select  >
                                    <Option value={1}>MasterCard</Option>
                                    <Option value={2}>Visa</Option>
                                    <Option value={3}>American Express</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={11} >
                            <Form.Item
                                label="Color"
                                name="color"
                                rules={[{
                                    required: true,
                                    message: "Indique el color"
                                }]}
                            >
                                <ColorPicker />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={{ span: 11, push: 2 }} >
                            <Form.Item
                                label="Tipo Cuenta"
                                name="tipo"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el tipo de Cuenta"
                                }]}
                            >
                                <Select
                                    onChange={(value) => this.setState({ tipo: value })}

                                >
                                    <Option value={1}>Bancaria</Option>
                                    <Option value={2}>Efectivo / Caja Menor</Option>
                                </Select>
                            </Form.Item>
                        </Col>



                        {this.state.tipo === 3 ? null : <Col xs={24} lg={{ span: 11 }} >
                            <Form.Item
                                label="Saldo"
                                name="saldo"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el saldo de la cuenta"
                                }]}
                            >
                                <InputNumber placeholder="Saldo" min={0} className="width-100" />
                            </Form.Item>
                        </Col>}
                    </Row>
                </Spin>
            </Form>
        )
    }
}


export default function (props) {
    const { visible = false, onClose = () => { }, cuenta_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={cuenta_id ? "Editar Cuenta" : "Crear Cuenta"}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-cuenta', key: 'submit', htmlType: 'submit' }}
    >
        <ModalCuentas {...props} />
    </Modal>

}