import React from 'react'
import { Route, Routes } from "react-router-dom";

import Inventario from '../components/Admin/Inventario/Inventario';

/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterInventario(props) {
    return (
        <Routes>
            <Route path="" element={<Inventario {...props} />} />
        </Routes>
    )
}

export default RouterInventario;
