import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Spin, Upload, } from 'antd';

import PropTypes from "prop-types";
import { LoadingOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import ColorPicker from '../../Widgets/ColorPicker';
import { AvatarLoader } from "../../Widgets/Uploaders";


const axios = require('axios').default;



/**
 *
 *
 * @export
 * @class ModalEmpresa
 * @extends {Component}
 */
class ModalEmpresa extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }
    };


    constructor(props) {
        super(props);
        this.state = {
            id: undefined,
            loading: false,
            form: {},
            loadingImage: false
        }
    }

    formModalEmpresa = React.createRef();


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        console.log('props', this.props);
        if (this.props.id !== this.state.id && this.props.id !== undefined) {
            this.get()
        }
    }



    /**
     * @methodOf ModalEmpresas
     * @function get
     *
     * @description Obtiene la Empresa
     *
     * */
    get = () => axios.get("/empresas/" + this.props.id)
        .then(async ({ data }) => {
            this.formModalEmpresa.current.setFieldsValue({
                ...data,
                logo: data.logo ? {
                    uid: data.logo.file,
                    name: data.logo.name,
                    status: 'done',
                    fromDB: true,
                    url: axios.defaults.baseURL + "/empresas/" + this.props.id + "?logo=" + data.logo.file +"&Authorization=" + sessionStorage.getItem("token")
                } : null
            })
            if (data.logo) this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + data.logo,
                    name: data.logo
                }
            })
        })
        .catch(res => {
            message.error('No se pudo cargar la Empresa')
            console.log("no obtenida", res);
        })


    /**
     * @methodOf ModalEmpresas
     * @function saveEmpresa
     *
     * @description Guarda la Empresa
     *
     * */
    onFinish = values => {
        this.setState({
            loading: true
        })

        const formData = new FormData()
        formData.appendMultiple({
            ...values,
            logo: undefined,
            id: this.props.id
        })

        if(values.logo && !values.logo?.fromDB){
            formData.append("logo", values.logo, values.logo.name)
        }

        if(this.props.id){
            this.update(formData)
        }else{
            this.save(formData)
        }
    }

    update = (values) => {
        axios.put("/empresas", values)
            .then(() => {
                message.success("¡Se ha actualizado correctamente la Empresa!")
                this.props.accept();
            })
            .catch((e) => {
                message.error('No se pudo actualizar la Empresa')
                console.log('e', e)
            })
            .finally(() => this.setState({
                loading: false
            }))
    }

    save = (values) => {
        axios.post("/empresas", values)
            .then(() => {
                message.success("¡Se ha guardado correctamente la Empresa!")
                this.props.accept();
            })
            .catch((e) => {
                message.error('No se pudo guardar la Empresa')
                console.log('e', e)
            })
            .finally(() => this.setState({
                loading: false
            }))
    }


    render() {
        const { formModalEmpresa, onFinish } = this;
        const { form, loading } = this.state;

        return (
            <Spin spinning={loading}>
                <Form id="form-empresa" layout="vertical" ref={formModalEmpresa} initialValues={form} onFinish={onFinish} >
                    <Form.Item name="_id" noStyle>
                        <Input type="hidden" />
                    </Form.Item>
                    <Row align="center">
                        <Form.Item
                            name="logo"
                        >
                            <AvatarLoader
                                imageCrop={true}
                                onRemove={()=>{
                                    axios.put('/empresas',{
                                        id: this.props.id,
                                        delete_logo: true
                                    })
                                }}
                                
                            />
                        </Form.Item>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col xs={24} >
                            <Form.Item label="Alias de la Empresa" name="alias" rules={[{ required: true, message: "La empresa debe tener un Alias" }]}>
                                <Input placeholder="I See You Tech" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label="Razón Social" name="razon_social"  >
                                <Input placeholder="Empresa"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label="RFC" name="rfc"
                                rules={[
                                    { pattern: /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/, message: "El RFC es inválido" }
                                ]}
                            >
                                <Input placeholder="RFC" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item label="Descripción" name="descripcion" >
                                <Input.TextArea placeholder="Descripción" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} >
                            <Form.Item label="Domicilio Fiscal de la Empresa" name="domicilio" rules={[{ required: true, message: "La empresa debe tener un domicilio fiscal" }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} >
                            <Form.Item label="Color" name="color" rules={[{ required: true, message: "La empresa debe tener un color" }]}>
                                <ColorPicker
                                    colors={['#4D4D4D', '#999999', '#FFFFFF', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E']}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}


/**
 *@function ModalNegocios
 *@description 
 */
export default function Modals(props) {
    return (
        <Modal
            visible={props.visible}
            onCancel={props.hideModal}
            title={props?.id ? "Editar Empresa" : "Nueva Empresa"}
            // footer={null}
            closable={true}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
            cancelText="Cancelar"
            okText="Guardar"
            okButtonProps={{ form: 'form-empresa', key: 'submit', htmlType: 'submit' }}
        >
            <ModalEmpresa {...props} />
        </Modal>
    )
}
