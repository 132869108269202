import React from 'react'
import { Route, Routes } from "react-router-dom";

import FinancialHealth from '../components/Admin/FinancialHealth/FinancialHealthDashboard'
import Inversiones from '../components/Admin/FinancialHealth/Inversiones'
import InversionesDetalle from '../components/Admin/FinancialHealth/InversionDetalle'


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterFinancialHealth(props) {
    return (
        <Routes>
            <Route path="" element={<FinancialHealth {...props} />} />
            <Route path="inversiones" element={<Inversiones  {...props}  />} />
            <Route path="inversion/detalle/:id" element={<InversionesDetalle  {...props}  />} />
        </Routes>
    )
}

export default RouterFinancialHealth;
