import React from 'react'
import { Route, Routes } from "react-router-dom";

// Components
import Dashboard from '../components/Admin/Contabilidad/Dashboard';
import FacturasSC from '../components/Admin/Contabilidad/Facturas';
import Empresa from '../components/Admin/Contabilidad/Empresa';
import FacturasSyncfy from '../components/Admin/Contabilidad/FacturasSyncfy';
//import DetallesProyecto from '../components/Admin/CRM/Clientes/DetallesProyecto';




/**
 * 
 * @export
 * @function RouterCRM
 * @description Router para CRM routes 
 */
 function RouterContabilidad(props) {
  return (
    <Routes>
    {/* render={(props) => } */}
      
      <Route path=""  element={<Dashboard {...props} />} />
      <Route path="facturas"  element={<FacturasSC {...props} />} />
      <Route path="facturas/sin-clasificar/:empresa_id" element={<FacturasSyncfy {...props} />} /> 
      <Route path="empresa/:empresa_id" element={<Empresa {...props}/>}/>
      {/* <Route path="proyecto/:proyecto_id" element={<DetallesProyecto {...props}/>}/> */}
    </Routes>
  )
}

export default RouterContabilidad