import React, { Component, useContext } from 'react';
import { Avatar, Spin, PageHeader, Tooltip, Layout, message, Row, Col, Card, Typography, Tabs, Menu, Space, Button, Result, Popconfirm, Tag, Empty, Form } from 'antd'
import axios from 'axios';
import { UploadOutlined } from '@ant-design/icons';



//Componentes
import Logged from '../../../../Hooks/Logged';
import { Uploader } from "../../../Widgets/Uploaders";
import usePermissions from '../../../../Hooks/usePermissions';


//Modales






//modal


const { Content } = Layout;
const { Text, Title, Paragraph } = Typography;
const { TabPane } = Tabs;


const moment = require('moment');
moment.locale('es');

/**
 * @export
 * @class DetalleClientes
 * @extends {Component}
 * @description Vista de Detalle Clientes
 */
class InformacionGeneral extends Component {

    constructor(props) {
        super(props)
        this.state = {
            empleado: {},

        }
    }



    componentDidMount() {
        this.get()
    }



    /**
     * @memberof DetallesClientes
     * @method get
     * @description Obtiene los detalles y propiedades del cliente
     *
     */
    get = () => {
        this.setState({ loading: true })
        axios.get('/empleados/' + this.props.id)
            .then(({ data }) => {
                console.log(data)
                let datos = data
                this.docRef.current.setFieldsValue({
                    identificacion_oficial: datos?.identificacion_oficial ? datos.identificacion_oficial?.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/empleados/" + this.props.id + "?identificacion_oficial=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                    })) : null,
                    curp_file: datos?.curp_file ? datos.curp_file?.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/empleados/" + this.props.id + "?curp_file=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                    })) : null,
                    rfc_file: datos?.rfc_file ? datos.rfc_file?.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/empleados/" + this.props.id + "?rfc_file=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                    })) : null,
                    comprobante_domicilio: datos?.comprobante_domicilio ? datos.comprobante_domicilio?.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/empleados/" + this.props.id + "?comprobante_domicilio=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                    })) : null,
                    alta_imss: datos?.alta_imss ? datos.alta_imss?.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/empleados/" + this.props.id + "?alta_imss=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                    })) : null,
                    baja_imss: datos?.baja_imss ? datos.baja_imss?.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/empleados/" + this.props.id + "?baja_imss=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                    })) : null,
                    contrato_laboral: datos?.contrato_laboral ? datos.contrato_laboral?.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/empleados/" + this.props.id + "?contrato_laboral=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                    })) : null,
                    reglamento: datos?.reglamento ? datos.reglamento?.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/empleados/" + this.props.id + "?reglamento=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                    })) : null,
                    job_description: datos?.job_description ? datos.job_description?.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/empleados/" + this.props.id + "?job_description=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                    })) : null,
                    nda: datos?.nda ? datos.nda?.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/empleados/" + this.props.id + "?nda=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                    })) : null,
                    carta_responsiva: datos?.carta_responsiva?.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/empleados/" + this.props.id + "?carta_responsiva=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                    })),
                    actas_administrativas: datos?.actas_administrativas ? datos.actas_administrativas?.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/empleados/" + this.props.id + "?actas_administrativas=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                    })) : null,
                    justificantes: datos?.justificantes ? datos.justificantes?.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/empleados/" + this.props.id + "?justificantes=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                    })) : null,
                })
            }).catch(error => {
                console.log(error)
                message.error('Error al obtener el datos')
            }).finally(() => this.setState({ loading: false }))
    }

    onFinish = (values) => {

        console.log(values)

        const formData = new FormData()
        formData.appendMultiple({
            id: this.props.id,
            
            identificacion_oficial: undefined,
            curp_file: undefined,
            rfc_file: undefined,
            comprobante_domicilio: undefined,
            alta_imss: undefined,
            baja_imss: undefined,
            contrato_laboral: undefined,
            reglamento: undefined,
            job_description: undefined,
            nda: undefined,
            carta_responsiva: undefined,
            actas_administrativas: undefined,
        })

        values.identificacion_oficial?.forEach(e => {
            if (!e.fromDB)
                formData.append("identificacion_oficial", e, e.name)
        })

        values.curp_file?.forEach(e => {
            if (!e.fromDB)
                formData.append("curp_file", e, e.name)
        })

        values.rfc_file?.forEach(e => {
            if (!e.fromDB)
                formData.append("rfc_file", e, e.name)
        })

        values.comprobante_domicilio?.forEach(e => {
            if (!e.fromDB)
                formData.append("comprobante_domicilio", e, e.name)
        })

        values.alta_imss?.forEach(e => {
            if (!e.fromDB)
                formData.append("alta_imss", e, e.name)
        })

        values.baja_imss?.forEach(e => {
            if (!e.fromDB) formData.append("baja_imss", e, e.name)
        })

        values.contrato_laboral?.forEach(e => {
            if (!e.fromDB)
                formData.append("contrato_laboral", e, e.name)
        })

        values.reglamento?.forEach(e => {
            if (!e.fromDB) formData.append("reglamento", e, e.name)
        })

        values.job_description?.forEach(e => {
            if (!e.fromDB)
                formData.append("job_description", e, e.name)
        })

        values.nda?.forEach(e => {
            if (!e.fromDB)
                formData.append("nda", e, e.name)
        })

        values.carta_responsiva?.forEach(e => {
            if (!e.fromDB)
                formData.append("carta_responsiva", e, e.name)
        })

        values.actas_administrativas?.forEach(e => {
            if (!e.fromDB)
                formData.append("actas_administrativas", e, e.name)
        })

        values.justificantes?.forEach(e => {
            if (!e.fromDB)
                formData.append("justificantes", e, e.name)
        })

        
            
        this.update(formData)
            
    }

    /**
    * @method updateUser
    * @description Actualiza la informacion de un empleado
    */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/empleados', values)
            .then(response => {
                message.success('Empleado Actualizado!')
                this.docRef.current.resetFields()
                this.get()
            }).catch(error => {
                console.log("error", error);
                message.error('Error al actualizar el Empleado')
            }).finally(() => this.setState({ loading: false }))
    }

    docRef = React.createRef();

    render() {
        const { empleado, cliente } = this.state

        window.form = this.docRef

        return (
            <>
                <Spin spinning={false}>


                    <Col span={24}>
                        <Card bordered>
                            <Row align="middle" className='mb-1' gutter={[12, 12]}>
                                <Col>
                                    <Title level={4} >Documentación</Title>
                                </Col>
                            </Row>

                            <Form
                                layout="vertical"
                                name="form-documentacion"
                                id="form-documentacion"
                                ref={this.docRef}
                                onFinish={this.onFinish}
                            >
                                <Row>
                                    <Col xs={24} lg={12}>
                                        <Form.Item
                                            label="Identificación Oficial"
                                            name="identificacion_oficial"
                                            valuePropName="fileList"
                                            getValueFromEvent={e => {
                                                if (Array.isArray(e)) {
                                                    return e;
                                                }
                                                return e?.fileList;
                                            }}>
                                            <Uploader
                                                {...this.props.id ? {
                                                    method: "PUT",
                                                    name: "identificacion_oficial",
                                                    headers: {
                                                        authorization: sessionStorage.getItem("token")
                                                    },
                                                    action: axios.defaults.baseURL + '/empleados',
                                                    onRemove: e => {
                                                        axios.put('/empleados', {
                                                            id: this.props.id,
                                                            identificacion_oficial: e.uid,
                                                        })
                                                    }
                                                } : {}}>
                                                <Button icon={<UploadOutlined />} block size="small">Subir Documentos </Button>
                                            </Uploader>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <Form.Item
                                            label="CURP"
                                            name="curp_file"
                                            valuePropName="fileList"
                                            getValueFromEvent={e => {
                                                if (Array.isArray(e)) {
                                                    return e;
                                                }
                                                return e?.fileList;
                                            }}>
                                            <Uploader
                                                {...this.props.id ? {
                                                    method: "PUT",
                                                    name: "curp_file",
                                                    headers: {
                                                        authorization: sessionStorage.getItem("token")
                                                    },
                                                    action: axios.defaults.baseURL + '/empleados',
                                                    onRemove: e => {
                                                        axios.put('/empleados', {
                                                            id: this.props.id,
                                                            curp_file: e.uid,
                                                        })
                                                    }
                                                } : {}}>
                                                <Button icon={<UploadOutlined />} block size="small">Subir Documentos </Button>
                                            </Uploader>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} lg={12}>
                                        <Form.Item
                                            label="RFC"
                                            name="rfc_file"
                                            valuePropName="fileList"
                                            getValueFromEvent={e => {
                                                if (Array.isArray(e)) {
                                                    return e;
                                                }
                                                return e?.fileList;
                                            }}>
                                            <Uploader
                                                {...this.props.id ? {
                                                    method: "PUT",
                                                    name: "rfc_file",
                                                    headers: {
                                                        authorization: sessionStorage.getItem("token")
                                                    },
                                                    action: axios.defaults.baseURL + '/empleados',
                                                    onRemove: e => {
                                                        axios.put('/empleados', {
                                                            id: this.props.id,
                                                            rfc_file: e.uid,
                                                        })
                                                    }
                                                } : {}}>
                                                <Button icon={<UploadOutlined />} block size="small">Subir Documentos </Button>
                                            </Uploader>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <Form.Item
                                            label="Comprobante de Domicilio"
                                            name="comprobante_domicilio"
                                            valuePropName="fileList"
                                            getValueFromEvent={e => {
                                                if (Array.isArray(e)) {
                                                    return e;
                                                }
                                                return e?.fileList;
                                            }}>
                                            <Uploader
                                                {...this.props.id ? {
                                                    method: "PUT",
                                                    name: "comprobante_domicilio",
                                                    headers: {
                                                        authorization: sessionStorage.getItem("token")
                                                    },
                                                    action: axios.defaults.baseURL + '/empleados',
                                                    onRemove: e => {
                                                        axios.put('/empleados', {
                                                            id: this.props.id,
                                                            comprobante_domicilio: e.uid,
                                                        })
                                                    }
                                                } : {}}>
                                                <Button icon={<UploadOutlined />} block size="small">Subir Documentos </Button>
                                            </Uploader>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} lg={12}>
                                        <Form.Item
                                            label="Alta IMSS"
                                            name="alta_imss"
                                            valuePropName="fileList"
                                            getValueFromEvent={e => {
                                                if (Array.isArray(e)) {
                                                    return e;
                                                }
                                                return e?.fileList;
                                            }}>
                                            <Uploader
                                                {...this.props.id ? {
                                                    method: "PUT",
                                                    name: "alta_imss",
                                                    headers: {
                                                        authorization: sessionStorage.getItem("token")
                                                    },
                                                    action: axios.defaults.baseURL + '/empleados',
                                                    onRemove: e => {
                                                        axios.put('/empleados', {
                                                            id: this.props.id,
                                                            alta_imss: e.uid,
                                                        })
                                                    }
                                                } : {}}>
                                                <Button icon={<UploadOutlined />} block size="small">Subir Documentos </Button>
                                            </Uploader>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <Form.Item
                                            label="Contrato Laboral"
                                            name="contrato_laboral"
                                            valuePropName="fileList"
                                            getValueFromEvent={e => {
                                                if (Array.isArray(e)) {
                                                    return e;
                                                }
                                                return e?.fileList;
                                            }}>
                                            <Uploader
                                                {...this.props.id ? {
                                                    method: "PUT",
                                                    name: "contrato_laboral",
                                                    headers: {
                                                        authorization: sessionStorage.getItem("token")
                                                    },
                                                    action: axios.defaults.baseURL + '/empleados',
                                                    onRemove: e => {
                                                        axios.put('/empleados', {
                                                            id: this.props.id,
                                                            contrato_laboral: e.uid,
                                                        })
                                                    }
                                                } : {}}>
                                                <Button icon={<UploadOutlined />} block size="small">Subir Documentos </Button>
                                            </Uploader>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} lg={12}>
                                        <Form.Item
                                            label="Job Description"
                                            name="job_description"
                                            valuePropName="fileList"
                                            getValueFromEvent={e => {
                                                if (Array.isArray(e)) {
                                                    return e;
                                                }
                                                return e?.fileList;
                                            }}>
                                            <Uploader
                                                {...this.props.id ? {
                                                    method: "PUT",
                                                    name: "job_description",
                                                    headers: {
                                                        authorization: sessionStorage.getItem("token")
                                                    },
                                                    action: axios.defaults.baseURL + '/empleados',
                                                    onRemove: e => {
                                                        axios.put('/empleados', {
                                                            id: this.props.id,
                                                            job_description: e.uid,
                                                        })
                                                    }
                                                } : {}}>
                                                <Button icon={<UploadOutlined />} block size="small">Subir Documentos </Button>
                                            </Uploader>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <Form.Item
                                            label="NDA"
                                            name="nda"
                                            valuePropName="fileList"
                                            getValueFromEvent={e => {
                                                if (Array.isArray(e)) {
                                                    return e;
                                                }
                                                return e?.fileList;
                                            }}>
                                            <Uploader
                                                {...this.props.id ? {
                                                    method: "PUT",
                                                    name: "nda",
                                                    headers: {
                                                        authorization: sessionStorage.getItem("token")
                                                    },
                                                    action: axios.defaults.baseURL + '/empleados',
                                                    onRemove: e => {
                                                        axios.put('/empleados', {
                                                            id: this.props.id,
                                                            nda: e.uid,
                                                        })
                                                    }
                                                } : {}}>
                                                <Button icon={<UploadOutlined />} block size="small">Subir Documentos </Button>
                                            </Uploader>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} lg={12}>
                                        <Form.Item
                                            label="Carta Responsiva"
                                            name="carta_responsiva"
                                            valuePropName="fileList"
                                            getValueFromEvent={e => {
                                                if (Array.isArray(e)) {
                                                    return e;
                                                }
                                                return e?.fileList;
                                            }}>
                                            <Uploader
                                                {...this.props.id ? {
                                                    method: "PUT",
                                                    name: "carta_responsiva",
                                                    headers: {
                                                        authorization: sessionStorage.getItem("token")
                                                    },
                                                    action: axios.defaults.baseURL + '/empleados',
                                                    onRemove: e => {
                                                        axios.put('/empleados', {
                                                            id: this.props.id,
                                                            carta_responsiva: e.uid,
                                                        })
                                                    }
                                                } : {}}>
                                                <Button icon={<UploadOutlined />} block size="small">Subir Documentos </Button>
                                            </Uploader>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <Form.Item
                                            label="Actas Administrativa"
                                            name="actas_administrativas"
                                            valuePropName="fileList"
                                            getValueFromEvent={e => {
                                                if (Array.isArray(e)) {
                                                    return e;
                                                }
                                                return e?.fileList;
                                            }}>
                                            <Uploader
                                                {...this.props.id ? {
                                                    method: "PUT",
                                                    name: "actas_administrativas",
                                                    headers: {
                                                        authorization: sessionStorage.getItem("token")
                                                    },
                                                    action: axios.defaults.baseURL + '/empleados',
                                                    onRemove: e => {
                                                        axios.put('/empleados', {
                                                            id: this.props.id,
                                                            actas_administrativas: e.uid,
                                                        })
                                                    }
                                                } : {}}>
                                                <Button icon={<UploadOutlined />} block size="small">Subir Documentos </Button>
                                            </Uploader>
                                        </Form.Item>

                                    </Col>

                                    <Col md={24} xl={12}>
                                        <Form.Item
                                            label="Baja del Imss"
                                            name="baja_imss"
                                            valuePropName="fileList"
                                            getValueFromEvent={e => {
                                                if (Array.isArray(e)) {
                                                    return e;
                                                }
                                                return e?.fileList;
                                            }}>
                                            <Uploader
                                                maxCount={1}
                                                {...this.props.transaccion_id ? {
                                                    method: "PUT",
                                                    name: "baja_imss",
                                                    headers: {
                                                        authorization: sessionStorage.getItem("token")
                                                    },
                                                    action: axios.defaults.baseURL + '/empleados',
                                                    onRemove: e => {
                                                        axios.put('/empleados', {
                                                            id: this.props.empleado_id,
                                                            baja_imss: e.uid,
                                                        })
                                                    }
                                                } : {}}>
                                                <Button icon={<UploadOutlined />} block size="small">Subir Documento</Button>
                                            </Uploader>
                                        </Form.Item>
                                    </Col>
                                    <Col md={24} xl={12}>
                                        <Form.Item
                                            label="Reglamento Interno"
                                            name="reglamento"
                                            valuePropName="fileList"
                                            getValueFromEvent={e => {
                                                if (Array.isArray(e)) {
                                                    return e;
                                                }
                                                return e?.fileList;
                                            }}>
                                            <Uploader
                                                maxCount={1}
                                                {...this.props.transaccion_id ? {
                                                    method: "PUT",
                                                    name: "reglamento",
                                                    headers: {
                                                        authorization: sessionStorage.getItem("token")
                                                    },
                                                    action: axios.defaults.baseURL + '/empleados',
                                                    onRemove: e => {
                                                        axios.put('/empleados', {
                                                            id: this.props.empleado_id,
                                                            reglamento: e.uid,
                                                        })
                                                    }
                                                } : {}}>
                                                <Button icon={<UploadOutlined />} block size="small">Subir Documento</Button>
                                            </Uploader>
                                        </Form.Item>
                                    </Col>
                                    <Col md={24} xl={12}>
                                        <Form.Item
                                            label="Justificantes"
                                            name="justificantes"
                                            valuePropName="fileList"
                                            getValueFromEvent={e => {
                                                if (Array.isArray(e)) {
                                                    return e;
                                                }
                                                return e?.fileList;
                                            }}>
                                            <Uploader
                                                maxCount={1}
                                                {...this.props.transaccion_id ? {
                                                    method: "PUT",
                                                    name: "justificantes",
                                                    headers: {
                                                        authorization: sessionStorage.getItem("token")
                                                    },
                                                    action: axios.defaults.baseURL + '/empleados',
                                                    onRemove: e => {
                                                        axios.put('/empleados', {
                                                            id: this.props.empleado_id,
                                                            justificantes: e.uid,
                                                        })
                                                    }
                                                } : {}}>
                                                <Button icon={<UploadOutlined />} block size="small">Subir Documento</Button>
                                            </Uploader>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} className="center">
                                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                            <Button type="primary" htmlType="submit">
                                                Enviar
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>



                            </Form>





                        </Card>
                    </Col>

                </Spin>
            </>
        )
    }
}

export default function (props) {


    const user = useContext(Logged);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        crearPropiedades: ["clientes", "propiedades", "create"],

        verTransaccionesPropiedad: ["clientes", "propiedades", "transacciones", "access"],
        crearTransaccionesPropiedad: ["clientes", "propiedades", "transacciones", "create"],

        verTransaccionesProgramadasPropiedad: ["clientes", "propiedades", "transacciones-programadas", "access"],
        crearTransaccionesProgramadasPropiedad: ["clientes", "propiedades", "transacciones-programadas", "create"],
    });

    return <InformacionGeneral

        {...permisos}
        {...props}
    />
}