import React, { Component } from "react";
import { Row, Col, Button, Modal, Form, DatePicker, Input, InputNumber, Select, message, Spin, Typography } from 'antd';
import { CheckCircleFilled } from "@ant-design/icons"

//componentes
import { SelectProyecto, SelectEmpresa } from "../../../Widgets/Inputs/Selects"

const { Option } = Select;
const { Text } = Typography;
const axios = require('axios').default;
const moment = require('moment');

/**
 * @class ModalCotizacion
 * @description Modal que se utiliza para crear cotizaciones (facturas estatus de -1) relacionadas a un proveedor
 */
class ModalCotizacion extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            proveedor: undefined,
            disabled: false,
        }
    }

    ModalCotizacion = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.factura_id) {
            this.getFactura();
        }
    }





    /**
     * @memberof ModalCotizacion
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {
        if (this.props.factura_id) {
            this.updateFactura(values)
        } 
    }


    /**
     * @memberof ModalCotizacion
     * @method getFactura
     * @description Se ejecuta al si el modal tiene factura_id en las propiedades y obtiene los datos para
     * llenar el formulario y editar
     * 
     */
    getFactura = () => {
        this.setState({ loading: true })

        axios.get(`/facturas/${this.props.factura_id}`, {
            params: { 
                id: this.props.factura_id,
            },
        }).then(async response => {
            let factura = response.data.data

            this.ModalCotizacion.current?.setFieldsValue({
                monto: factura.monto,
            })

            this.setState({
                factura
            })

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la transaccion')
        }).finally(() => {

            this.setState({ loading: false, disabled: true })
        })
    }

    /**
    * @memberof ModalCotizacion
    * @method updateFactura
    * @description Actualiza la informacion de una factura
    */
    updateFactura = (values) => {
        this.setState({ loading: true })
        axios.put('/facturas', {
            ...values,
            estatus: 1,
            monto: values.monto.toMoney(),
            tipo: this.state.factura?.tipo,
            id: this.props.factura_id
        }).then(response => {
            message.success('Factura Actualizada')
            this.props.onClose()
        }).catch(error => {
            console.log(error)
            message.error('Error al guardar')
        }).finally(() => this.setState({ loading: false }))
    }


    render() {
        const { disabled } = this.state;
        return (
            <Form
                layout="vertical"
                name="form-materiales"
                ref={this.ModalCotizacion}
                onFinish={this.onFinish}
                initialValues={{
                    fecha: moment(),
                    tipo: 2,
                }}

            >
                <Row>
                    <Col span={24} className="center">
                        <Text>La cotización sera aprobada y se convertira en factura.</Text>
                    </Col>
                    <Col span={24} className="center pt-2 pb-2">
                        <CheckCircleFilled style={{fontSize: '65px', color: '#52c41a'}} />
                    </Col>
                </Row>
                <Spin spinning={this.state.loading}>

                    <Row>
                        <Col xs={24} lg={{ span: 11 }}>
                            <Form.Item
                                label="Fecha de Vencimiento"
                                name="fecha_vigencia"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha"
                                }]}
                            >
                                <DatePicker className="width-100" />
                            </Form.Item>
                        </Col>
            
                        <Col xs={24} lg={{ span: 11, push: 2  }} >
                            <Form.Item
                                label="Monto (Pesos)"
                                name="monto"
                                // formatter="(\d)(?=(\d\d\d)+(?!\d))"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >
                                <InputNumber disabled={false} min={0.01} defaultValue={0} className="width-100" decimalSeparator="," onChange={this.onChangePesos}
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                    />
                                
                            </Form.Item>
                        </Col>


                    </Row>
                    
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, factura_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={`Cotización Aceptada`}
        /* footer={null} */
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-materiales', key: 'submit', htmlType: 'submit' }}
    >
        <ModalCotizacion {...props} />
    </Modal>

}