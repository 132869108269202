import React, { Component } from 'react';
import { Row, Col, Typography, Button, Popconfirm, Spin, PageHeader, Layout, Space, message, List, Card, Tag } from 'antd'
import axios from 'axios';
import '../../../Styles/Modules/Empleados/Empleados.scss'
//componentes
import Logged from "../../../Hooks/Logged";
import usePermissions from '../../../Hooks/usePermissions';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import { IconDelete, IconEdit, IconMail } from '../../Widgets/Iconos';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import { Link, useSearchParams } from "react-router-dom"
import moment from 'moment';
//modal
import ModalUsuarios from './ModalEmpleados'
import { EyeOutlined, MinusOutlined, PlusOutlined, WarningOutlined } from '@ant-design/icons';
const { Content } = Layout;
const { Text } = Typography

/**
 *
 *
 * @export
 * @class Usuarios
 * @extends {Component}
 * @description Vista donde se listan todos los usuarios
 */
class Empleados extends Component {
    static contextType = Logged;

    back = () => {
        this.props.history.goBack();
    }


    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {

            loading: false,
            empleados: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

            },
            empleado_id: null
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getEmpleados()
    }

    /**
     *
     * @memberof Usuarios
     * @method componentDidUpdate
     * @description Si se realiza una busqueda, filtra los usuarios
     */
    componentDidUpdate(prevProps) {
        console.log("this.props.search", this.props.search);
        if (this.props.search !== prevProps.search)
            this.getEmpleados()
    }

    /**
	 *
	 *
	 * @memberof empleados
	 * 
	 * @method getempleados
	 * @description Obtiene los empleados
	 */
	getEmpleados = ({
		page = this.state.empleados.page,
		limit = this.state.empleados.limit,
		search = this.props.search,
	} = this.state.empleados
	) => {
		this.setState({ empleados: { ...this.state.empleados, page, limit }, loading: true })
		axios.get('/empleados', {
			params: { page, limit, search }
		}).then(({ data }) => {
            console.log("data empleados", data)
			this.props.setSearchParams({ page, limit })
			this.setState(state => {
				state.empleados.data = data.data
				state.empleados.total = data.total
				state.empleados.page = data.page
				return state;
			})
		}).catch(error => {
			console.log(error)
			message.error('Error al traer la información')
		}).finally(() => this.setState({ loading: false }))
	}

    render() {

        let renderEstatusEmpleado = (estatus) => {
            switch (estatus) {
                case 1:
                    return <Tag className='tag-activo'>Activo</Tag>
                case 2:
                    return <Tag className='tag-baja'>Baja</Tag>
                default:
                    return <Tag color="default">Desconocido</Tag>
            }
        }

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={"Recursos Humanos"}
                        extra={<span className="page-header-extra"> {this.state.empleados.total} Empleados</span>}
                    >

                    </PageHeader>

                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Empleados" }}
                            dataSource={this.state.empleados.data}
                            pagination={{
                                current: this.state.empleados.page,
                                pageSize: this.state.empleados.limit,
                                total: this.state.empleados.total,
                                hideOnSinglePage: false,
                                showSizeChanger: true,
                                position: 'bottom',
                                onChange: (page, limit) => this.getEmpleados({ page, limit })
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-1" >
                                <Col span={5} >
                                    <Text strong>Nombre</Text>
                                </Col>

                                <Col span={5} className="center">
                                    <Text strong>Fecha de Ingreso</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>Empresa</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>Estatus</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Acciones</Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">

                                            <Col span={5} >
                                                <CustomAvatar
                                                    height={42}
                                                    width={107}
                                                    placeholder={true}
                                                    url={item.avatar ? {
                                                        url: axios.defaults.baseURL + "/empleados/" + item._id + "?avatar=" + item?.avatar?.file + "&Authorization=" + sessionStorage.getItem("token"),
                                                        logo: item.avatar?.name
                                                    } : null}
                                                    name={`${item.nombre} ${item.apellido_paterno}`}
                                                />
                                                <Text ellipsis>{item.nombre} {item.apellido_paterno ? item.apellido_paterno : ''}</Text>
                                            </Col>

                                            <Col span={5} className="center">
                                                <Text className="text-gray">{moment(item.fecha_ingreso).format('YYYY-MM-DD')}</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Text className="text-gray">{item?.empresa_id?.alias}</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Text className="text-gray">{renderEstatusEmpleado(item.estatus)}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Space>
                                                    <Button type="primary" icon={<IconEdit />} disabled={!this.props.editEmpleados} onClick={() => this.setState({ modalEmpleados: true, empleado_id: item._id })} />
                                                    <Link title="Detalles" to={`/admin/empleados/${item._id}`}>
                                                        <Button
                                                            
                                                            className="ant-btn-primary-lime" name={item.nombre} id={item._id}
                                                            title="Ver Detalles" icon={<EyeOutlined style={{ color: "#FFF" }} />}>
                                                        </Button>
                                                    </Link>
                                                    <Popconfirm
                                                        placement="topRight"
                                                        disabled={!this.props.deleteEmpleados}
                                                        title="¿Deseas eliminar este empleado?"
                                                        onConfirm={() => axios.delete('/empleados', {
                                                            params: {
                                                                id: item._id
                                                            }
                                                        }).then((response) => {
                                                            message.success(response?.data?.message)
                                                            this.getEmpleados()
                                                        })
                                                            .catch((error) => {
                                                                message.error(error?.response?.data?.message);

                                                            })
                                                        }
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button type="normal" danger disabled={!this.props.deleteEmpleados} title="Eliminar" icon={<IconDelete />} />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Content>

                    {this.props.createEmpleados ? <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalEmpleados: true })} /> : null}

                    <ModalUsuarios
                        visible={this.state.modalEmpleados}
                        empleado_id={this.state.empleado_id}
                        onClose={() => {
                            this.setState({ modalEmpleados: false, empleado_id: undefined })
                            this.getEmpleados()
                        }}
                    />
                </Spin>
            </>
        )
    }
}

export default function (props) {


    let user = React.useContext(Logged)


    let permissions = usePermissions(user?.rol_id?.permisos, {
        editEmpleados: ["empleados", "edit"],
        createEmpleados: ["empleados", "create"],
        deleteEmpleados: ["empleados", "delete"],
    })

    let [searchParams, setSearchParams] = useSearchParams();

    return <Empleados {...props} {...permissions} searchParams={searchParams}
    setSearchParams={setSearchParams}/>
}