import React, { Component } from "react";
import { Modal, Form, Input, message, Spin, InputNumber, Select, Typography } from 'antd';
import { SelectProducto, SelectProyecto, SelectInmueble } from "../../Widgets/Inputs/Selects";
import PrioridadPicker from "../../Widgets/Inputs/PrioridadPicker";
import SelectEmpleado from "../../Widgets/Inputs/SelectEmpleado";

const { Option } = Select
const { Text } = Typography


const axios = require('axios').default;

/**
 *
 *
 * @class ModalRequisiciones
 * @extends {Component}
 */
class ModalRequisiciones extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            proyecto_id: null,

            unidades: {
                data: [
                    // {}
                ],

                page: 1,
                limit: 50,

                total: 0,
                pages: 0,

                search: null,

                //value
                inputValue: null

            },
            unidad: "-"
        }
    }

    ModalRequisiciones = React.createRef();

    componentDidMount() {
        this.getUnidades()
    }

    onFinish = (values) => {

        console.log("values", );
        values.unidad = values?.unidad?.value || values?.unidad
        this.addRequisicion(values)
    }

    getUnidades = ({
        page = this.state.unidades.page,
        limit = this.state.unidades.limit,
        search = this.state.unidades.search,
    } = this.state.unidades) => {

        //this.setState({ unidades: { ...this.state.unidades, page, limit, } })
        axios.get('/sat-catalogos/unidades-claves', {
            params: { page, limit, search }
        })
            .then(({ data }) => {
                this.setState({ unidades: { ...this.state.unidades, ...data } })
            })
            .catch(error => {
                message.error('Error al obtner las categorias')
            })
    }


    /**
     *
     * @methodOf ModalRequisiciones
     * @function addRequisicion
     * @description Añade una nueva requisicion
     */
    addRequisicion = (values) => {
        this.setState({ loading: true })
        axios.post('/requisiciones', {
            ...values
        }).then(response => {
            message.success("Requisición creada")
            this.props.onClose(true)
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al crear la requisicion")
        }).finally(() => {
            this.setState({ loading: false })
        })
    }



    renderUnidadOption = ({ nombre, simbolo, descripcion, unidad }) => <Typography style={{ margin: "0 0 0.2em 0" }}>
        <Text strong>{nombre} <i>{simbolo ? <i><small style={{ fontWeight: "lighter" }}>({simbolo})</small></i> : null} </i> <small> ({unidad})</small></Text> <br />
        <Text>{descripcion}</Text>
    </Typography>

    render() {
        return (
            <Form
                layout="vertical"
                name="form-requisiciones"
                ref={this.ModalRequisiciones}
                onFinish={this.onFinish}
                className="pd-1"
                initialValues={{
                    cantidad: 1,
                    prioridad: 0
                }}
            >
                <Spin spinning={this.state.loading}>
                    <Form.Item
                        label="Producto"
                        name="producto_id"
                        rules={[{
                            required: true,
                            message: "Por favor, seleccione el producto"
                        }]}
                    >
                        <SelectProducto onSelect={(e, producto) => {
                            producto = producto[0]?.producto
                            console.log("producto?.unidad_id?._id", producto);
                            if (producto?.unidad?._id)
                                this.ModalRequisiciones?.current.setFieldsValue({
                                    unidad: {
                                        label: this.renderUnidadOption(producto?.unidad),
                                        key: producto?.unidad?._id,
                                        value: producto?.unidad?._id
                                    }
                                })
                            else
                                this.ModalRequisiciones?.current.setFieldsValue({
                                    unidad: undefined,
                                })
                            // console.log("producto",)
                            this.setState({unidad: "-"})
                        }} />
                    </Form.Item>
                    <Form.Item
                        label="Unidad"
                        name="unidad"
                        rules={[
                            {
                                required: true,
                                message: "Indique la Unidad del Producto"
                            }
                        ]}
                    >
                        <Select
                            placeholder="Busque la unidad deseada"
                            className="form-select select-actions"
                            showSearch
                            filterOption={false}
                            onSearch={search => this.getUnidades({ search })}
                            onSelect={(unidad_id) => {
                                let index = this.state.unidades.data.findIndex(e => e._id.toString() === unidad_id.toString())
                                if(index !== -1){
                                    this.setState({
                                        unidad: this.state.unidades?.data[index]?.simbolo
                                    })
                                }
                            }}
                        >
                            {this.state.unidades.data?.map((unidad) => <Option
                                className="option-actions "
                                key={unidad._id}
                                value={unidad._id}
                            >
                                {this.renderUnidadOption(unidad)}
                            </Option>
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Proyecto"
                        name="proyecto_id"
                        rules={[{
                            required: true,
                            message: "Por favor, seleccione el proyecto"
                        }]}
                    >
                        <SelectProyecto
                            onSelect={(proyecto_id) => {
                                this.ModalRequisiciones.current.setFieldsValue({ inmueble_id: undefined })
                                this.setState({ proyecto_id })
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Cantidad / Volumen"
                        name="volumen"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese la cantidad"
                        }]}
                    >
                        <InputNumber
                            className="width-100"
                            min={1}
                            addonAfter={this.state.unidad}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Inmueble"
                        name="inmuebles"
                        rules={[{
                            required: true,
                            message: "Por favor, seleccione el inmueble"
                        }]}
                    >
                        <SelectInmueble
                            mode="multiple"
                            proyecto_id={this.state.proyecto_id}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Empleado / Maestro"
                        name="empleado_id"
                        rules={[{
                            required: true,
                            message: "Por favor, seleccione el empleado"
                        }]}
                    >
                        <SelectEmpleado
                            mode="multiple"
                        />
                    </Form.Item>

                    <Form.Item
                        label="Prioridad"
                        name="prioridad"
                        rules={[{
                            required: true,
                            message: "Por favor, seleccione la prioridad"
                        }]}
                    >
                        <PrioridadPicker />
                    </Form.Item>

                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, area_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={"Nueva Requisición"}
        closable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-requisiciones', key: 'submit', htmlType: 'submit' }}
    >
        <ModalRequisiciones {...props} />
    </Modal>

}