import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, Layout, message, Space, List, Typography } from 'antd'

import axios from 'axios';


import { IconDelete, IconEdit } from '../../../Widgets/Iconos';
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
import Avatar from "../../../Widgets/Avatar/Avatar";

import ModalProductoAlmacen from '../../Almacenes/ModalesSecciones/ModalProductoAlmacen';

import Logged from '../../../../Hooks/Logged'
import usePermissions from '../../../../Hooks/usePermissions';

const { Content } = Layout;
const { Text } = Typography;


export class Almacenes extends Component {

    constructor(props) {
        super(props)
        this.state = {

            almacenes: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,
            },

        }
    }

    componentDidMount() {


        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getAlmacenes()
    }

    renderUrl = (item) => {
        console.log("Render URL",item)
        const urlParams = new URL(axios.defaults.baseURL + "/proyectos/logo/" + item._id)
        urlParams.searchParams.set("Authorization", sessionStorage.getItem("token"))
        urlParams.searchParams.set("name", item.logo?.name)
        return urlParams.href
    }


    getAlmacenes = ({
        page = this.state.almacenes.page,
        limit = this.state.almacenes.limit,
        search = this.props.search

    } = this.state.almacenes) => {
        this.setState({ loading: true, almacenes: { ...this.state.almacenes, page, limit } })
        axios.get('/almacenes/productos-almacenes/' + this.props.producto_id, {
            params: {
                page,
                limit,
                search
            }
        }).then(({ data }) => {
            console.log(data)
            this.setState({
                almacenes: data,
            });

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        })
    }


    render() {
        return (
            <>
                <Spin spinning={false}>
                    <Content className="admin-content content-bg">
                        <List
                            loading={false}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Almacenes " }}
                            dataSource={this.state.almacenes.data}
                            pagination={{
                                current: 1,
                                pageSize: 10,
                                total: 3,
                                position: 'bottom',
                                

                            }}
                            header={
                                <Row className="width-100 pr-1 pl-1">
                                    <Col span={5} className="center">
                                        <Text strong>ALMACÉN</Text>
                                    </Col>
                                    <Col span={5} className="center">
                                        <Text strong>PROYECTO</Text>
                                    </Col>
                                    <Col span={5} className="center">
                                        <Text strong>TOTAL</Text>
                                    </Col>
                                    <Col span={5} className="center">
                                        <Text strong>CANTIDAD</Text>
                                    </Col>

                                    <Col span={4} className="center">
                                        <Text strong>ACCIONES</Text>
                                    </Col>
                                </Row>
                            }
                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100">

                                            <Col span={5} className="center">
                                                <Text>{item?.nombre}</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Avatar
                                                    color={item.proyecto_id?.color}
                                                    {...(item.proyecto_id?.logo ? {
                                                        image: this.renderUrl(item.proyecto_id)
                                                    } : {
                                                        name: item.proyecto_id?.nombre
                                                    })}


                                                // image={}
                                                />
                                                <Text className='ml-1'>{item?.proyecto_id?.nombre}</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Text>$ {item?.total.toMoney(true)} MXN</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Text>{item?.cantidad} {item.producto_id.unidad.simbolo ? item.producto_id.unidad.simbolo : item.producto_id.unidad.nombre}{item?.cantidad === 1 ? null : "s"}</Text>
                                            </Col>
                                            <Col span={4} align="right">
                                                <Space size="small" direction="horizontal">
                                                    <Button
                                                        type="primary"
                                                        disabled={false}
                                                        icon={<IconEdit />}
                                                        title="Editar"
                                                        onClick={() => this.setState({ modal_visible: true, producto_almacen_id: item.inventario_id })}
                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        disabled={false}
                                                        title="¿Deseas eliminar este almacen?"
                                                        onConfirm={() => axios.delete('/almacenes/productos/' + item.inventario_id,
                                                            {}).then(() => {
                                                                this.getAlmacenes()
                                                                message.success('Producto eliminado del Almacén')
                                                            }).catch(error => {
                                                                message.error('Error al eliminar el Producto')

                                                            })}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            disabled={false}
                                                            type="primary"
                                                            icon={<IconDelete />}
                                                            title="Eliminar"
                                                            danger
                                                        />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>

                    {this.props.createInventario ? <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} /> : null}

                    <ModalProductoAlmacen
                        visible={this.state.modal_visible}
                        onClose={() => { this.setState({ modal_visible: false, producto_almacen: undefined }); this.getAlmacenes() }}
                        almacen_id={this.props.almacen_id}
                        producto_id={this.props.producto_id}
                        producto_almacen_id={this.state.producto_almacen_id}
                    />

                </Spin>
            </>
        )
    }
}


export default function (props) {

    let user = React.useContext(Logged)


    let permissions = usePermissions(user?.rol_id?.permisos, {

        createInventario: ["almacenes-productos", "create"],


    })


    return <Almacenes {...props} />

}