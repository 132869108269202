import React, { Component, useEffect } from "react";
import { Typography, Button, Modal, Form, Input, message, Spin, Row, Col, Divider, Select, Space, Tooltip } from 'antd'


import { DeleteOutlined, EditOutlined, PlusCircleTwoTone, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { SimpleUploader, Uploader } from "../../Widgets/Uploaders";
import { UploadOutlined } from "@ant-design/icons";

const { Text } = Typography

const { Option } = Select

const axios = require('axios').default;


function InputEditable(props) {

    useEffect(() => {

        document.getElementById("editInput").focus()


    }, []);


    return <Input
        id="editInput"
        onClick={event => event.stopPropagation()}
        onKeyUp={event => event.stopPropagation()}
        onKeyDown={event => event.stopPropagation()}
        onKeyDownCapture={event => event.stopPropagation()}
        onKeyUpCapture={event => event.stopPropagation()}
        {...props} />


}

/**
 *
 *
 * @class ModalProductos
 * @extends {Component}
 */
class ModalProductos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,

            unidades: {
                data: [
                    // {}
                ],

                page: 1,
                limit: 15,

                total: 0,
                pages: 0,

                search: null,

                //value
                inputValue: null

            },

            categorias: {
                data: [
                    // {}
                ],

                page: 1,
                limit: 10,

                total: 0,
                pages: 0,

                search: null
            },

            sub_categorias: {
                data: [
                    // {}
                ],

                page: 1,
                limit: 10,

                total: 0,
                pages: 0,

                search: null
            },

            producto: 1,
            categoria: null,
            subcategoria: null
        }
    }

    ModalProductos = React.createRef();

    componentDidMount() {
        this.getUnidades()
        this.getCategorias()


        if (this.props.producto_id !== undefined) {
            this.getProducto()
        }else {
            this.getLatestProductNumber()
        }
    }



    /**
     * @memberof ModalProductos
     * @method getProducto
     * @description Obtiene una producto de la DB
     */
    getProducto = () => {
        this.setState({ loading: true })
        axios.get(`/productos/${this.props.producto_id}`).then(({ data }) => {
            data.unidad = data.unidad?._id ? {
                label: this.renderUnidadOption(data.unidad),
                key: data?.unidad?._id,
                value: data?.unidad?._id,
            } : undefined
            data.categoria_id = data?.categoria_id?._id ? {
                label: data?.categoria_id?.nombre,
                key: data?.categoria_id?._id,
                value: data?.categoria_id?._id,
            } : null
            data.sub_categoria_id =  data?.sub_categoria_id?._id ? {
                label: data?.sub_categoria_id?.nombre,
                key: data?.sub_categoria_id?._id,
                value: data?.sub_categoria_id?._id,
            } : null
            this.setState({
                producto: data.codigo,
                categoria: data.categoria_id.codigo,
                subcategoria: data.sub_categoria_id.codigo,
            })
            this.ModalProductos.current.setFieldsValue(data)
        }).catch(error => {
            message.error('Error al traer área')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    getUnidades = ({
        page = this.state.unidades.page,
        limit = this.state.unidades.limit,
        search = this.state.unidades.search,
    } = this.state.unidades) => {

        this.setState({ unidades: { ...this.state.unidades, page, limit, search, } })
        axios.get('/sat-catalogos/unidades-claves', {
            params: { page, limit, search }
        })
            .then(({ data }) => {
                this.setState({ unidades: { ...this.state.unidades, ...data } })
            })
            .catch(error => {
                message.error('Error al obtner las categorias')
            })
    }

    getLatestProductNumber = () => {
        axios.get('/productos/folio')
            .then(({ data }) => {
                this.setState({ producto: data })
            })
            .catch(error => {
                // message.error('Error al obtner las categorias')
            })
    }

    /**
     * @memberOf ModalProductos
     * @method getSubCategorias
     * @descripcion Obtiene la lista de sub categorias
     */
    getCategorias = ({
        page = this.state.categorias.page,
        limit = this.state.categorias.limit,
        search = this.state.categorias.search,
    } = this.state.categorias) => {
        this.setState({ categorias: { ...this.state.categorias, page, limit, search, inputValue: null } })
        axios.get('/categorias', {
            params: { page, limit, search }
        })
            .then(({ data }) => {
                this.setState({ categorias: { ...this.state.categorias, ...data } })
            })
            .catch(error => {
                message.error('Error al obtner las categorias')
            })

    }

    addCategoria = ({
        nombre = this.state.categorias.inputValue
    } = this.state.categorias) => axios.post('/categorias', { nombre })
        .then(({ data }) => this.getCategorias())
        .catch(error => message.error(error?.response?.data ?? 'Error al actualizar la categoría'))

    updateCategoria = ({
        nombre,
        categoria_id
    } = this.state.categorias) => axios.put('/categorias/' + categoria_id, { nombre })
        .then(({ data }) => this.getCategorias())
        .catch(error => message.error(error?.response?.data ?? 'Error al actualizar la categoría'))

    deleteCategoria = (categoria_id) => axios.delete('/categorias/' + categoria_id)
        .then(({ data }) => this.getCategorias())
        .catch(error => message.error(error?.response?.data ?? 'Error al eliminar la categoría'))

    getSubCategorias = ({
        page = this.state.sub_categorias.page,
        limit = this.state.sub_categorias.limit,
        search = this.state.sub_categorias.search,
        categoria_id = this.state.sub_categorias.categoria_id,
    } = this.state.sub_categorias) => {

        this.setState({ sub_categorias: { ...this.state.sub_categorias, page, limit, search, categoria_id } })
        axios.get('/sub-categorias', {
            params: { page, limit, search, categoria_id }
        })
            .then(({ data }) => this.setState({ sub_categorias: { ...this.state.sub_categorias, ...data, inputValue: null } }))
            .catch(error => message.error('Error al obtner las categorias'))
    }

    addSubCategoria = ({
        nombre = this.state.sub_categorias.inputValue,
        categoria_id = this.state.sub_categorias.categoria_id,
    } = this.state.sub_categorias) => axios.post('/sub-categorias', { nombre, categoria_id })
        .then(({ data }) => this.getSubCategorias())
        .catch(error => message.error(error?.response?.data ?? 'Error al agregar la subcategoría'))

    updateSubCategoria = ({
        nombre,
        subcategoria_id
    }) => axios.put('/sub-categorias/' + subcategoria_id, { nombre })
        .then(({ data }) => this.getSubCategorias())
        .catch(error => message.error(error?.response?.data ?? 'Error al actualizar la subcategoría'))

    deleteSubCategoria = (subcategoria_id) => axios.delete('/sub-categorias/' + subcategoria_id)
        .then(({ data }) => this.getSubCategorias())
        .catch(error => message.error(error?.response?.data ?? 'Error al eliminar la subcategoría'))


    formatSKU = ({
        producto = this.state.producto,
        categoria = this.state.categoria,
        subcategoria = this.state.subcategoria
    } = this.state) => {

        this.setState({ categoria, subcategoria, producto })
        if (producto && categoria && subcategoria)
            this.ModalProductos.current.setFieldsValue({
                sku: `${producto.toString().padStart(5, '0')}-${categoria.toString().padStart(3, '0')}-${subcategoria.toString().padStart(3, '0')}`
            })
    }

    /**
     * @memberof ModalProductos
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {


        values.unidad = (values.unidad?.value  && values.unidad.key) ? values.unidad.value : values.unidad
        values.categoria_id = (values.categoria_id?.value  && values.categoria_id.key) ? values.categoria_id.value : values.categoria_id
        values.sub_categoria_id = (values.sub_categoria_id?.value  && values.sub_categoria_id.key) ? values.sub_categoria_id.value : values.sub_categoria_id

        const formData = new FormData()

        formData.appendMultiple({
            ...values,
            id: this.props.producto_id
        });

        console.log("values",values)
        console.log("formData",formData)

        if (this.props.producto_id) {
            
            console.log("Update",formData)
            this.updateProducto(formData)
        }
            
        else{
            console.log(formData)
            this.addProducto(formData)
        }

            

    }

    /**
    * @memberof ModalProductos
    * @method addProducto
    * @description Añade una producto a la BD
    */
    addProducto = (values) => {
        console.log(values)
        axios.post('/productos', 
            values
        ).then(response => {
            message.success('Producto creado')
            this.props.onClose()
        }).catch(error => {
            message.error('Error al crear Producto.')
        })
    }

    /**
     * @memberof ModalProductos
     * @method updateProducto
     * @description Actualiza la información de una producto
     */
    updateProducto = (values) => {
        this.setState({ loading: true })
        axios.put('/productos/' + this.props.producto_id, values)
        .then(response => {
            message.success('Producto Actualizada')
            this.props.onClose()
        }).catch(error => {
            message.error('Error al actualizar Producto')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    

    renderUnidadOption = ({ nombre, simbolo, descripcion, unidad }) => <Typography style={{ margin: "0 0 0.2em 0" }}>
        <Text strong>{nombre} <i>{simbolo ? <i><small style={{ fontWeight: "lighter" }}>({simbolo})</small></i> : null} </i> <small> ({unidad})</small></Text> <br />
        <Text>{descripcion}</Text>
    </Typography>

    renderCategoriaOption = ({ _id, nombre, editable, }, index) => {

        let updateInputStatus = status => this.setState(state => {
            state.categorias.data = state.categorias.data.map(e => ({ ...e, editable: false }))
            state.categorias.data[index].editable = status
            return state;
        })


        return <div style={{ justifyContent: "space-between", width: "100%", display: "flex" }}>
            {(editable == true) ? <InputEditable
                size="small"
                value={nombre}
                autoComplete="off"
                onChange={event => this.setState(state => {
                    state.categorias.data[index].nombre = event.target.value
                    return state;
                })}
                onKeyUpCapture={event => {
                    event.stopPropagation()
                    if (event.key === 'Enter') {
                        this.updateCategoria({ categoria_id: _id, nombre })
                        updateInputStatus(false)
                    }
                }}
                onBlur={event => {
                    event.stopPropagation()
                    this.updateCategoria({ categoria_id: _id, nombre })
                    updateInputStatus(false)
                }}
            /> : nombre}
            <Space style={{ position: "relative", left: 5 }}>
                <Button
                    icon={editable == true ? <SaveOutlined /> : <EditOutlined />}
                    size="small"
                    type="text"
                    onClick={(editable) ? (event => {
                        event.stopPropagation()
                        updateInputStatus(false)
                        this.updateCategoria({ categoria_id: _id, nombre })
                    }) : (event => {
                        event.stopPropagation()
                        updateInputStatus(true)
                    })}
                />
                <Button
                    size="small"
                    icon={<DeleteOutlined />}
                    type="text"
                    onClick={event => {
                        event.stopPropagation()
                        this.deleteCategoria(_id)
                    }}
                />
            </Space>
        </div>

    }

    renderSubCategoriaOption = ({ _id, nombre, codigo, editable }, index) => {

        let updateInputStatus = status => this.setState(state => {
            state.sub_categorias.data = state.sub_categorias.data.map(e => ({ ...e, editable: false }))
            state.sub_categorias.data[index].editable = status
            return state;
        })

        return <div style={{ justifyContent: "space-between", width: "100%", display: "flex" }}>
            {(editable == true) ? <InputEditable
                size="small"
                value={nombre}
                autoComplete="off"
                onChange={event => this.setState(state => {
                    state.sub_categorias.data[index].nombre = event.target.value
                    return state;
                })}
                onKeyUpCapture={event => {
                    event.stopPropagation()
                    if (event.key === 'Enter') {
                        this.updateSubCategoria({ subcategoria_id: _id, nombre })
                        updateInputStatus(false)
                    }
                }}
                onBlur={event => {
                    event.stopPropagation()
                    this.updateSubCategoria({ subcategoria_id: _id, nombre })
                    updateInputStatus(false)
                }}
            /> : nombre}
            <Space style={{ position: "relative", left: 5 }}>
                <Button
                    icon={editable == true ? <SaveOutlined /> : <EditOutlined />}
                    size="small"
                    type="text"
                    onClick={(editable) ? (event => {
                        event.stopPropagation()
                        updateInputStatus(false)
                        this.updateSubCategoria({ subcategoria_id: _id, nombre })
                    }) : (event => {
                        event.stopPropagation()
                        updateInputStatus(true)
                    })}
                />
                <Button
                    size="small"
                    icon={<DeleteOutlined />}
                    type="text"
                    onClick={event => {
                        event.stopPropagation()
                        this.deleteSubCategoria(_id)
                    }}
                />
            </Space>
        </div>

    }



    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    name="form-productos"
                    ref={this.ModalProductos}
                    onFinish={this.onFinish}
                    className="pd-1"
                >
                    <Row gutter={[16, 16]} className="w-100">
                        <Col span={24}>
                            <Form.Item
                                label="Nombre"
                                name="nombre"

                                rules={[
                                    {
                                        required: true,
                                        message: "Indique el nombre del producto"
                                    }
                                ]}
                            >
                                <Input placeholder="Nombre" ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Unidad"
                                name="unidad"
                                rules={[
                                    {
                                        required: true,
                                        message: "Indique la Unidad del Producto"
                                    }
                                ]}
                            >
                                <Select
                                    placeholder="Busque la unidad deseada"
                                    className="form-select select-actions"
                                    showSearch
                                    filterOption={false}
                                    onSearch={search => this.getUnidades({ search })}
                                >
                                    {this.state.unidades.data?.map((unidad) => <Option
                                        className="option-actions "
                                        key={unidad._id}
                                        value={unidad._id}
                                    >
                                        {this.renderUnidadOption(unidad)}
                                    </Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Categoría"
                                name="categoria_id"
                            >
                                <Select
                                    placeholder="Categoria"
                                    className="form-select select-actions"
                                    onChange={(categoria_id, option) => {
                                        this.formatSKU({ categoria: option.codigo })
                                        this.getSubCategorias({ categoria_id })
                                    }}
                                    disabled={!this.props.id && this.state.sku_cargado}
                                    onSearch={search => this.getCategorias({ search })}
                                    showSearch
                                    filterOption={false}
                                    dropdownRender={menu => (
                                        <>
                                            {menu}
                                            <Divider style={{ margin: '8px 0' }} />
                                            <Space style={{ padding: '0 8px 4px' }}>
                                                <Input
                                                    placeholder="Ingrese una categoría"
                                                    value={this.state.categorias.inputValue}
                                                    onChange={event => this.setState(state => {
                                                        state.categorias.inputValue = event.target.value
                                                        return state;
                                                    })}
                                                />
                                                <Tooltip title="Añadir categoría como nueva" placement="bottom">
                                                    <Button
                                                        size="small"
                                                        type="text"
                                                        icon={<PlusOutlined />}
                                                        onClick={this.addCategoria}
                                                    />
                                                </Tooltip>
                                            </Space>
                                        </>
                                    )}
                                    optionLabelProp="label"
                                >
                                    {this.state.categorias.data?.map((categoria, index) => {


                                        return <Option
                                            className="option-actions "
                                            key={categoria._id}
                                            value={categoria._id}
                                            label={categoria.nombre}
                                            codigo={categoria.codigo}
                                        >
                                            {this.renderCategoriaOption(categoria, index)}
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Sub Categoría"
                                name="sub_categoria_id"
                            >
                                <Select
                                    placeholder="Sub Categoria"
                                    className="form-select select-actions"
                                    onSearch={search => this.getSubCategorias({ search })}
                                    showSearch
                                    filterOption={false}
                                    disabled={!this.state.sub_categorias.categoria_id}

                                    onChange={(value, option) => {

                                        this.formatSKU({ subcategoria: option.codigo })
                                    }}

                                    dropdownRender={menu => (
                                        <>
                                            {menu}
                                            <Divider style={{ margin: '8px 0' }} />
                                            <Space style={{ padding: '0 8px 4px' }}>
                                                <Input
                                                    placeholder="Ingrese una categoría"
                                                    value={this.state.sub_categorias.inputValue}
                                                    onChange={event => this.setState(state => {
                                                        state.sub_categorias.inputValue = event.target.value
                                                        return state;
                                                    })}
                                                // onKeyUpCapture={this.addSubCategoria}
                                                />
                                                <Tooltip title="Añadir categoría como nueva" placement="bottom">
                                                    <Button
                                                        size="small"
                                                        type="text"
                                                        icon={<PlusOutlined />}
                                                        onClick={this.addSubCategoria}
                                                    />
                                                </Tooltip>
                                            </Space>
                                        </>
                                    )}
                                    optionLabelProp="label"
                                >
                                    {this.state.sub_categorias.data?.map((subcategoria, index) => {
                                        return <Option
                                            className="option-actions "
                                            key={subcategoria._id}
                                            value={subcategoria._id}
                                            label={subcategoria.nombre}
                                            codigo={subcategoria.codigo}
                                        >
                                            {this.renderSubCategoriaOption(subcategoria, index)}
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="SKU Unico de Identificación"
                                name="sku"
                            >
                                <Input disabled={true} placeholder="Nombre" ></Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                            >
                                <Input.TextArea placeholder="Nombre" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Fabricante / Marca del Producto"
                                name="fabricante"
                            >
                                <Input placeholder="Nombre" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="SKU del Fabricante o Marca"
                                name="sku_fabricante"
                            >
                                <Input placeholder="Nombre" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                // labelCol={{ span: 12 }}
                                name="imagen"

                            >
                                <SimpleUploader
                                    url={axios.defaults.baseURL + "/productos/imagen/"}
                                    _id={this.props.producto_id}
                                    uploadTitle="Subir Imagen"
                                    imageCrop={true}
                                    uploadContent={<div>
                                        <UploadOutlined style={{ color: "currentcolor", fontSize: 24 }} />
                                        <div className="ant-upload-text">Subir fotografía del producto</div>
                                    </div>}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, producto_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={producto_id ? "Editar Producto" : "Crear Producto"}
        closable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-productos', key: 'submit', htmlType: 'submit' }}
    >
        <ModalProductos {...props} />
    </Modal>

}